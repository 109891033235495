<template>
    <div class="toggle-favorite" v-bind:class="{'favorite-active' : isFavorite(this.artistId)}" @click="isFavorite(artistId) ? removeFavoriteArtist(artistId) : setFavoriteArtist(artistId)">
        <i class="fas fa-star"></i>
        <span class="favorite-off"
              v-show="!isFavorite(this.artistId)"
             
        >Add to Favorites</span>
        <span class="favorite-on"
              v-show="isFavorite(this.artistId)"
             
        >Remove Favorite</span>
    </div>
</template>

<script>
    export default {
        name: "ArtsgoggleFavoriteButton",
        props: ['artist-id'],
        data() {
            return {
                favoriteArtists: []
            }
        },
        mounted() {
            this.getUserFavorites()
        },
        methods: {
            getUserFavorites: function () {
                this.favoriteArtists = this.$storage.get('favoriteArtists', []);
            },
            setFavoriteArtist: function() {
                this.favoriteArtists.push(this.artistId.toString());
                this.$storage.set('favoriteArtists', this.favoriteArtists);
            },
            removeFavoriteArtist: function() {
                console.log('removed');
                this.favoriteArtists = this.favoriteArtists.filter(artist => artist !== this.artistId.toString());
                this.$storage.set('favoriteArtists', this.favoriteArtists);
            },
            isFavorite: function(artistId) {
                let artist = artistId.toString();
                return (this.favoriteArtists.length && this.favoriteArtists.includes(artist) );
            }
        }
    }
</script>

<style>

</style>