<template>
    <div>
    <div class="container no-favorites" v-show="!favoriteArtists.length">
        <h2>You have no favorite artists or performers. Go find some!!</h2>
    </div>
    <div class="info-list artist blocks">
        <div class="artists-list">
                <div class="block-section" v-for="artist in artistData">
                    <div class="info-box">
                        <div class="artist-medium">{{ artist.primaryMedium }}</div>
                        <div class="text-wrap">
                            <div class="heading-info">
                                <h2><a :href="artist.url">{{ artist.artistType == 'artist' ? artist.formattedName : artist.bandName }}</a></h2>
                                <p v-if="artist.artistType == 'artist'">{{ artist.businessName }}</p>
                            </div>
                        </div>
                        <div class="img-area" v-if="artist.artistType == 'artist'">
                            <a :href="artist.url" class="img-container" v-bind:style="{backgroundImage: 'url('+artist.sampleImage+')'}">
                            </a>
                        </div>
                            
                        <div class="text-info">
                            <a :href="artist.url" class="btn" v-if="artist.artistType == 'artist'">
                            View Artist Details
                            </a>
                            <div v-if="artist.artistType == 'band'">
                                <p><a :href="artist.website">{{artist.website}}</a></p>
                                <p>{{ artist.stageAssignment.title }} at {{ artist.performanceTime }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="toggle-favorite favorite-active" @click="removeFavoriteArtist(artist.id)">
                        <i class="fas fa-star"></i>
                        <span class="favorite-on"
                            
                        >Remove Favorite</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import ArtsgoggleFavoritesButton from './ArtsgoggleFavoritesButton';
    export default {
        name: "ArtsgoggleFavorites",
        components: {
            ArtsgoggleFavoritesButton
        },
        data() {
            return {
                favoriteArtists: [],
                favoriteBands: [],
                artistData: []
            }
        },
        created() {
            this.getUserFavorites()
        },
        mounted() {
            this.getFavoriteArtistData();
        },
        methods: {
            getUserFavorites: function () {
                this.favoriteArtists = this.$storage.get('favoriteArtists', []);
                this.favoriteBands = this.$storage.get('favoriteBands', []);
            },
            getFavoriteArtistData: function() {
                this.artistData = [];
                if (this.favoriteArtists.length) {
                    this.favoriteArtists.map(artist => {
                        axios
                            .get('/api/artsgoggle/artist/' + artist + '.json')
                            .then(
                                (response) => {
                                    this.artistData.push(response.data)
                                }
                            )
                            .catch(error => console.log(error))
                    });
                }
            },
            removeFavoriteArtist: function(artistId) {
                this.favoriteArtists = this.favoriteArtists.filter(artist => artist !== artistId);
                this.$storage.set('favoriteArtists', this.favoriteArtists);
                this.getFavoriteArtistData();
            }
        }
    }
</script>

<style>
 .no-favorites {
     min-height: 40vh;
     display:flex;
     justify-content: center;
     align-items: center;
     padding:12px;
     background: #fff;
 }
.no-favorites h2 {
    font-size: 1.5rem;
    font-weight: 600;
}
</style>