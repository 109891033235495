var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "toggle-favorite",
      class: { "favorite-active": _vm.isFavorite(this.shopId) },
      on: {
        click: function ($event) {
          _vm.isFavorite(_vm.shopId)
            ? _vm.removeFavoriteShop(_vm.shopId)
            : _vm.setFavoriteShop(_vm.shopId)
        },
      },
    },
    [
      _c("i", { staticClass: "fas fa-heart" }),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isFavorite(this.shopId),
              expression: "!isFavorite(this.shopId)",
            },
          ],
          staticClass: "favorite-off",
        },
        [_vm._v("Add to Favorites")]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isFavorite(this.shopId),
              expression: "isFavorite(this.shopId)",
            },
          ],
          staticClass: "favorite-on",
        },
        [_vm._v("Remove Favorite")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }