var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.favoriteArtists.length,
            expression: "!favoriteArtists.length",
          },
        ],
        staticClass: "container no-favorites",
      },
      [
        _c("h2", [
          _vm._v("You have no favorite artists or performers. Go find some!!"),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "info-list artist blocks" }, [
      _c(
        "div",
        { staticClass: "artists-list" },
        _vm._l(_vm.artistData, function (artist) {
          return _c("div", { staticClass: "block-section" }, [
            _c("div", { staticClass: "info-box" }, [
              _c("div", { staticClass: "artist-medium" }, [
                _vm._v(_vm._s(artist.primaryMedium)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-wrap" }, [
                _c("div", { staticClass: "heading-info" }, [
                  _c("h2", [
                    _c("a", { attrs: { href: artist.url } }, [
                      _vm._v(
                        _vm._s(
                          artist.artistType == "artist"
                            ? artist.formattedName
                            : artist.bandName
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  artist.artistType == "artist"
                    ? _c("p", [_vm._v(_vm._s(artist.businessName))])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              artist.artistType == "artist"
                ? _c("div", { staticClass: "img-area" }, [
                    _c("a", {
                      staticClass: "img-container",
                      style: {
                        backgroundImage: "url(" + artist.sampleImage + ")",
                      },
                      attrs: { href: artist.url },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "text-info" }, [
                artist.artistType == "artist"
                  ? _c(
                      "a",
                      { staticClass: "btn", attrs: { href: artist.url } },
                      [
                        _vm._v(
                          "\n                        View Artist Details\n                        "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                artist.artistType == "band"
                  ? _c("div", [
                      _c("p", [
                        _c("a", { attrs: { href: artist.website } }, [
                          _vm._v(_vm._s(artist.website)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(artist.stageAssignment.title) +
                            " at " +
                            _vm._s(artist.performanceTime)
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "toggle-favorite favorite-active",
                on: {
                  click: function ($event) {
                    return _vm.removeFavoriteArtist(artist.id)
                  },
                },
              },
              [
                _c("i", { staticClass: "fas fa-star" }),
                _vm._v(" "),
                _c("span", { staticClass: "favorite-on" }, [
                  _vm._v("Remove Favorite"),
                ]),
              ]
            ),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }