var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "guide-general" }, [
      _c("div", { staticClass: "container-medium" }, [
        _c("div", [
          _c(
            "div",
            {
              staticClass: "filters",
              class: { "filters-open": _vm.filtersOpen },
            },
            [
              _c(
                "div",
                {
                  staticClass: "filters-wrapper",
                  class: { "filters-open": _vm.filtersOpen },
                },
                [
                  _c("div", { staticClass: "holder-wrap" }, [
                    _c("div", { staticClass: "holder-wrap-content" }, [
                      _c(
                        "span",
                        {
                          staticClass: "filters-title opener-filter",
                          on: { click: _vm.toggleFilters },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "/images/southside-guide/filter-icon.png",
                              alt: "",
                            },
                          }),
                          _vm._v("Filters\n                        "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "filters-row",
                          attrs: { "data-filter-group": "filters" },
                        },
                        [
                          _c("div", { staticClass: "filters-area" }, [
                            _c("p", [
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.filtersOpen,
                                      expression: "filtersOpen",
                                    },
                                  ],
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.showAllCategories.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Clear Filters")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("h3", { staticClass: "pink" }, [
                              _vm._v("Category:"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedCategories,
                                        expression: "selectedCategories",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "foodDrink",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedCategories
                                      )
                                        ? _vm._i(
                                            _vm.selectedCategories,
                                            "foodDrink"
                                          ) > -1
                                        : _vm.selectedCategories,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedCategories,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "foodDrink",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedCategories =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedCategories = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedCategories = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "foodDrink" } }, [
                                _vm._v("Food & Drink"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedCategories,
                                        expression: "selectedCategories",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "entertainment",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedCategories
                                      )
                                        ? _vm._i(
                                            _vm.selectedCategories,
                                            "entertainment"
                                          ) > -1
                                        : _vm.selectedCategories,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedCategories,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "entertainment",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedCategories =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedCategories = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedCategories = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "entertainment" } }, [
                                _vm._v(
                                  "Entertainment (Live Music, Theater, Classes, etc.)"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedCategories,
                                        expression: "selectedCategories",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "retailGifts",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedCategories
                                      )
                                        ? _vm._i(
                                            _vm.selectedCategories,
                                            "retailGifts"
                                          ) > -1
                                        : _vm.selectedCategories,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedCategories,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "retailGifts",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedCategories =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedCategories = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedCategories = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "retailGifts" } }, [
                                _vm._v(
                                  "Retail: Gifts, Home Goods, Apparel, Art & more"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedCategories,
                                        expression: "selectedCategories",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "specialEventSpaces",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedCategories
                                      )
                                        ? _vm._i(
                                            _vm.selectedCategories,
                                            "specialEventSpaces"
                                          ) > -1
                                        : _vm.selectedCategories,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedCategories,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "specialEventSpaces",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedCategories =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedCategories = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedCategories = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "specialEventSpaces" } },
                                [_vm._v("Special Event Spaces & Hotels")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedCategories,
                                        expression: "selectedCategories",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "healthBeauty",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedCategories
                                      )
                                        ? _vm._i(
                                            _vm.selectedCategories,
                                            "healthBeauty"
                                          ) > -1
                                        : _vm.selectedCategories,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedCategories,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "healthBeauty",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedCategories =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedCategories = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedCategories = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "healthBeauty" } }, [
                                _vm._v("Health & Beauty Services"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedCategories,
                                        expression: "selectedCategories",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "nonProfit",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedCategories
                                      )
                                        ? _vm._i(
                                            _vm.selectedCategories,
                                            "nonProfit"
                                          ) > -1
                                        : _vm.selectedCategories,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedCategories,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "nonProfit",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedCategories =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedCategories = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedCategories = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "nonProfit" } }, [
                                _vm._v("Non-Profit Causes"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedCategories,
                                        expression: "selectedCategories",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "otherServices",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedCategories
                                      )
                                        ? _vm._i(
                                            _vm.selectedCategories,
                                            "otherServices"
                                          ) > -1
                                        : _vm.selectedCategories,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedCategories,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "otherServices",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedCategories =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedCategories = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedCategories = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "otherServices" } }, [
                                _vm._v("Other Services"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "filters-area" }, [
                            _c("h3", { staticClass: "pink" }, [
                              _vm._v("Offerings:"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedOfferings,
                                        expression: "selectedOfferings",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "holidaySales",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedOfferings
                                      )
                                        ? _vm._i(
                                            _vm.selectedOfferings,
                                            "holidaySales"
                                          ) > -1
                                        : _vm.selectedOfferings,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedOfferings,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "holidaySales",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedOfferings =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedOfferings = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedOfferings = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "holidaySales" } }, [
                                _vm._v("Holiday Sales & Specials"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedOfferings,
                                        expression: "selectedOfferings",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "holidayMenu",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedOfferings
                                      )
                                        ? _vm._i(
                                            _vm.selectedOfferings,
                                            "holidayMenu"
                                          ) > -1
                                        : _vm.selectedOfferings,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedOfferings,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "holidayMenu",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedOfferings =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedOfferings = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedOfferings = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", [_vm._v("Holiday Menu")]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedOfferings,
                                        expression: "selectedOfferings",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "holidayEvents",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedOfferings
                                      )
                                        ? _vm._i(
                                            _vm.selectedOfferings,
                                            "holidayEvents"
                                          ) > -1
                                        : _vm.selectedOfferings,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedOfferings,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "holidayEvents",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedOfferings =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedOfferings = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedOfferings = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", [_vm._v("Holiday Events")]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedOfferings,
                                        expression: "selectedOfferings",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "giftableExperiences",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedOfferings
                                      )
                                        ? _vm._i(
                                            _vm.selectedOfferings,
                                            "giftableExperiences"
                                          ) > -1
                                        : _vm.selectedOfferings,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedOfferings,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "giftableExperiences",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedOfferings =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedOfferings = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedOfferings = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v(
                                  "Giftable Experiences (Tickets, Tours, Rentals, Classes, etc.)"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedOfferings,
                                        expression: "selectedOfferings",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "giftableServices",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedOfferings
                                      )
                                        ? _vm._i(
                                            _vm.selectedOfferings,
                                            "giftableServices"
                                          ) > -1
                                        : _vm.selectedOfferings,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedOfferings,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "giftableServices",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedOfferings =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedOfferings = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedOfferings = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v(
                                  "Giftable Services (Beauty, Portraits, Cleaning, Design, etc.)"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedOfferings,
                                        expression: "selectedOfferings",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "giftcards",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedOfferings
                                      )
                                        ? _vm._i(
                                            _vm.selectedOfferings,
                                            "giftcards"
                                          ) > -1
                                        : _vm.selectedOfferings,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedOfferings,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "giftcards",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedOfferings =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedOfferings = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedOfferings = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", [_vm._v("Gift Cards")]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedOfferings,
                                        expression: "selectedOfferings",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "onlineShopping",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedOfferings
                                      )
                                        ? _vm._i(
                                            _vm.selectedOfferings,
                                            "onlineShopping"
                                          ) > -1
                                        : _vm.selectedOfferings,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedOfferings,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "onlineShopping",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedOfferings =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedOfferings = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedOfferings = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", [_vm._v("Online Shopping")]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedOfferings,
                                        expression: "selectedOfferings",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "catering",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedOfferings
                                      )
                                        ? _vm._i(
                                            _vm.selectedOfferings,
                                            "catering"
                                          ) > -1
                                        : _vm.selectedOfferings,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedOfferings,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "catering",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedOfferings =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedOfferings = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedOfferings = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", [_vm._v("Catering")]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedOfferings,
                                        expression: "selectedOfferings",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "privateEventSpace",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedOfferings
                                      )
                                        ? _vm._i(
                                            _vm.selectedOfferings,
                                            "privateEventSpace"
                                          ) > -1
                                        : _vm.selectedOfferings,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedOfferings,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "privateEventSpace",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedOfferings =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedOfferings = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedOfferings = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", [_vm._v("Private Event Space")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "filters-area" }, [
                            _c("h3", { staticClass: "pink" }, [
                              _vm._v("Village:"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedVillages,
                                        expression: "selectedVillages",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "southMainVillage",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedVillages
                                      )
                                        ? _vm._i(
                                            _vm.selectedVillages,
                                            "southMainVillage"
                                          ) > -1
                                        : _vm.selectedVillages,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedVillages,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "southMainVillage",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedVillages =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedVillages = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedVillages = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "southMainVillage" } },
                                [_vm._v("South Main Village ")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedVillages,
                                        expression: "selectedVillages",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "magnoliaVillage",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedVillages
                                      )
                                        ? _vm._i(
                                            _vm.selectedVillages,
                                            "magnoliaVillage"
                                          ) > -1
                                        : _vm.selectedVillages,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedVillages,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "magnoliaVillage",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedVillages =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedVillages = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedVillages = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "magnoliaVillage" } },
                                [_vm._v("Magnolia Village")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedVillages,
                                        expression: "selectedVillages",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "parkPlaceVillage",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedVillages
                                      )
                                        ? _vm._i(
                                            _vm.selectedVillages,
                                            "parkPlaceVillage"
                                          ) > -1
                                        : _vm.selectedVillages,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedVillages,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "parkPlaceVillage",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedVillages =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedVillages = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedVillages = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "parkPlaceVillage" } },
                                [_vm._v("Park Place Village")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedVillages,
                                        expression: "selectedVillages",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "vickeryVillage",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedVillages
                                      )
                                        ? _vm._i(
                                            _vm.selectedVillages,
                                            "vickeryVillage"
                                          ) > -1
                                        : _vm.selectedVillages,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedVillages,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "vickeryVillage",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedVillages =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedVillages = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedVillages = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "vickeryVillage" } },
                                [_vm._v("Vickery Village")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedVillages,
                                        expression: "selectedVillages",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "evansRosedale",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedVillages
                                      )
                                        ? _vm._i(
                                            _vm.selectedVillages,
                                            "evansRosedale"
                                          ) > -1
                                        : _vm.selectedVillages,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedVillages,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "evansRosedale",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedVillages =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedVillages = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedVillages = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "evansRosedale" } }, [
                                _vm._v("Evans & Rosedale"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedVillages,
                                        expression: "selectedVillages",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "fairmount",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedVillages
                                      )
                                        ? _vm._i(
                                            _vm.selectedVillages,
                                            "fairmount"
                                          ) > -1
                                        : _vm.selectedVillages,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedVillages,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "fairmount",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedVillages =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedVillages = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedVillages = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "fairmount" } }, [
                                _vm._v("Fairmount"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedVillages,
                                        expression: "selectedVillages",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "medicalDistrict",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedVillages
                                      )
                                        ? _vm._i(
                                            _vm.selectedVillages,
                                            "medicalDistrict"
                                          ) > -1
                                        : _vm.selectedVillages,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedVillages,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "medicalDistrict",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedVillages =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedVillages = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedVillages = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "medicalDistrict" } },
                                [_vm._v("Medical District")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "check-group" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check check_grey check_size-lg check_rounded",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedVillages,
                                        expression: "selectedVillages",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "iDontKnow",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selectedVillages
                                      )
                                        ? _vm._i(
                                            _vm.selectedVillages,
                                            "iDontKnow"
                                          ) > -1
                                        : _vm.selectedVillages,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedVillages,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "iDontKnow",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedVillages =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedVillages = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedVillages = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "iDontKnow" } }, [
                                _vm._v("Other"),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { "margin-top": "24px" } }, [
            _c("div", { staticClass: "container-medium ajax-holder" }, [
              _c(
                "ul",
                {
                  staticClass: "info-list shop-list content-style-decore guide",
                },
                _vm._l(this.shopsToDisplay, function (shop) {
                  return _c("li", { key: shop.id, staticClass: "shop" }, [
                    _c(
                      "a",
                      {
                        staticClass: "info-box",
                        attrs: { href: shop.shopUrl },
                      },
                      [
                        _c("div", { staticClass: "text-wrap" }, [
                          _c("div", { staticClass: "heading-info" }, [
                            _c("h2", [
                              _c("a", { attrs: { href: shop.shopUrl } }, [
                                _vm._v(_vm._s(shop.submission.businessName)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("h3", [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.transformCategory(
                                      shop.submission
                                        .whatTypeOfBusinessAreYou[0]
                                    )
                                  ),
                                },
                              }),
                              _vm._v(
                                " | \n                                        "
                              ),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.transformVillage(
                                      shop.submission
                                        .whichNearSoutsideVillageAreYouLocatedIn[0]
                                    )
                                  ),
                                },
                              }),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "shop-image-wrap" }, [
                          _c("img", {
                            attrs: { src: shop.primaryProfileImage },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "shop-footer" }, [
                          _c("div", { staticClass: "shop-description" }, [
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(
                                  shop.submission.businessDescription.substring(
                                    0,
                                    160
                                  ) + "..."
                                ),
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "controls" }, [
                            _c(
                              "div",
                              {
                                staticClass: "toggle-favorite",
                                class: {
                                  "favorite-active": _vm.isFavorite(shop.id),
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.isFavorite(shop.id)
                                      ? _vm.removeFavoriteShop(shop.id)
                                      : _vm.setFavoriteShop(shop.id)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fas fa-heart" }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.isFavorite(shop.id),
                                        expression: "!isFavorite(shop.id)",
                                      },
                                    ],
                                    staticClass: "favorite-off",
                                  },
                                  [_vm._v("Add to Favorites")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isFavorite(shop.id),
                                        expression: "isFavorite(shop.id)",
                                      },
                                    ],
                                    staticClass: "favorite-on",
                                  },
                                  [_vm._v("Remove Favorite")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "details-button" }, [
                              _c("a", { attrs: { href: shop.shopUrl } }, [
                                _vm._v("Learn More "),
                                _c("i", { staticClass: "fas fa-arrow-right" }),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }