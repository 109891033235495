<template>
<div class="content-section property-list" id="properties">
    <div class="filter-properties container" @click="toggleFilters">
        <a class="btn-arrow bg-denim text-sand" href="#" @click.prevent="">Filter Properties</a>
    </div>
    <div class="properties">
            <div class="property" v-for="item in postsToDisplay" :key="item.id">
                <a class="image" :href="item.url">
                    <img :src="item.image" alt="" v-if="item.image">
                </a>
                <div class="property-content">
                    <div class="info-row">
                        <span>{{ item.price }}</span>
                        <span>{{ item.sqft }}</span>
                    </div>
                    <div class="text-area">
                        <address class="adr">
                            <h2><a :href="item.url">{{ item.title }}</a><br>
                            <span v-show="item.address != item.title">{{ item.address }}</span></h2>
                            <strong class="subtitle">{{ item.city }}, {{ item.state }} {{ item.zip }}</strong>
                        </address>
                        
                        <span class="filters-marker" :class="'filters-marker_'+item.villageColor" v-if="item.villageId">{{ item.villageName }}</span>
                        <div class="bottom-text">
                            <p v-html="item.description"></p>
                        </div>
                    </div>
                    <div class="contact-area">
                        <h4 class="mobile-hidden">Contact:</h4>
                        <p>{{ item.agentName}}, {{ item.agentCompany }}</p>
                        <p>
                            <a :href="'tel:'+item.agentPhone" class="tel">{{ item.agentPhone }}</a> 
                            <span v-if="item.agentEmail">or
                            <a :href="'mailto:'+item.agentEmail" class="mail">{{ item.agentEmail }}</a>
                            </span>
                        </p>
                    </div>
                    <div class="action">
                        <a :href="item.url" class="btn-arrow">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="pagination">
                <p></p>
            </div>
    </div>
    <div class="filters bg-sand" :class="{open: filtersOpen}" id="filter-sidebar">
        <div class="close-filters" @click="toggleFilters">
            <i class="far fa-times text-denim"></i>
        </div>
        <div class="filters-inner">
            <div class="filter-section">
                <h3>Category:</h3>
                <div class="filter-list">
                    <label class="checkbox" v-for="cat in propertyCategories" :key="cat.id">
                        <span class="checkbox__input">
                            <input type="checkbox" name="categories" :value="cat.id" v-model="selectedCategories">
                            <span class="checkbox__control">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                                <path fill='none' stroke='currentColor' stroke-width='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
                            </span>
                        </span>
                        <span class="radio__label">{{ cat.title }}</span>
                    </label>
                </div>
            </div>
            <div class="filter-section">
                <h3>Village:</h3>
                <div class="filter-list">
                    <label class="checkbox" v-for="village in villages" :key="village.id">
                        <span class="checkbox__input">
                            <input type="checkbox" name="village" :value="village.id" v-model="selectedVillages">
                            <span class="checkbox__control">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                                <path fill='none' stroke='currentColor' stroke-width='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
                            </span>
                        </span>
                        <span class="radio__label">{{ village.title }}</span>
                    </label>
                </div>
            </div>
            <div class="filter-section">
                <h3>Type:</h3>
                <div class="filter-list">
                    <label class="checkbox" v-for="type in propertyFilters" :key="type.id">
                        <span class="checkbox__input">
                            <input type="checkbox" name="type" :value="type.id" v-model="selectedTypes">
                            <span class="checkbox__control">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                                <path fill='none' stroke='currentColor' stroke-width='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
                            </span>
                        </span>
                        <span class="radio__label">{{ type.title }}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "Properties",
        data() {
            return {
                properties: [],
                selectedCategories: [],
                selectedTypes: [],
                selectedVillages: [],
                filtersOpen: false,
                propertyCategories: [],
                propertyFilters: [],
                villages: []
            }
        },
        created() {
            this.getPropertyCategories();
            this.getPropertyTypes();
            this.getVillages();
        },
        mounted() {
            this.getProperties();
            this.initStickySidebar();
        },
        methods: {
            getProperties: function() {
                axios
                    .get('/api/properties.json?limit=20' )
                    .then(
                        (response) => {
                            this.properties = response.data.data;
                        }
                    )
                    .catch(error => console.log(error))
            },
            getPage: function(i) {
                axios
                    .get('/api/properties.json?limit=20&page=' + i)
                    .then(
                        (response) => {
                            this.properties = response.data.data
                        }
                    )
                    .catch(error => console.log(error))
            },
            getPropertyCategories: function() {
                axios
                    .get('/api/propertyCategories.json')
                    .then(
                        (response) => {
                            this.propertyCategories = response.data.data
                        }
                    )
                    .catch(error => console.log(error))
            },
            getPropertyTypes: function() {
                axios
                    .get('/api/propertyFilters.json')
                    .then(
                        (response) => {
                            this.propertyFilters = response.data.data;
                        }
                    )
                    .catch(error => console.log(error))
            },
            getVillages: function() {
                axios
                    .get('/api/villages.json')
                    .then(
                        (response) => {
                            this.villages = response.data.data;
                        }
                    )
                    .catch(error => console.log(error))
            },
            selectCategory: function(id) {
                this.selectedCategory = id;
                console.log(id);
            },
            showAllCategories: function() {
                this.selectedCategory = null;
                // console.log(this.selectedCategory);
            },
            toggleFilters: function() {
                this.filtersOpen = !this.filtersOpen;
            },
            filterByCategory: function(property) {
                return !this.selectedCategories.length || property.categories.some(cat => this.selectedCategories.includes(cat));
            },
            filterByPropertyType: function(property) {
                return !this.selectedTypes.length || property.filters.some(tp => this.selectedTypes.includes(tp));
            },
            filterByVillage: function(property) {
                return !this.selectedVillages.length || this.selectedVillages.includes(property.villageId);
            },
            initStickySidebar: function() {
                var sidebar = new StickySidebar('#filter-sidebar', {
                    containerSelector: '#properties',
                    innerWrapperSelector: '.filters-inner',
                    minWidth: 960,
                    topSpacing: 80,
                    bottomSpacing: 0
                });
            },

        },
        computed: {
            postsToDisplay: function() {
                return this.properties.filter(this.filterByCategory).filter(this.filterByPropertyType).filter(this.filterByVillage);
            }
        }
    }
</script>

<style>
    .filters-wrapper {
        margin-bottom: 36px;
    }
</style>