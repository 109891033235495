<template>
<div class="container">
    <div class="guide-general">
    <div class="container-medium">
    <div>

       
        
        <div class="filters" :class="{ 'filters-open':filtersOpen }">
            <div class="filters-wrapper" :class="{ 'filters-open':filtersOpen }">
                <div class="holder-wrap">
                    <div class="holder-wrap-content">
                        <span class="filters-title opener-filter" @click="toggleFilters">
                            <img src="/images/southside-guide/filter-icon.png" alt="">Filters
                        </span>
                        <div class="filters-row" data-filter-group="filters">
                            
                            <div class="filters-area">
                                <p>
                                    <a href="#" @click.prevent="showAllCategories" v-show="filtersOpen">Clear Filters</a>
                                </p>
                                <h3 class="pink">Category:</h3>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="foodDrink" v-model="selectedCategories">
                                        <span></span>
                                    </div>
                                    <label for="foodDrink">Food & Drink</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="entertainment" v-model="selectedCategories">
                                        <span></span>
                                    </div>
                                    <label for="entertainment">Entertainment (Live Music, Theater, Classes, etc.)</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="retailGifts" v-model="selectedCategories">
                                        <span></span>
                                    </div>
                                    <label for="retailGifts">Retail: Gifts, Home Goods, Apparel, Art & more</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="specialEventSpaces" v-model="selectedCategories">
                                        <span></span>
                                    </div>
                                    <label for="specialEventSpaces">Special Event Spaces & Hotels</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="healthBeauty" v-model="selectedCategories">
                                        <span></span>
                                    </div>
                                    <label for="healthBeauty">Health & Beauty Services</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="nonProfit" v-model="selectedCategories">
                                        <span></span>
                                    </div>
                                    <label for="nonProfit">Non-Profit Causes</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="otherServices" v-model="selectedCategories">
                                        <span></span>
                                    </div>
                                    <label for="otherServices">Other Services</label>
                                </div>
                            </div>
                            
                            <div class="filters-area">
                                <h3 class="pink">Offerings:</h3>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="holidaySales" v-model="selectedOfferings">
                                        <span></span>
                                    </div>
                                    <label for="holidaySales">Holiday Sales & Specials</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="holidayMenu" v-model="selectedOfferings">
                                        <span></span>
                                    </div>
                                    <label>Holiday Menu</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="holidayEvents" v-model="selectedOfferings">
                                        <span></span>
                                    </div>
                                    <label>Holiday Events</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="giftableExperiences" v-model="selectedOfferings">
                                        <span></span>
                                    </div>
                                    <label>Giftable Experiences (Tickets, Tours, Rentals, Classes, etc.)</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="giftableServices" v-model="selectedOfferings">
                                        <span></span>
                                    </div>
                                    <label>Giftable Services (Beauty, Portraits, Cleaning, Design, etc.)</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="giftcards" v-model="selectedOfferings">
                                        <span></span>
                                    </div>
                                    <label>Gift Cards</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="onlineShopping" v-model="selectedOfferings">
                                        <span></span>
                                    </div>
                                    <label>Online Shopping</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="catering" v-model="selectedOfferings">
                                        <span></span>
                                    </div>
                                    <label>Catering</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="privateEventSpace" v-model="selectedOfferings">
                                        <span></span>
                                    </div>
                                    <label>Private Event Space</label>
                                </div>
                               
                            </div>

                            <div class="filters-area">
                                <h3 class="pink">Village:</h3>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="southMainVillage" v-model="selectedVillages">
                                        <span></span>
                                    </div>
                                    <label for="southMainVillage">South Main Village </label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="magnoliaVillage" v-model="selectedVillages">
                                        <span></span>
                                    </div>
                                    <label for="magnoliaVillage">Magnolia Village</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="parkPlaceVillage" v-model="selectedVillages">
                                        <span></span>
                                    </div>
                                    <label for="parkPlaceVillage">Park Place Village</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="vickeryVillage" v-model="selectedVillages">
                                        <span></span>
                                    </div>
                                    <label for="vickeryVillage">Vickery Village</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="evansRosedale" v-model="selectedVillages">
                                        <span></span>
                                    </div>
                                    <label for="evansRosedale">Evans & Rosedale</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="fairmount" v-model="selectedVillages">
                                        <span></span>
                                    </div>
                                    <label for="fairmount">Fairmount</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="medicalDistrict" v-model="selectedVillages">
                                        <span></span>
                                    </div>
                                    <label for="medicalDistrict">Medical District</label>
                                </div>
                                <div class="check-group">
                                    <div class="check check_grey check_size-lg check_rounded">
                                        <input type="checkbox" value="iDontKnow" v-model="selectedVillages">
                                        <span></span>
                                    </div>
                                    <label for="iDontKnow">Other</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="" style="margin-top: 24px;">
            <div class="container-medium ajax-holder">
                <ul class="info-list shop-list content-style-decore guide">
                    <li class="shop" v-for="shop in this.shopsToDisplay" :key="shop.id">
                        <a :href="shop.shopUrl" class="info-box">
                            <div class="text-wrap">
                                <div class="heading-info">
                                    <h2><a :href="shop.shopUrl">{{ shop.submission.businessName }}</a></h2>
                                    <h3><span v-html="transformCategory(shop.submission.whatTypeOfBusinessAreYou[0])"></span> | 
                                        <span v-html="transformVillage(shop.submission.whichNearSoutsideVillageAreYouLocatedIn[0])"></span></h3>
                                </div>
                            </div>
                            <div class="shop-image-wrap">
                                <img :src="shop.primaryProfileImage">
                            </div>
                            <div class="shop-footer">
                                <div class="shop-description">
                                    <p v-html="shop.submission.businessDescription.substring(0, 160) + '...'"></p>
                                </div>
                                
                                
                                <div class="controls">
                                    <div class="toggle-favorite" v-bind:class="{'favorite-active' : isFavorite(shop.id)}" @click="isFavorite(shop.id) ? removeFavoriteShop(shop.id) : setFavoriteShop(shop.id)">
                                        <i class="fas fa-heart"></i>
                                        <span class="favorite-off"
                                            v-show="!isFavorite(shop.id)"
                                        >Add to Favorites</span>
                                        <span class="favorite-on"
                                            v-show="isFavorite(shop.id)"
                                        >Remove Favorite</span>
                                    </div>

                                    <div class="details-button">
                                        <a :href="shop.shopUrl">Learn More <i class="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </a>
                        
                    </li>
                </ul>
            </div>
        </div>
        
    </div>
    </div>
    </div>
</div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "SouthsideGuideShops",
        data() {
            return {
                shops: [],
                favoriteShops: [],
                selectedCategories: [],
                selectedOfferings: [],
                selectedVillages: [],
                filtersOpen: false
            }
        },
        mounted() {
            this.getShops();
            this.getUserFavorites();
        },
        methods: {
            getShops: function() {
                axios
                    .get('/api/southside-guide/submissions.json')
                    .then(
                        (response) => {
                            this.shops = response.data.data
                        }
                    )
                    .catch(error => console.log(error))
            },
            getUserFavorites: function () {
                this.favoriteShops = this.$storage.get('favoriteShops', []);
            },
            setFavoriteShop: function(shopId) {
                this.favoriteShops.push(shopId);
                this.$storage.set('favoriteShops', this.favoriteShops);
            },
            removeFavoriteShop: function(shopId) {
                this.favoriteShops = this.favoriteShops.filter(shop => shop !== shopId);
                this.$storage.set('favoriteShops', this.favoriteShops);
            },
            isFavorite: function(shopId) {
                return (this.favoriteShops.length && this.favoriteShops.includes(shopId) );
            },
            showAllCategories: function() {
                this.selectedCategories = [];
            },
            toggleFilters: function() {
                this.filtersOpen = !this.filtersOpen;
            },
            filterByBusinessType: function(shop) {
                return !this.selectedCategories.length || shop.submission.whatTypeOfBusinessAreYou.some(cat => this.selectedCategories.includes(cat));
            },
            filterByOffering: function(shop) {
                return !this.selectedOfferings.length || shop.submission.OFFERING.some(cat => this.selectedOfferings.includes(cat));
            },
            filterByVillage: function(shop) {
                return !this.selectedVillages.length || shop.submission.whichNearSoutsideVillageAreYouLocatedIn.some(cat => this.selectedVillages.includes(cat));
            },
            transformCategory: function(category) {
                if(category == 'foodDrink') { return 'Food & Drink'};
                if(category == 'entertainment') { return 'Entertainment'};
                if(category == 'retailGifts') { return 'Retail'};
                if(category == 'specialEventSpaces') { return 'Hospitality'};
                if(category == 'healthBeauty') { return 'Health & Beauty'};
                if(category == 'nonProfit') { return 'Non-Profit'};
                if(category == 'otherServices') { return 'Services'};
            },
            transformVillage: function(village) {
                if(village == 'southMainVillage') { return 'South Main Village'};
                if(village == 'magnoliaVillage') { return 'Magnolia Village'};
                if(village == 'parkPlaceVillage') { return 'Park Place Village'};
                if(village == 'vickeryVillage') { return 'Vickery Village'};
                if(village == 'evansRosedale') { return 'Evans & Rosedale'};
                if(village == 'fairmount') { return 'Fairmount'};
                if(village == 'medicalDistrict') { return 'Medical District'};
                if(village == 'iDontKnow') { return 'Near Southside'};
            }

        },
        computed: {
            shopsToDisplay: function() {
                return this.shops.filter(this.filterByBusinessType).filter(this.filterByOffering).filter(this.filterByVillage);
            }
        }
    }
</script>

<style>

</style>