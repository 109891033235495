<template>
<div class="content-section artists-list bg-white" id="artists-list">
    <div class="artists">
        <div class="artist" v-for="vendor in vendors" :key="vendor.id">
            <div class="image" @click="showVendor(vendor)">
                <img :src="vendor.logo" alt="" v-if="vendor.logo">
            </div>
            <div class="artist-content">
                <div class="content">
                    <div>
                        <h4 class="text-paper artist-medium" v-if="vendor.vendorType"><span v-html="vendor.vendorType"></span></h4>
                        <h3 class="text-white" @click="showVendor(vendor)" v-html="vendor.businessName">
                        </h3>
                        <h5 v-if="vendor.block" class="text-white" v-html="vendor.block"></h5>
                    </div>
                </div>
                <div class="action">
                    <a href="#" class="btn-arrow" @click.prevent="showVendor(vendor)">Learn More</a>
                </div>
            </div>
        </div>
    </div>

    <modal name="vendor-modal" height="90%" v-if="selectedVendor">
        <div class="return-button">
            <i class="far fa-times" @click="closeVendorModal"></i>
        </div>
        <div class="artist-header">
            <div class="artist-nav">
                
                <img src="/artsgoggle-guide/images/AG-site-HEAD.jpg" alt="">
            </div>
            
        </div>
        <div class="image-container artist-images">
            <div class="main-carousel" v-if="selectedVendor.otherImages">
                <vue-carousel ref="carousel" :centerMode="false" :slidesToShow="1" :arrows="false" :loop="false">
                    <img :src="selectedVendor.sampleImage" alt="">
                    <img :src="image" alt="" v-for="image in selectedVendor.otherImages" :key="image">
                </vue-carousel>
                <i class="far fa-chevron-left arrow prev text-black" @click="prevSlide"></i>
                <i class="far fa-chevron-right arrow next text-black" @click="nextSlide"></i>
            </div>
        </div>
        <div class="artist-content">
           
            <div class="artist-details">
                
                <div class="artist-id">
                    
                    <div class="artist-name">
                        <h2 class="text-crazy-pink artist-business">{{ selectedVendor.businessName }}</h2>
                        <h4>{{ selectedVendor.block }}</h4>
                    </div>
                    
                </div>
                
                <div class="artist-description font-heading" v-html="selectedVendor.description">
                </div>
                <div class="artist-description font-heading" v-html="selectedVendor.popularItems">
                </div>
                
            </div>
            <div class="sidebar">
                <div class="toggle-favorite font-heading" v-bind:class="{'favorite-active' : isFavorite(selectedVendor.id)}" @click="isFavorite(selectedVendor.id) ? removeFavoriteArtist(selectedVendor.id) : setFavoriteArtist(selectedVendor.id)">
                    <i class="fas fa-star"></i>
                    <span class="favorite-off"
                        v-show="!isFavorite(selectedVendor.id)"
                    >Add to Favorites</span>
                    <span class="favorite-on"
                        v-show="isFavorite(selectedVendor.id)"
                    >Remove Favorite</span>
                </div>
                
                <div class="contact">
                    
                    <p class="website" v-if="selectedVendor.website">
                        <a :href="formatUrl(selectedVendor.website)" class="btn text-black">View Our Website</a>
                    </p>
                    
                    <h3 v-if="selectedVendor.localBusiness == 'Yes'">Find Me in the Near Southside</h3>
                    
                    <p v-if="selectedVendor.localBusiness == 'Yes'" class="font-heading">{{ selectedVendor.address }}<br>
                    {{ selectedVendor.city }}, TX</p>
                    
                    <h3>Contact</h3>
                    <p class="email font-heading"><a :href="'mailto:' + selectedVendor.email">EMAIL</a></p>
                    <p class="website font-heading" v-if="selectedVendor.website"><a :href="formatUrl(selectedVendor.website)" target="_blank">WEBSITE</a></p>

                    <div v-if="selectedVendor.facebookUrl || selectedVendor.instagramUrl">
                    <h3>FOLLOW</h3>
                    <p class="social">
                        <a :href="formatFB(selectedVendor.facebookUrl)" v-if="selectedVendor.facebookUrl" target="_blank"><i class="fab fa-facebook"></i></a>
                        <a :href="formatIG(selectedVendor.instagramUrl)" v-if="selectedVendor.instagramUrl" target="_blank"><i class="fab fa-instagram"></i></a>
                    </p>
                    </div>
                </div>

            </div>
        </div>
    </modal>
</div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "Vendors",
        data() {
            return {
                vendors: [],
                showFavorites: false,
                selectedVendor: null,
                mainCarousel: null,
                favoriteArtists: []
            }
        },
        mounted() {
            this.getVendors();
            this.getUserFavorites();
        },
        methods: {
            getVendors: function() {
                axios
                    .get('/api/artsgoggle/vendors.json')
                    .then(
                        (response) => {
                            this.vendors = response.data.data
                        }
                    )
                    .catch(error => console.log(error))
            },
            showVendor: function(vendor) {
                this.selectedVendor = vendor;
                this.$nextTick(() => {
                    this.showVendorModal();
                });
            },
            showVendorModal: function() {
                this.$modal.show('vendor-modal');
            },
            closeVendorModal: function() {
                this.$modal.hide('vendor-modal');
                this.selectedVendor = null;
            },
            prevSlide: function() {
                this.$refs.carousel.prev();
            },
            nextSlide: function() {
                this.$refs.carousel.next();
            },
            prevNav: function() {
                this.$refs.carouselNav.prev();
            },
            nextNav: function() {
                this.$refs.carouselNav.next();
            },
            advanceSlide: function(index) {
                this.$refs.carousel.goTo(index);
            },
            getUserFavorites: function () {
                this.favoriteArtists = this.$storage.get('favoriteArtists', []);
            },
            setFavoriteArtist: function(artistId) {
                this.favoriteArtists.push(artistId.toString());
                this.$storage.set('favoriteArtists', this.favoriteArtists);
            },
            removeFavoriteArtist: function(artistId) {
                // console.log('removed');
                this.favoriteArtists = this.favoriteArtists.filter(artist => artist !== artistId.toString());
                this.$storage.set('favoriteArtists', this.favoriteArtists);
            },
            isFavorite: function(artistId) {
                let artist = artistId.toString();
                return (this.favoriteArtists.length && this.favoriteArtists.includes(artist) );
            },
            formatUrl: function(url) {
                var formatted = url;
                if( !url.startsWith('http')) {
                    formatted = 'http://' + url;
                }
                return formatted;
            },
            formatFB: function(str) {
                if( str.startsWith('http')) { //url formatted correctly
                    return str;
                }
                
                if( str.startsWith('facebook')) { //url needs https
                    return 'https://' + str;
                }

                if(str.startsWith('@')) {//user entered @ for some reason
                    return 'https://facebook.com/' + str.substring(1);
                }
                
                return 'https://facebook.com/' + str; //user only entered IG handle
            },
            formatIG: function(str) {

                if( str.startsWith('http')) { //url formatted correctly
                    return str;
                }
                
                if( str.startsWith('instagram')) { //url needs https
                    return 'https://' + str;
                }

                 if(str.startsWith('@')) {//user entered @ for some reason
                    return 'https://instagram.com/' + str.substring(1);
                }
                
                return 'https://instagram.com/' + str; //user only entered IG handle
            },
            formatTw: function(str) {

                if( str.startsWith('http')) { //url formatted correctly
                    return str;
                }
                
                if( str.startsWith('twitter')) { //url needs https
                    return 'https://' + str;
                }

                 if(str.startsWith('@')) {//user entered @ for some reason
                    return 'https://twitter.com/' + str.substring(1);
                }
                
                return 'https://twitter.com/' + str; //user only entered IG handle
            }

        },
    }
</script>

<style lang="scss" scoped>
    .artist {
        .image {
            background: #fff;
            padding: 1rem;
            border: 4px solid #000;
            img {
                object-fit: contain;
            }
        }
    }
</style>