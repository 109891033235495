<template>
<div class="tabs-content members" id="members">
    <form action="#" class="members-search-form">
        <div class="container-medium">
            <div class="custom-select members-select">
                <div class="custom-select-arrow"></div>
                <select v-model="selectedCategory">
                    <option value="">ALL CATEGORIES</option>
                    <option v-for="cat in memberCategories" :key="cat.id" :value="cat.id">{{ cat.title }}</option>
                </select>
            </div>
            <div class="search-field members-search">
                <input placeholder="Search" type="text" v-model="searchQuery">
                <button type="submit" class="btn-search"></button>
            </div>
            <div class="members-option">
                <div class="check check_size-lg check_grey check_rounded">
                    <input v-model="selectedInDistrict" class="checkbox" id="ctrl" type="checkbox">
                    <span></span>
                </div>
                <label for="ctrl">in district</label>
            </div>
        </div>
        <div class="block-bottom-arrow"></div>
    </form>
    
    <div class="container-medium">
        <div class="members-category" v-for="level in memberLevels" :key="level.value">
            <div v-if="membersToDisplay[level.value]">
            <h2 class="page-title-decore-06 members-title"><span class="page-title-decore-06-inner members-title-inner">{{ level.label }} Members</span></h2>
            <table class="members-list">
                <thead>
                    <tr>
                        <th class="col-20">Name</th>
                        <th class="col-25">Address</th>
                        <th class="col-20">Representative</th>
                        <th class="col-25">Contact</th>
                        <th class="col-10">Website</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="member in membersToDisplay[level.value]" :key="member.id">
                        <td class="col-20">{{ member.title }}</td>
                        <td class="col-25">{{ member.address }}<br>{{ member.city }} {{ member.state }}, {{ member.zip }}</td>
                        <td class="col-20">{{ member.representativeName }}</td>
                        <td class="col-25"><span v-if="member.phone">{{member.phone}}<br></span><span v-html="member.email"></span></td>
                        <td class="col-10"><a class="btn btn-decore" :href="member.website" target="blank" v-if="member.website">GO</a></td>
                    </tr>
                    
                </tbody>
            </table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import axios from 'axios';
    import lodash from 'lodash';
    export default {
        name: "Members",
        data() {
            return {
                members: [],
                selectedCategory: '',
                selectedInDistrict: false,
                memberCategories: [],
                memberLevels: [
                    {
                        value : 'sustainingHonorRoll',
                        label : 'Sustaining Honor Roll'
                    },
                    {
                        value : 'sustainingPartner',
                        label : 'Sustaining Partner'
                    },
                    {
                        value : 'diamond',
                        label : 'Diamond'
                    },
                    {
                        value : 'platinum',
                        label : 'Platinum'
                    },
                    {
                        value : 'gold',
                        label : 'Gold'
                    },
                    {
                        value : 'silver',
                        label : 'Silver'
                    },
                    {
                        value : 'bronze',
                        label : 'Bronze'
                    },
                    {
                        value : 'friends',
                        label : 'Friends'
                    },
                ],
                searchQuery: ''
            }
        },
        created() {
            this.getMemberCategories();
        },
        mounted() {
            this.getMembers();
        },
        methods: {
            getMembers: function() {
                axios
                    .get('/api/memberProfiles.json')
                    .then(
                        (response) => {
                            this.members = response.data.data;
                        }
                    )
                    .catch(error => console.log(error))
            },
            getMemberCategories: function() {
                axios
                    .get('/api/memberCategories.json')
                    .then(
                        (response) => {
                            this.memberCategories = response.data.data
                        }
                    )
                    .catch(error => console.log(error))
            },
            selectCategory: function(id) {
                this.selectedCategory = id;
                console.log(id);
            },
            showAllCategories: function() {
                this.selectedCategory = null;
                // console.log(this.selectedCategory);
            },
            filterByCategory: function(member) {
                return !this.selectedCategory || member.category == this.selectedCategory;
            },
            filterByInDistrict: function(member) {
                return this.selectedInDistrict ? member.inDistrict : true;
            },
            filterBySearch: function(member) {
                var title = member.title.toLowerCase();
                return this.searchQuery ? title.indexOf(this.searchQuery.toLowerCase()) > -1 : true;
            }
        },
        computed: {
            membersToDisplay: function() {
                var filtered = this.members.filter(this.filterByCategory).filter(this.filterByInDistrict).filter(this.filterBySearch);
                return _.groupBy(filtered, 'memberLevel');
            }
        }
    }
</script>

<style>
    .filters-wrapper {
        margin-bottom: 36px;
    }
</style>