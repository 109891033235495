<template>
    <div class="toggle-favorite" v-bind:class="{'favorite-active' : isFavorite(this.shopId)}" @click="isFavorite(shopId) ? removeFavoriteShop(shopId) : setFavoriteShop(shopId)">
        <i class="fas fa-heart"></i>
        <span class="favorite-off"
              v-show="!isFavorite(this.shopId)"
             
        >Add to Favorites</span>
        <span class="favorite-on"
              v-show="isFavorite(this.shopId)"
             
        >Remove Favorite</span>
    </div>
</template>

<script>
    export default {
        name: "SouthsideGuideFavoriteButton",
        props: ['shop-id'],
        data() {
            return {
                favoriteShops: []
            }
        },
        mounted() {
            this.getUserFavorites()
        },
        methods: {
            getUserFavorites: function () {
                this.favoriteShops = this.$storage.get('favoriteShops', []);
            },
            setFavoriteShop: function() {
                this.favoriteShops.push(this.shopId.toString());
                this.$storage.set('favoriteShops', this.favoriteShops);
            },
            removeFavoriteShop: function() {
                console.log('removed');
                this.favoriteShops = this.favoriteShops.filter(shop => shop !== this.shopId.toString());
                this.$storage.set('favoriteShops', this.favoriteShops);
            },
            isFavorite: function(shopId) {
                let shop = shopId.toString();
                return (this.favoriteShops.length && this.favoriteShops.includes(shop) );
            }
        }
    }
</script>

<style>

</style>