var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content-section artists-list bg-white",
      attrs: { id: "artists-list" },
    },
    [
      _c(
        "div",
        { staticClass: "artists" },
        _vm._l(_vm.vendors, function (vendor) {
          return _c("div", { key: vendor.id, staticClass: "artist" }, [
            _c(
              "div",
              {
                staticClass: "image",
                on: {
                  click: function ($event) {
                    return _vm.showVendor(vendor)
                  },
                },
              },
              [
                vendor.logo
                  ? _c("img", { attrs: { src: vendor.logo, alt: "" } })
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "artist-content" }, [
              _c("div", { staticClass: "content" }, [
                _c("div", [
                  vendor.vendorType
                    ? _c("h4", { staticClass: "text-paper artist-medium" }, [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(vendor.vendorType) },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h3", {
                    staticClass: "text-white",
                    domProps: { innerHTML: _vm._s(vendor.businessName) },
                    on: {
                      click: function ($event) {
                        return _vm.showVendor(vendor)
                      },
                    },
                  }),
                  _vm._v(" "),
                  vendor.block
                    ? _c("h5", {
                        staticClass: "text-white",
                        domProps: { innerHTML: _vm._s(vendor.block) },
                      })
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "action" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn-arrow",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.showVendor(vendor)
                      },
                    },
                  },
                  [_vm._v("Learn More")]
                ),
              ]),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.selectedVendor
        ? _c("modal", { attrs: { name: "vendor-modal", height: "90%" } }, [
            _c("div", { staticClass: "return-button" }, [
              _c("i", {
                staticClass: "far fa-times",
                on: { click: _vm.closeVendorModal },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "artist-header" }, [
              _c("div", { staticClass: "artist-nav" }, [
                _c("img", {
                  attrs: {
                    src: "/artsgoggle-guide/images/AG-site-HEAD.jpg",
                    alt: "",
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "image-container artist-images" }, [
              _vm.selectedVendor.otherImages
                ? _c(
                    "div",
                    { staticClass: "main-carousel" },
                    [
                      _c(
                        "vue-carousel",
                        {
                          ref: "carousel",
                          attrs: {
                            centerMode: false,
                            slidesToShow: 1,
                            arrows: false,
                            loop: false,
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.selectedVendor.sampleImage,
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _vm._l(
                            _vm.selectedVendor.otherImages,
                            function (image) {
                              return _c("img", {
                                key: image,
                                attrs: { src: image, alt: "" },
                              })
                            }
                          ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("i", {
                        staticClass:
                          "far fa-chevron-left arrow prev text-black",
                        on: { click: _vm.prevSlide },
                      }),
                      _vm._v(" "),
                      _c("i", {
                        staticClass:
                          "far fa-chevron-right arrow next text-black",
                        on: { click: _vm.nextSlide },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "artist-content" }, [
              _c("div", { staticClass: "artist-details" }, [
                _c("div", { staticClass: "artist-id" }, [
                  _c("div", { staticClass: "artist-name" }, [
                    _c(
                      "h2",
                      { staticClass: "text-crazy-pink artist-business" },
                      [_vm._v(_vm._s(_vm.selectedVendor.businessName))]
                    ),
                    _vm._v(" "),
                    _c("h4", [_vm._v(_vm._s(_vm.selectedVendor.block))]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "artist-description font-heading",
                  domProps: {
                    innerHTML: _vm._s(_vm.selectedVendor.description),
                  },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "artist-description font-heading",
                  domProps: {
                    innerHTML: _vm._s(_vm.selectedVendor.popularItems),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sidebar" }, [
                _c(
                  "div",
                  {
                    staticClass: "toggle-favorite font-heading",
                    class: {
                      "favorite-active": _vm.isFavorite(_vm.selectedVendor.id),
                    },
                    on: {
                      click: function ($event) {
                        _vm.isFavorite(_vm.selectedVendor.id)
                          ? _vm.removeFavoriteArtist(_vm.selectedVendor.id)
                          : _vm.setFavoriteArtist(_vm.selectedVendor.id)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fas fa-star" }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isFavorite(_vm.selectedVendor.id),
                            expression: "!isFavorite(selectedVendor.id)",
                          },
                        ],
                        staticClass: "favorite-off",
                      },
                      [_vm._v("Add to Favorites")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isFavorite(_vm.selectedVendor.id),
                            expression: "isFavorite(selectedVendor.id)",
                          },
                        ],
                        staticClass: "favorite-on",
                      },
                      [_vm._v("Remove Favorite")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "contact" }, [
                  _vm.selectedVendor.website
                    ? _c("p", { staticClass: "website" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn text-black",
                            attrs: {
                              href: _vm.formatUrl(_vm.selectedVendor.website),
                            },
                          },
                          [_vm._v("View Our Website")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedVendor.localBusiness == "Yes"
                    ? _c("h3", [_vm._v("Find Me in the Near Southside")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedVendor.localBusiness == "Yes"
                    ? _c("p", { staticClass: "font-heading" }, [
                        _vm._v(_vm._s(_vm.selectedVendor.address)),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.selectedVendor.city) +
                            ", TX"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h3", [_vm._v("Contact")]),
                  _vm._v(" "),
                  _c("p", { staticClass: "email font-heading" }, [
                    _c(
                      "a",
                      { attrs: { href: "mailto:" + _vm.selectedVendor.email } },
                      [_vm._v("EMAIL")]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.selectedVendor.website
                    ? _c("p", { staticClass: "website font-heading" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.formatUrl(_vm.selectedVendor.website),
                              target: "_blank",
                            },
                          },
                          [_vm._v("WEBSITE")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedVendor.facebookUrl ||
                  _vm.selectedVendor.instagramUrl
                    ? _c("div", [
                        _c("h3", [_vm._v("FOLLOW")]),
                        _vm._v(" "),
                        _c("p", { staticClass: "social" }, [
                          _vm.selectedVendor.facebookUrl
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.formatFB(
                                      _vm.selectedVendor.facebookUrl
                                    ),
                                    target: "_blank",
                                  },
                                },
                                [_c("i", { staticClass: "fab fa-facebook" })]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selectedVendor.instagramUrl
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.formatIG(
                                      _vm.selectedVendor.instagramUrl
                                    ),
                                    target: "_blank",
                                  },
                                },
                                [_c("i", { staticClass: "fab fa-instagram" })]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }