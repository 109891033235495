var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-section artists-list bg-white" }, [
    _c(
      "div",
      { staticClass: "artists" },
      _vm._l(_vm.blocksToDisplay, function (block) {
        return _c(
          "div",
          { key: block.id, staticClass: "ajax-holder info-list artist blocks" },
          [
            _c("div", { staticClass: "image" }, [
              _c("img", { attrs: { src: block.sponsorLogo, alt: "" } }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "artist-content" }, [
              _c("div", { staticClass: "content" }, [
                _c("div", [
                  _c("h3", { staticClass: "text-white" }, [
                    _vm._v(_vm._s(block.title)),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(block.locationDescription))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sponsor-info" }, [
                  block.sponsorName
                    ? _c("p", { staticClass: "sponsor-name" }, [
                        _vm._v("Presented by " + _vm._s(block.sponsorName)),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "block-button" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn",
                      attrs: { href: "/guide/block/" + block.slug },
                    },
                    [_vm._v("View Artists & Vendors")]
                  ),
                ]),
              ]),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }