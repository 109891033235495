var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "entertainment-filters" }, [
      _c(
        "button",
        {
          staticClass: "btn",
          class: { active: this.view == "byStage" },
          on: {
            click: function ($event) {
              return _vm.setStageView()
            },
          },
        },
        [_vm._v("View By Stage")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn",
          class: { active: this.view == "byTime" },
          on: {
            click: function ($event) {
              return _vm.setTimeView()
            },
          },
        },
        [_vm._v("View By Time")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.view == "byStage",
            expression: "view == 'byStage'",
          },
        ],
        staticClass: "filters-wrapper filters-open",
      },
      [
        _c("div", { staticClass: "holder-wrap" }, [
          _c("div", { staticClass: "holder-wrap-content" }, [
            _c(
              "div",
              {
                staticClass: "filters-row",
                attrs: { "data-filter-group": "filters" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "container-medium",
                    staticStyle: { "margin-top": "6px" },
                  },
                  [
                    _c("div", { staticClass: "custom-select members-select" }, [
                      _c("div", { staticClass: "custom-select-arrow" }),
                      _vm._v(" "),
                      _vm.stages.length
                        ? _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedStage,
                                  expression: "selectedStage",
                                },
                              ],
                              attrs: { name: "category_sel" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedStage = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("Select Stage"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "optgroup",
                                { attrs: { label: "Magnolia" } },
                                _vm._l(_vm.magnoliaStages, function (stage) {
                                  return _c(
                                    "option",
                                    { domProps: { value: stage.id } },
                                    [_vm._v(_vm._s(stage.title))]
                                  )
                                }),
                                0
                              ),
                              _vm._v(" "),
                              _c(
                                "optgroup",
                                { attrs: { label: "South Main" } },
                                _vm._l(_vm.southMainStages, function (stage) {
                                  return _c(
                                    "option",
                                    { domProps: { value: stage.id } },
                                    [_vm._v(_vm._s(stage.title))]
                                  )
                                }),
                                0
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.view == "byStage",
            expression: "view == 'byStage'",
          },
        ],
      },
      [
        _c(
          "div",
          { staticClass: "ajax-holder info-list artist blocks" },
          _vm._l(_vm.stagesToDisplay, function (stage) {
            return _c("div", { staticClass: "block-section" }, [
              _c("div", { staticClass: "stage-sponsor-section" }, [
                _c(
                  "a",
                  {
                    staticClass: "stage-name",
                    attrs: { href: stage.stageMapLink },
                  },
                  [_vm._v(_vm._s(stage.title))]
                ),
                _vm._v(" "),
                stage.sponsorName
                  ? _c("p", [
                      _vm._v("Presented by " + _vm._s(stage.sponsorName)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "sponsor-logo" }, [
                  stage.sponsorLogo
                    ? _c("img", { attrs: { src: stage.sponsorLogo, alt: "" } })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sponsor-info" }, [
                  _c("div", {
                    staticClass: "sponsor-description",
                    domProps: { innerHTML: _vm._s(stage.sponsorDescription) },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "table",
                  { staticClass: "ag-entertainers-list stage-hidden" },
                  [
                    _vm._m(0, true),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(stage.bands, function (band) {
                        return _c("tr", [
                          _c("td", { staticClass: "band-name" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: band.website, target: "_blank" },
                              },
                              [_vm._v(_vm._s(band.bandName))]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "performance-time" }, [
                            _vm._v(_vm._s(band.performanceTime)),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isFavorite(band.id),
                                  expression: "!isFavorite(band.id)",
                                },
                              ],
                              staticClass: "fas fa-star favorite-off",
                              on: {
                                click: function ($event) {
                                  return _vm.setFavoriteArtist(band.id)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isFavorite(band.id),
                                  expression: "isFavorite(band.id)",
                                },
                              ],
                              staticClass: "fas fa-star favorite-on",
                              on: {
                                click: function ($event) {
                                  return _vm.removeFavoriteArtist(band.id)
                                },
                              },
                            }),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
              ]),
            ])
          }),
          0
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.view == "byTime",
            expression: "view == 'byTime'",
          },
        ],
      },
      [
        _c("table", { staticClass: "ag-entertainers-list stage-hidden" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.performers, function (band) {
              return _c("tr", [
                _c("td", [
                  _c("a", { attrs: { href: band.website, target: "_blank" } }, [
                    _vm._v(_vm._s(band.bandName)),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(band.stageLocation)),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "a",
                    { attrs: { href: band.stageLink, target: "_blank" } },
                    [_vm._v(_vm._s(band.stageAssignment))]
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "performance-time" }, [
                  _vm._v(_vm._s(band.performanceTime)),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isFavorite(band.id),
                        expression: "!isFavorite(band.id)",
                      },
                    ],
                    staticClass: "fas fa-star favorite-off",
                    on: {
                      click: function ($event) {
                        return _vm.setFavoriteArtist(band.id)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isFavorite(band.id),
                        expression: "isFavorite(band.id)",
                      },
                    ],
                    staticClass: "fas fa-star favorite-on",
                    on: {
                      click: function ($event) {
                        return _vm.removeFavoriteArtist(band.id)
                      },
                    },
                  }),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "band-name" }, [_vm._v("Entertainer")]),
        _vm._v(" "),
        _c("th", [_vm._v("Time")]),
        _vm._v(" "),
        _c("th", [_vm._v("Fav?")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Entertainer")]),
        _vm._v(" "),
        _c("th", [_vm._v("Stage")]),
        _vm._v(" "),
        _c("th", [_vm._v("Time")]),
        _vm._v(" "),
        _c("th", [_vm._v("Fav?")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }