var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "lns-bands" }, [
    _c("div", { staticClass: "filters container" }, [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "align-items": "center",
            background: "#fff",
            padding: "1rem 2rem",
          },
          on: { click: _vm.toggleFilters },
        },
        [
          _c("h4", [_vm._v("Filter Performers")]),
          _vm._v(" "),
          _c("span", { staticClass: "filterToggle" }, [
            _c("i", {
              staticClass: "fa fa-chevron-down",
              class: { open: _vm.filtersOpen },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.filtersOpen,
              expression: "filtersOpen",
            },
          ],
          staticClass: "filters-wrap",
        },
        [
          _c("p", { staticClass: "dates" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedDates,
                    expression: "selectedDates",
                  },
                ],
                attrs: { type: "checkbox", value: "March8", id: "" },
                domProps: {
                  checked: Array.isArray(_vm.selectedDates)
                    ? _vm._i(_vm.selectedDates, "March8") > -1
                    : _vm.selectedDates,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.selectedDates,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "March8",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selectedDates = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectedDates = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectedDates = $$c
                    }
                  },
                },
              }),
              _vm._v("March 8"),
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedDates,
                    expression: "selectedDates",
                  },
                ],
                attrs: { type: "checkbox", value: "October11", id: "" },
                domProps: {
                  checked: Array.isArray(_vm.selectedDates)
                    ? _vm._i(_vm.selectedDates, "October11") > -1
                    : _vm.selectedDates,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.selectedDates,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "October11",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selectedDates = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectedDates = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectedDates = $$c
                    }
                  },
                },
              }),
              _vm._v("October 11"),
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedDates,
                    expression: "selectedDates",
                  },
                ],
                attrs: { type: "checkbox", value: "November15", id: "" },
                domProps: {
                  checked: Array.isArray(_vm.selectedDates)
                    ? _vm._i(_vm.selectedDates, "November15") > -1
                    : _vm.selectedDates,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.selectedDates,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "November15",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selectedDates = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectedDates = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectedDates = $$c
                    }
                  },
                },
              }),
              _vm._v("November 15"),
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedDates,
                    expression: "selectedDates",
                  },
                ],
                attrs: { type: "checkbox", value: "ANY", id: "" },
                domProps: {
                  checked: Array.isArray(_vm.selectedDates)
                    ? _vm._i(_vm.selectedDates, "ANY") > -1
                    : _vm.selectedDates,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.selectedDates,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "ANY",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selectedDates = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectedDates = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectedDates = $$c
                    }
                  },
                },
              }),
              _vm._v("Any"),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Solo or Group: ")]),
            _vm._v(" "),
            _c("label", { attrs: { for: "" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedPerformanceTypes,
                    expression: "selectedPerformanceTypes",
                  },
                ],
                attrs: { type: "radio", name: "ptype", value: "solo" },
                domProps: {
                  checked: _vm._q(_vm.selectedPerformanceTypes, "solo"),
                },
                on: {
                  change: function ($event) {
                    _vm.selectedPerformanceTypes = "solo"
                  },
                },
              }),
              _vm._v("Solo"),
            ]),
            _vm._v(" "),
            _c("label", { attrs: { for: "" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedPerformanceTypes,
                    expression: "selectedPerformanceTypes",
                  },
                ],
                attrs: { type: "radio", name: "ptype", value: "band" },
                domProps: {
                  checked: _vm._q(_vm.selectedPerformanceTypes, "band"),
                },
                on: {
                  change: function ($event) {
                    _vm.selectedPerformanceTypes = "band"
                  },
                },
              }),
              _vm._v("Group"),
            ]),
            _vm._v(" "),
            _c("label", { attrs: { for: "" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedPerformanceTypes,
                    expression: "selectedPerformanceTypes",
                  },
                ],
                attrs: {
                  type: "radio",
                  name: "ptype",
                  value: "any",
                  checked: "",
                },
                domProps: {
                  checked: _vm._q(_vm.selectedPerformanceTypes, "any"),
                },
                on: {
                  change: function ($event) {
                    _vm.selectedPerformanceTypes = "any"
                  },
                },
              }),
              _vm._v("Any"),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Willing to Discuss Donation: ")]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedDonation,
                    expression: "selectedDonation",
                  },
                ],
                attrs: { type: "checkbox", value: "yes", id: "" },
                domProps: {
                  checked: Array.isArray(_vm.selectedDonation)
                    ? _vm._i(_vm.selectedDonation, "yes") > -1
                    : _vm.selectedDonation,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.selectedDonation,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "yes",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selectedDonation = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectedDonation = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectedDonation = $$c
                    }
                  },
                },
              }),
              _vm._v("Yes"),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Music Type: ")]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedMusicTypes,
                    expression: "selectedMusicTypes",
                  },
                ],
                attrs: { type: "checkbox", value: "original", id: "" },
                domProps: {
                  checked: Array.isArray(_vm.selectedMusicTypes)
                    ? _vm._i(_vm.selectedMusicTypes, "original") > -1
                    : _vm.selectedMusicTypes,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.selectedMusicTypes,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "original",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selectedMusicTypes = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectedMusicTypes = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectedMusicTypes = $$c
                    }
                  },
                },
              }),
              _vm._v("Original Composition"),
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedMusicTypes,
                    expression: "selectedMusicTypes",
                  },
                ],
                attrs: { type: "checkbox", value: "cover", id: "" },
                domProps: {
                  checked: Array.isArray(_vm.selectedMusicTypes)
                    ? _vm._i(_vm.selectedMusicTypes, "cover") > -1
                    : _vm.selectedMusicTypes,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.selectedMusicTypes,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "cover",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selectedMusicTypes = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectedMusicTypes = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectedMusicTypes = $$c
                    }
                  },
                },
              }),
              _vm._v("Tribute or Cover"),
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedMusicTypes,
                    expression: "selectedMusicTypes",
                  },
                ],
                attrs: { type: "checkbox", value: "dj", id: "" },
                domProps: {
                  checked: Array.isArray(_vm.selectedMusicTypes)
                    ? _vm._i(_vm.selectedMusicTypes, "dj") > -1
                    : _vm.selectedMusicTypes,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.selectedMusicTypes,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "dj",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selectedMusicTypes = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectedMusicTypes = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectedMusicTypes = $$c
                    }
                  },
                },
              }),
              _vm._v("DJ"),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Can Provide PA: ")]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedPaType,
                    expression: "selectedPaType",
                  },
                ],
                attrs: { type: "radio", value: "myOwnSound", id: "" },
                domProps: { checked: _vm._q(_vm.selectedPaType, "myOwnSound") },
                on: {
                  change: function ($event) {
                    _vm.selectedPaType = "myOwnSound"
                  },
                },
              }),
              _vm._v("Yes"),
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedPaType,
                    expression: "selectedPaType",
                  },
                ],
                attrs: { type: "radio", value: "venueSound", id: "" },
                domProps: { checked: _vm._q(_vm.selectedPaType, "venueSound") },
                on: {
                  change: function ($event) {
                    _vm.selectedPaType = "venueSound"
                  },
                },
              }),
              _vm._v("No"),
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedPaType,
                    expression: "selectedPaType",
                  },
                ],
                attrs: { type: "radio", value: "acoustic", id: "" },
                domProps: { checked: _vm._q(_vm.selectedPaType, "acoustic") },
                on: {
                  change: function ($event) {
                    _vm.selectedPaType = "acoustic"
                  },
                },
              }),
              _vm._v("PA Not Required"),
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedPaType,
                    expression: "selectedPaType",
                  },
                ],
                attrs: { type: "radio", value: "any", id: "" },
                domProps: { checked: _vm._q(_vm.selectedPaType, "any") },
                on: {
                  change: function ($event) {
                    _vm.selectedPaType = "any"
                  },
                },
              }),
              _vm._v("Any"),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Acoustic or Amplified: ")]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedAmplified,
                    expression: "selectedAmplified",
                  },
                ],
                attrs: { type: "radio", value: "Prefers Acoustic", id: "" },
                domProps: {
                  checked: _vm._q(_vm.selectedAmplified, "Prefers Acoustic"),
                },
                on: {
                  change: function ($event) {
                    _vm.selectedAmplified = "Prefers Acoustic"
                  },
                },
              }),
              _vm._v("Prefers Acoustic"),
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedAmplified,
                    expression: "selectedAmplified",
                  },
                ],
                attrs: { type: "radio", value: "Amplified", id: "" },
                domProps: {
                  checked: _vm._q(_vm.selectedAmplified, "Amplified"),
                },
                on: {
                  change: function ($event) {
                    _vm.selectedAmplified = "Amplified"
                  },
                },
              }),
              _vm._v("Amplified"),
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedAmplified,
                    expression: "selectedAmplified",
                  },
                ],
                attrs: {
                  type: "radio",
                  value: "Acoustic or Amplified",
                  id: "",
                },
                domProps: {
                  checked: _vm._q(
                    _vm.selectedAmplified,
                    "Acoustic or Amplified"
                  ),
                },
                on: {
                  change: function ($event) {
                    _vm.selectedAmplified = "Acoustic or Amplified"
                  },
                },
              }),
              _vm._v("Either"),
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedAmplified,
                    expression: "selectedAmplified",
                  },
                ],
                attrs: { type: "radio", value: "any", id: "" },
                domProps: { checked: _vm._q(_vm.selectedAmplified, "any") },
                on: {
                  change: function ($event) {
                    _vm.selectedAmplified = "any"
                  },
                },
              }),
              _vm._v("Any"),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Indoors or Outdoors: ")]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedIndoors,
                    expression: "selectedIndoors",
                  },
                ],
                attrs: { type: "radio", value: "indoorsOnly", id: "" },
                domProps: {
                  checked: _vm._q(_vm.selectedIndoors, "indoorsOnly"),
                },
                on: {
                  change: function ($event) {
                    _vm.selectedIndoors = "indoorsOnly"
                  },
                },
              }),
              _vm._v("Indoors"),
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedIndoors,
                    expression: "selectedIndoors",
                  },
                ],
                attrs: { type: "radio", value: "indoorsOrOutdoors", id: "" },
                domProps: {
                  checked: _vm._q(_vm.selectedIndoors, "indoorsOrOutdoors"),
                },
                on: {
                  change: function ($event) {
                    _vm.selectedIndoors = "indoorsOrOutdoors"
                  },
                },
              }),
              _vm._v("Outdoors"),
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedIndoors,
                    expression: "selectedIndoors",
                  },
                ],
                attrs: { type: "radio", value: "any", id: "" },
                domProps: { checked: _vm._q(_vm.selectedIndoors, "any") },
                on: {
                  change: function ($event) {
                    _vm.selectedIndoors = "any"
                  },
                },
              }),
              _vm._v("Either"),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "container" },
      [
        this.bandsToDisplay.length == 0
          ? _c("div", { staticClass: "noresults" }, [
              _c("h3", [_vm._v("Loading results...")]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(this.bandsToDisplay, function (band) {
          return _c("div", { key: band.id }, [
            _c("div", { staticClass: "band" }, [
              _c("div", { staticClass: "header" }, [
                _c("h2", [_vm._v(_vm._s(band.bandName))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "top" }, [
                  _c("div", { staticClass: "dates-booked" }, [
                    band.bookedFor.includes("March8")
                      ? _c("span", { staticClass: "date-tag" }, [
                          _c("em", [_vm._v("Booked")]),
                          _vm._v("Mar 8"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    band.bookedFor.includes("October11")
                      ? _c("span", { staticClass: "date-tag" }, [
                          _c("em", [_vm._v("Booked")]),
                          _vm._v("Oct 11"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    band.bookedFor.includes("November15")
                      ? _c("span", { staticClass: "date-tag" }, [
                          _c("em", [_vm._v("Booked")]),
                          _vm._v("Nov 15"),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "dates-wrap" }, [
                    _c("div", { staticClass: "dates-row" }, [
                      _c("h4", [_vm._v("AVAILABLE TO PERFORM: ")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "dates" }, [
                        band.performanceDates.includes("March8")
                          ? _c("span", { staticClass: "date-tag" }, [
                              _c("i", {
                                staticClass:
                                  "fa-sharp fa-solid fa-circle-check",
                              }),
                              _vm._v(" March 8"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        band.performanceDates.includes("October11")
                          ? _c("span", { staticClass: "date-tag" }, [
                              _c("i", {
                                staticClass:
                                  "fa-sharp fa-solid fa-circle-check",
                              }),
                              _vm._v(" October 11"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        band.performanceDates.includes("November15")
                          ? _c("span", { staticClass: "date-tag" }, [
                              _c("i", {
                                staticClass:
                                  "fa-sharp fa-solid fa-circle-check",
                              }),
                              _vm._v(" November 15"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        band.performanceDates.includes("ANY")
                          ? _c("span", { staticClass: "date-tag" }, [
                              _c("i", {
                                staticClass:
                                  "fa-sharp fa-solid fa-circle-check",
                              }),
                              _vm._v(" Any"),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "size" }, [
                    _c("h4", [_vm._v("BAND MEMBERS: ")]),
                    _vm._v(" "),
                    _c("span", {
                      domProps: { innerHTML: _vm._s(band.bandSize) },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "band-info" }, [
                  _c("div", { staticClass: "media" }, [
                    _c(
                      "div",
                      { staticClass: "slider-wrap" },
                      [
                        _c(
                          "vue-carousel",
                          {
                            ref: band.id,
                            refInFor: true,
                            staticClass: "glide",
                            attrs: {
                              settings: _vm.slickSettings,
                              arrows: false,
                            },
                          },
                          _vm._l(band.photos, function (image) {
                            return _c("img", {
                              key: image,
                              attrs: { src: image, alt: "" },
                            })
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "arrows" }, [
                          _c("i", {
                            staticClass:
                              "fa-solid fa-circle-chevron-left fa-2xl arrow prev",
                            on: {
                              click: function ($event) {
                                return _vm.showPrev(band)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("i", {
                            staticClass:
                              "fa-solid fa-circle-chevron-right fa-2xl arrow prev",
                            on: {
                              click: function ($event) {
                                return _vm.showNext(band)
                              },
                            },
                          }),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "details" }, [
                    _c("div", { staticClass: "fee-info" }, [
                      _c("div", { staticClass: "donation" }, [
                        _c("span", [
                          _c("input", {
                            attrs: { type: "checkbox", value: "yes" },
                            domProps: {
                              checked: band.willingToDonate === "yes",
                            },
                          }),
                          _vm._v(" Willing To Discuss Donation"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "fee" }, [
                        _c("span", { staticClass: "label" }, [_vm._v("FEE: ")]),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "amount",
                          domProps: { innerHTML: _vm._s(band.fee) },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "social" }, [
                      band.musicLink
                        ? _c(
                            "a",
                            {
                              attrs: { href: band.musicLink, target: "_blank" },
                            },
                            [
                              _c("i", {
                                staticClass: "fa-solid fa-headphones",
                              }),
                              _vm._v(" LISTEN"),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      band.videoLink
                        ? _c(
                            "a",
                            {
                              attrs: { href: band.videoLink, target: "_blank" },
                            },
                            [
                              _c("i", { staticClass: "fa-solid fa-tv-retro" }),
                              _vm._v(" VIDEO"),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      band.stagePlot
                        ? _c(
                            "a",
                            {
                              attrs: { href: band.stagePlot, target: "_blank" },
                            },
                            [
                              _c("i", {
                                staticClass: "fa-solid fa-guitar-electric",
                              }),
                              _vm._v(" STAGE PLOT"),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", [
                        band.facebook
                          ? _c(
                              "a",
                              {
                                staticClass: "icon",
                                attrs: {
                                  href: band.facebook,
                                  target: "_blank",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa-brands fa-facebook",
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        band.instagram
                          ? _c(
                              "a",
                              {
                                staticClass: "icon",
                                attrs: {
                                  href: band.instagram,
                                  target: "_blank",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa-brands fa-square-instagram",
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        band.website
                          ? _c(
                              "a",
                              {
                                staticClass: "icon",
                                attrs: { href: band.website, target: "_blank" },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa-regular fa-globe-pointer",
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      [
                        _c("strong", [_vm._v("Genre: ")]),
                        _vm._v(" "),
                        _vm._l(band.genres, function (genre, index) {
                          return _c("span", { key: genre.label }, [
                            _c("span", [_vm._v(_vm._s(genre.label))]),
                            index != band.genres.length - 1
                              ? _c("span", [_vm._v(" / ")])
                              : _vm._e(),
                          ])
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("p", [
                      _c("strong", [_vm._v("Seeking Booking As: ")]),
                      _c("span", [_vm._v(_vm._s(band.seekingBookingAs.label))]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      [
                        _c("strong", [_vm._v("Performance Type: ")]),
                        _vm._v(" "),
                        _vm._l(band.performanceType, function (ptype, index) {
                          return _c("span", { key: index }, [
                            _c("span", [_vm._v(_vm._s(ptype))]),
                            index != band.performanceType.length - 1
                              ? _c("span", [_vm._v(" / ")])
                              : _vm._e(),
                          ])
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("p", [
                      _c("strong", [_vm._v("Load In: ")]),
                      band.loadInTime
                        ? _c("span", [_vm._v(_vm._s(band.loadInTime.label))])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "tags",
                        staticStyle: { "margin-top": "2rem" },
                      },
                      [
                        band.yourGearOrOurs.value == "myOwnSound"
                          ? _c("span", { staticClass: "tag" }, [
                              _vm._v("Can Provide PA"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        band.hasManagement.value == "Yes"
                          ? _c("span", { staticClass: "tag" }, [
                              _vm._v("Has Professional Management"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        band.isTheMusicYouPlayLicensed.value == "Yes"
                          ? _c("span", { staticClass: "tag" }, [
                              _vm._v("ASCAP/BMI License Required"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        band.stageTypes.value == "formalStagesOnly"
                          ? _c("span", { staticClass: "tag" }, [
                              _vm._v("Formal Stages Only"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        (band.stageTypes.value = "informalSpaces")
                          ? _c("span", { staticClass: "tag" }, [
                              _vm._v("Informal Stage OK"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        band.performanceLocations.value == "indoorsOnly"
                          ? _c("span", { staticClass: "tag" }, [
                              _vm._v("Indoors Only"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        band.performanceLocations.value == "indoorsAndOutdoors"
                          ? _c("span", { staticClass: "tag" }, [
                              _vm._v("Indoors or Outdoors"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "tag",
                          domProps: { innerHTML: _vm._s(band.acousticOrAmped) },
                        }),
                        _vm._v(" "),
                        band.liveOrWorkInNearSouthside.value == "yes"
                          ? _c("span", { staticClass: "tag" }, [
                              _vm._v("Near Southside Local"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text-block" }, [
                  _c("strong", [_vm._v("VIBE CHECK: ")]),
                  _c("p", [_vm._v(_vm._s(band.yourVibe))]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "header grad",
                    on: {
                      click: function ($event) {
                        return _vm.toggleBioSection(
                          band.id,
                          !_vm.bioOpenStatus[band.id]
                        )
                      },
                    },
                  },
                  [
                    _c("h2", [_vm._v("BIO")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "toggle" }, [
                      _c("i", {
                        staticClass: "fa fa-chevron-down",
                        class: { open: _vm.bioOpenStatus[band.id] },
                      }),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.bioOpenStatus[band.id],
                        expression: "bioOpenStatus[band.id]",
                      },
                    ],
                    staticClass: "content text-md",
                  },
                  [_c("p", { domProps: { innerHTML: _vm._s(band.bio) } })]
                ),
              ]),
              _vm._v(" "),
              band.specialRequestsOrConsiderations
                ? _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.toggleSpecialSection(
                            band.id,
                            !_vm.specialOpenStatus[band.id]
                          )
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "header grad" }, [
                        _c("h2", [
                          _vm._v("SPECIFIC REQUIREMENTS OR SPECIAL REQUESTS"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "toggle" }, [
                          _c("i", {
                            staticClass: "fa fa-chevron-down",
                            class: { open: _vm.specialOpenStatus[band.id] },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.specialOpenStatus[band.id],
                              expression: "specialOpenStatus[band.id]",
                            },
                          ],
                          staticClass: "content text-md",
                        },
                        [
                          _c("p", {
                            domProps: {
                              innerHTML: _vm._s(
                                band.specialRequestsOrConsiderations
                              ),
                            },
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "header grad",
                    on: {
                      click: function ($event) {
                        return _vm.toggleContactSection(
                          band.id,
                          !_vm.contactSectionStatus[band.id]
                        )
                      },
                    },
                  },
                  [
                    _c("h2", [_vm._v("CONTACT INFO")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "toggle" }, [
                      _c("i", {
                        staticClass: "fa fa-chevron-down",
                        class: { open: _vm.contactSectionStatus[band.id] },
                      }),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.contactSectionStatus[band.id],
                        expression: "contactSectionStatus[band.id]",
                      },
                    ],
                    staticClass: "content contact text-md",
                  },
                  [
                    _c("div", { staticClass: "half" }, [
                      _c("h2", [_vm._v("PRIMARY CONTACT")]),
                      _vm._v(" "),
                      _c("p", [
                        _c("strong", [_vm._v("NAME: ")]),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(band.primaryContactName),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("strong", [_vm._v("EMAIL: ")]),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(band.primaryContactEmail),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("strong", [_vm._v("CELL: ")]),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(band.primaryContactCell),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("strong", [_vm._v("AFFILIATION: ")]),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(band.primaryContactAffiliation),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("strong", [_vm._v("PREFERRED MEANS OF CONTACT: ")]),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              band.primaryContactsPreferredType.label
                            ),
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "half" }, [
                      _c("h2", [_vm._v("SECONDARY CONTACT")]),
                      _vm._v(" "),
                      _c("p", [
                        _c("strong", [_vm._v("NAME: ")]),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(band.secondaryContactName),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("strong", [_vm._v("EMAIL: ")]),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(band.secondaryContactEmail),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("strong", [_vm._v("CELL: ")]),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(band.secondaryContactCell),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("strong", [_vm._v("AFFILIATION: ")]),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(band.secondaryContactAffiliation),
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("strong", [_vm._v("Dates Available: ")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }