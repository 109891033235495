<template>
    <div class="content-section artists-list bg-white">
        
        <div class="artists"> <!--  class="ajax-holder info-list artist" -->
            <div class="ajax-holder info-list artist blocks" v-for="block in blocksToDisplay" :key="block.id">
                <div class="image">
                    <img :src="block.sponsorLogo" alt="">
                </div>
                <div class="artist-content">
                    <div class="content">
                        <div>
                            <h3 class="text-white">{{ block.title }}</h3>
                            <p>{{ block.locationDescription }}</p>
                        </div>
                        <div class="sponsor-info">
                            <p class="sponsor-name" v-if="block.sponsorName">Presented by {{ block.sponsorName }}</p>
                        </div>
                        <p class="block-button"><a :href="'/guide/block/'+block.slug" class="btn">View Artists & Vendors</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "ArtsgoggleBlocks",
        data() {
            return {
                selectedLocation: "",
                blocks: {}
            }
        },
        mounted() {
            this.getAllBlocks()
        },
        methods: {
            getAllBlocks: function() {
                axios
                    .get('/api/artsgoggle/blocks.json')
                    .then(
                        (response) => {
                            this.blocks = response.data.data
                        }
                    )
                    .catch(error => console.log(error))
            }

        },
        computed: {
            blocksToDisplay: function() {
                if (this.selectedLocation) {
                    return this.blocks.filter(block => block.blockLocation.value == this.selectedLocation)
                }

                return this.blocks
            }
        }
    }
</script>

<style scoped>
    .artist .image {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        border: 2px solid #224461;
        padding: 1rem;
    }
    .artist .image img {
        width: auto;
        object-fit: contain;
    }
</style>