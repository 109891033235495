<template>
<div class="content-section events-list" id="events-list">
    <div class="filter-events container text-denim" @click="toggleFilters">
        <a class="btn-arrow text-denim" href="#" @click.prevent="">Filter Events</a>
    </div>
    <div class="events">
        <div class="event" v-for="event in eventsToDisplay" :key="event.id">
            <div class="image" @click="showEventModal(event)">
                <img :src="event.image" alt="" v-if="event.image">
                <div class="event-date bg-sunflower" v-if="event.startDate == event.endDate">
                    <div class="month" v-text="getMonth(event.startDate)"></div>
                    <div class="date" v-text="getDate(event)"></div>
                </div>
                <div class="event-date bg-sunflower" v-else>
                    <div class="month">DATES</div>
                    <div class="date">VARY</div>
                </div>
            </div>
            <div class="event-content">
                <!-- <div class="tags">
                    <p>
                        {{ event.eventType.title }}
                    </p>
                </div> -->
                <div class="content">
                    <div>
                        <h3 @click="showEventModal(event)" class="text-sunflower">{{ event.title }}</h3>
                        <div v-html="event.description" class="event-description"></div>
                    </div>
                    <div>
                        <div class="contact">
                            <span>{{ event.location }}</span>
                            <span>{{ event.address }}</span>
                        </div>
                        
                    </div>
                </div>
                <div class="action">
                    <a href="#" class="btn-arrow" @click.prevent="showEventModal(event)">Learn More</a>
                </div>
            </div>
        </div>
    
    </div>
    <div class="filters white" :class="{open: filtersOpen}" id="filter-sidebar">
        <div class="close-filters" @click="toggleFilters">
            <i class="far fa-times"></i>
        </div>
        <div class="filters-inner">
            <div class="filter-section">
                <h3>Location:</h3>
                <div class="filter-list">
                    <label class="checkbox" v-for="village in villages" :key="village.id">
                        <span class="checkbox__input">
                            <input type="checkbox" name="location" :value="village.id" v-model="selectedVillages">
                            <span class="checkbox__control">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                                <path fill='none' stroke='currentColor' stroke-width='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
                            </span>
                        </span>
                        <span class="radio__label">{{ village.title }}</span>
                    </label>
                </div>
            </div>
            <div class="filter-section">
                <h3>Event Type:</h3>
                <div class="filter-list">
                    <label class="checkbox" v-for="type in eventTypes" :key="type.id">
                        <span class="checkbox__input">
                            <input type="checkbox" name="eventType" :value="type.id" v-model="selectedTypes">
                            <span class="checkbox__control">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                                <path fill='none' stroke='currentColor' stroke-width='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
                            </span>
                        </span>
                        <span class="radio__label">{{ type.title }}</span>
                    </label>
                </div>
            </div>
        </div>
            
    </div>
    <modal name="event-modal" height="90%" v-if="selectedEvent">
        <div class="return-button">
            <i class="far fa-times" @click="closeEventModal"></i>
        </div>
        <div class="image-container">
            <vue-carousel ref="carousel" :centerMode="false" :slidesToShow="1" :arrows="false">
                <img :src="image" alt="" v-for="image in selectedEvent.eventImages" :key="image">
            </vue-carousel>
            <i class="far fa-chevron-left arrow prev" @click="prevSlide"></i>
            <i class="far fa-chevron-right arrow next" @click="nextSlide"></i>
        </div>
        <div class="event-content">
            <div class="event-details">
                <h2>{{ selectedEvent.title }}</h2>
                <h3 v-if="selectedEvent.subheadline">{{ selectedEvent.subheadline }}</h3>
                
                <div class="event-description" v-html="selectedEvent.fullDescription">    
                </div>

                {{ selectedEvent.eventDetails }}
            </div>
            <div class="sidebar bg-black">
                <h3 v-html="getFullDate(selectedEvent)"></h3>
                <p v-html="getTimes(selectedEvent)" v-if="!selectedEvent.allDay" class="event-time"></p>
                <p v-else class="event-time">All Day</p>
                <!-- <div class="btn-holder">
                    <a href="#" class="btn">
                        <span>Add to Calendar</span>
                        <div class="ease emerald"></div>
                    </a>
                </div>
                <div class="btn-holder">
                    <a href="#" class="btn ">
                        <span>Add to Calendar</span>
                        <div class="ease emerald"></div>
                    </a>
                </div> -->
                <h4 v-if="selectedEvent.price" class="event-price">Price: {{ selectedEvent.price }}</h4>
                <h4 v-else class="event-price">Free Event</h4>

                <a :href="selectedEvent.ctaUrl" class="btn bg-sunflower" v-if="selectedEvent.ctaUrl">{{ selectedEvent.ctaText }}</a>
            
                <div class="contact">
                    <h3 v-if="selectedEvent.location" class="location-name">{{ selectedEvent.location }}</h3>
                    <p v-if="selectedEvent.address">{{ selectedEvent.address}}<br>
                    {{ selectedEvent.city }}, TX {{ selectedEvent.zip }}</p>
                    <p class="phone" v-if="selectedEvent.phone">{{ selectedEvent.phone }}</p>
                    <p class="email"><a :href="'mailto:' + selectedEvent.email">EMAIL</a></p>
                    <p class="website" v-if="selectedEvent.website">
                        <a :href="formatUrl(selectedEvent.website)">View Website</a>
                    </p>
                    
                </div>
           
                <div class="social-networks">
                    <span v-if="selectedEvent.twitterUrl">
                        <a class="social-icon" :href="selectedEvent.twitterUrl" rel="nofollow" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                    </span>
                    <span v-if="selectedEvent.facebookUrl">
                        <a class="social-icon" :href="selectedEvent.facebookUrl" rel="nofollow" target="_blank">
                            <i class="fab fa-facebook"></i>
                        </a>
                    </span>
                    <span v-if="selectedEvent.instagramUrl">
                        <a class="social-icon" :href="selectedEvent.instagramUrl" rel="nofollow" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </span>
                    <span v-if="selectedEvent.youtubeUrl">
                        <a class="social-icon" :href="selectedEvent.youtubeUrl" rel="nofollow" target="_blank">
                            <i class="fab fa-youtube"></i>
                        </a>
                    </span>
                </div>
                <div class="links">
                    <a :href="selectedEvent.sponsorUrl" class="btn powder" v-if="selectedEvent.sponsorUrl">Sponsor</a>
                    <a :href="selectedEvent.volunteerUrl" class="btn powder" v-if="selectedEvent.volunteerUrl">Volunteer</a>
                </div>
            </div>
        </div>
    </modal>
</div>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment';
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    export default {
        name: "Events",
        data() {
            return {
                events: [],
                selectedTypes: [],
                selectedVillages: [],
                filtersOpen: false,
                eventTypes: [],
                villages: [],
                selectedEvent: null
            }
        },
        created() {
            this.getEventTypes();
            this.getVillages();
        },
        mounted() {
            this.getEvents();
            this.initStickySidebar();
        },
        methods: {
            getMonth : function (date) {
                return moment(date).format('MMM');
            },
            getDate : function (event) {
                let startDate = moment(event.startDate).format('DD');
                let endDate = moment(event.endDate).format('DD');
                let dates = '';
                if( startDate == endDate ) {
                    dates = startDate;
                } else {
                    dates = startDate + ' - ' + endDate;
                }
                return dates;
            },
            getFullDate: function(event) {
                let startDate = moment(event.startDate).format('MMM DD');
                let endDate = moment(event.endDate).format('MMM DD');
                
                let dates = '';
                if( startDate == endDate ) {
                    dates = startDate;
                } else {
                    dates = startDate + ' - ' + endDate;
                }
                return dates;
            },
            getTimes: function(event) {
                let startTime = event.startTime ? moment(event.startTime).format('h:mm A') : '';
                let endTime = event.endTime ? moment(event.endTime).format('h:mm A') : '';
                return startTime + ' - ' + endTime;
            },
            formatUrl: function(url) {
                let fullUrl = '';
                if(url.startsWith('http')) {
                    fullUrl = url;
                } else {
                    fullUrl = 'http://' + url;
                }

                return fullUrl;
            },
            getEvents: function() {
                axios
                    .get('/api/events.json')
                    .then(
                        (response) => {
                            this.events = response.data.data;
                            this.selectedEvent = this.events[0];
                        }
                    )
                    .catch(error => console.log(error))
            },
            getEventTypes: function() {
                console.log('types');
                axios
                    .get('/api/eventTypes.json')
                    .then(
                        (response) => {
                            this.eventTypes = response.data.data
                        }
                    )
                    .catch(error => console.log(error))
            },
            getVillages: function() {
                axios
                    .get('/api/villages.json')
                    .then(
                        (response) => {
                            this.villages = response.data.data
                        }
                    )
                    .catch(error => console.log(error))
            },
            toggleFilters: function() {
                this.filtersOpen = !this.filtersOpen;
            },
            showEventModal: function(event) {
                this.selectedEvent = event;
                this.$modal.show('event-modal');
            },
            closeEventModal: function() {
                this.$modal.hide('event-modal');
                // this.selectedEvent = null;
            },
            showAllCategories: function() {
                this.selectedCategory = null;
                // console.log(this.selectedCategory);
            },
            filterByType: function(event) {
                return !this.selectedTypes.length || this.selectedTypes.includes(event.types[0]);
            },
            filterByVillage: function(event) {
                return !this.selectedVillages.length || this.selectedVillages.includes(event.villages[0].id);
            },
            initStickySidebar: function() {

                var sidebar = new StickySidebar('#filter-sidebar', {
                    containerSelector: '#events-list',
                    innerWrapperSelector: '.filters-inner',
                    minWidth: 960,
                    topSpacing: 48,
                    bottomSpacing: 48
                });
            },
            prevSlide: function() {
                this.$refs.carousel.prev();
            },
            nextSlide: function() {
                this.$refs.carousel.next();
            }
        },
        computed: {
            eventsToDisplay: function() {
                return this.events.filter(this.filterByType).filter(this.filterByVillage);
            }
        }
    }

</script>

<style scoped>
    
</style>