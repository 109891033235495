<template>
<div class="lns-bands">
    <div class="filters container">
        <div @click="toggleFilters" style="display: flex; justify-content: space-between; align-items: center; background: #fff; padding: 1rem 2rem;">
            <h4>Filter Performers</h4>
            <span class="filterToggle"><i class="fa fa-chevron-down" :class="{ 'open': filtersOpen }"></i></span>
        </div>
        <div class="filters-wrap" v-show="filtersOpen">
            
            <p class="dates">
                <span><strong>Dates Available: </strong></span>
                <label><input type="checkbox" value="March8" id="" v-model="selectedDates">March 8</label>
                <label><input type="checkbox" value="October11" id="" v-model="selectedDates">October 11</label>
                <label><input type="checkbox" value="November15" id="" v-model="selectedDates">November 15</label>
                <label><input type="checkbox" value="ANY" id="" v-model="selectedDates">Any</label>
            </p>
            <p><strong>Solo or Group: </strong>
                <label for=""><input type="radio" name="ptype" value="solo" v-model="selectedPerformanceTypes">Solo</label>
                <label for=""><input type="radio" name="ptype" value="band" v-model="selectedPerformanceTypes">Group</label>
                <label for=""><input type="radio" name="ptype" value="any" v-model="selectedPerformanceTypes" checked>Any</label>
            </p>
            <p><strong>Willing to Discuss Donation: </strong>
                <label><input type="checkbox" value="yes" id="" v-model="selectedDonation">Yes</label>
            </p>
            <p><strong>Music Type: </strong>
                <label><input type="checkbox" value="original" id="" v-model="selectedMusicTypes">Original Composition</label>
                <label><input type="checkbox" value="cover" id="" v-model="selectedMusicTypes">Tribute or Cover</label>
                <label><input type="checkbox" value="dj" id="" v-model="selectedMusicTypes">DJ</label>
            </p>
            <p><strong>Can Provide PA: </strong>
                <label><input type="radio" value="myOwnSound" id="" v-model="selectedPaType">Yes</label>
                <label><input type="radio" value="venueSound" id="" v-model="selectedPaType">No</label>
                <label><input type="radio" value="acoustic" id="" v-model="selectedPaType">PA Not Required</label>
                <label><input type="radio" value="any" id="" v-model="selectedPaType">Any</label>
            </p>
            <p><strong>Acoustic or Amplified: </strong>
                <label><input type="radio" value="Prefers Acoustic" id="" v-model="selectedAmplified">Prefers Acoustic</label>
                <label><input type="radio" value="Amplified" id="" v-model="selectedAmplified">Amplified</label>
                <label><input type="radio" value="Acoustic or Amplified" id="" v-model="selectedAmplified">Either</label>
                <label><input type="radio" value="any" id="" v-model="selectedAmplified">Any</label>
            </p>
            <p><strong>Indoors or Outdoors: </strong>
                <label><input type="radio" value="indoorsOnly" id="" v-model="selectedIndoors">Indoors</label>
                <label><input type="radio" value="indoorsOrOutdoors" id="" v-model="selectedIndoors">Outdoors</label>
                <label><input type="radio" value="any" id="" v-model="selectedIndoors">Either</label>
            </p>
        </div>
    </div>
    <div class="container">
        <div class="noresults" v-if="this.bandsToDisplay.length == 0">
            <h3>Loading results...</h3>
        </div>
        <div v-for="band in this.bandsToDisplay" :key="band.id">
        <div class="band">
            <div class="header">
                <h2>{{ band.bandName }}</h2>
            </div>
            <div class="content">
                
                <div class="top">
                    <div class="dates-booked">
                        <span class="date-tag" v-if="band.bookedFor.includes('March8')"><em>Booked</em>Mar 8</span>
                        <span class="date-tag" v-if="band.bookedFor.includes('October11')"><em>Booked</em>Oct 11</span>
                        <span class="date-tag" v-if="band.bookedFor.includes('November15')"><em>Booked</em>Nov 15</span>
                    </div>
                    <div class="dates-wrap">
                        <div class="dates-row">
                            <h4>AVAILABLE TO PERFORM: </h4>
                            <div class="dates">
                                <span class="date-tag" v-if="band.performanceDates.includes('March8')"><i class="fa-sharp fa-solid fa-circle-check"></i> March 8</span>
                                <span class="date-tag" v-if="band.performanceDates.includes('October11')"><i class="fa-sharp fa-solid fa-circle-check"></i> October 11</span>
                                <span class="date-tag" v-if="band.performanceDates.includes('November15')"><i class="fa-sharp fa-solid fa-circle-check"></i> November 15</span>
                                <span class="date-tag" v-if="band.performanceDates.includes('ANY')"><i class="fa-sharp fa-solid fa-circle-check"></i> Any</span>
                            </div>
                        </div>
                       
                    </div>
                    <div class="size">
                        <h4>BAND MEMBERS: </h4>
                        <span v-html="band.bandSize"></span>
                    </div>
                </div>

                <div class="band-info">
                    <div class="media">
                       <div class="slider-wrap">
                            <vue-carousel :ref="band.id" 
                                :settings="slickSettings"
                                :arrows="false"
                                class="glide">
                                <img :src="image" alt="" v-for="image in band.photos" :key="image">
                            </vue-carousel>
                            <div class="arrows">
                                <i class="fa-solid fa-circle-chevron-left fa-2xl arrow prev" @click="showPrev(band)"></i>
                                <i class="fa-solid fa-circle-chevron-right fa-2xl arrow prev" @click="showNext(band)"></i>
                            </div>
                        </div>
                        
                    </div>
                    <div class="details">
                        <div class="fee-info">
                            <div class="donation"><span><input type="checkbox" value="yes" :checked="band.willingToDonate === 'yes'" /> Willing To Discuss Donation</span></div>
                            <div class="fee">
                                <span class="label">FEE: </span>
                                <span class="amount" v-html="band.fee"></span>
                            </div>
                        </div>
                        <div class="social">
                                <a v-if="band.musicLink" :href="band.musicLink" target="_blank"><i class="fa-solid fa-headphones"></i> LISTEN</a>
                                <a v-if="band.videoLink" :href="band.videoLink" target="_blank"><i class="fa-solid fa-tv-retro"></i> VIDEO</a>
                                <a v-if="band.stagePlot" :href="band.stagePlot" target="_blank"><i class="fa-solid fa-guitar-electric"></i> STAGE PLOT</a>

                            <div>
                                <a v-if="band.facebook" :href="band.facebook" target="_blank" class="icon"><i class="fa-brands fa-facebook"></i></a>
                                <a v-if="band.instagram" :href="band.instagram" target="_blank" class="icon"><i class="fa-brands fa-square-instagram"></i></a>
                                <a v-if="band.website" :href="band.website" target="_blank" class="icon"><i class="fa-regular fa-globe-pointer"></i></a>
                          
                            </div>
                        </div>
                        <p><strong>Genre: </strong> 
                            <span v-for="(genre, index) in band.genres" :key="genre.label">
                                <span>{{ genre.label }}</span><span v-if="index != (band.genres.length -1)"> / </span>
                            </span>
                        </p>
                        <p><strong>Seeking Booking As: </strong><span>{{ band.seekingBookingAs.label }}</span></p>
                        <p><strong>Performance Type: </strong>
                            <span v-for="(ptype, index) in band.performanceType" :key="index">
                                <span>{{ ptype }}</span><span v-if="index != (band.performanceType.length -1)"> / </span>
                            </span>
                        </p>
                        <p><strong>Load In: </strong><span v-if="band.loadInTime">{{ band.loadInTime.label }}</span></p>
                        <div style="margin-top: 2rem;" class="tags">
                            <span v-if="band.yourGearOrOurs.value == 'myOwnSound'" class="tag">Can Provide PA</span>
                            <span v-if="band.hasManagement.value == 'Yes'" class="tag" >Has Professional Management</span>
                            <span v-if="band.isTheMusicYouPlayLicensed.value == 'Yes'" class="tag" >ASCAP/BMI License Required</span>
                            <span v-if="band.stageTypes.value == 'formalStagesOnly'" class="tag" >Formal Stages Only</span>
                            <span v-if="band.stageTypes.value = 'informalSpaces'" class="tag">Informal Stage OK</span>
                            <span v-if="band.performanceLocations.value == 'indoorsOnly'" class="tag" >Indoors Only</span>
                            <span v-if="band.performanceLocations.value == 'indoorsAndOutdoors'" class="tag">Indoors or Outdoors</span>
                            <span class="tag" v-html="band.acousticOrAmped"></span>
                            <span v-if="band.liveOrWorkInNearSouthside.value == 'yes'" class="tag">Near Southside Local</span>
                        </div>
                    </div>
                    
                </div>
                <div class="text-block">
                    <strong>VIBE CHECK: </strong><p>{{ band.yourVibe }}</p>
                </div>
            </div>
            <div>
                <div class="header grad" @click="toggleBioSection(band.id, !bioOpenStatus[band.id])">
                    <h2>BIO</h2>
                    <span class="toggle"><i class="fa fa-chevron-down" :class="{ 'open': bioOpenStatus[band.id] }"></i></span>
                </div>
                <div class="content text-md" v-show="bioOpenStatus[band.id]">
                   <p v-html="band.bio"></p>
                </div>
            </div>
            <div v-if="band.specialRequestsOrConsiderations" @click="toggleSpecialSection(band.id, !specialOpenStatus[band.id])">
                <div class="header grad" >
                    <h2>SPECIFIC REQUIREMENTS OR SPECIAL REQUESTS</h2>
                    <span class="toggle"><i class="fa fa-chevron-down" :class="{ 'open': specialOpenStatus[band.id] }"></i></span>
                </div>
                <div class="content text-md" v-show="specialOpenStatus[band.id]" >
                    <p v-html="band.specialRequestsOrConsiderations"></p>
                </div>
            </div>
            <div >
                <div class="header grad" @click="toggleContactSection(band.id, !contactSectionStatus[band.id])">
                    <h2>CONTACT INFO</h2>
                    <span class="toggle"><i class="fa fa-chevron-down" :class="{ 'open': contactSectionStatus[band.id] }"></i></span>
                </div>
                <div class="content contact text-md" v-show="contactSectionStatus[band.id]">
                    <div class="half">
                        <h2>PRIMARY CONTACT</h2>
                        <p><strong>NAME: </strong><span v-html="band.primaryContactName"></span></p>
                        <p><strong>EMAIL: </strong><span v-html="band.primaryContactEmail"></span></p>
                        <p><strong>CELL: </strong><span v-html="band.primaryContactCell"></span></p>
                        <p><strong>AFFILIATION: </strong><span v-html="band.primaryContactAffiliation"></span></p>
                        <p><strong>PREFERRED MEANS OF CONTACT: </strong><span v-html="band.primaryContactsPreferredType.label"></span></p>
                    </div>
                    <div class="half">
                        <h2>SECONDARY CONTACT</h2>
                        <p><strong>NAME: </strong><span v-html="band.secondaryContactName"></span></p>
                        <p><strong>EMAIL: </strong><span v-html="band.secondaryContactEmail"></span></p>
                        <p><strong>CELL: </strong><span v-html="band.secondaryContactCell"></span></p>
                        <p><strong>AFFILIATION: </strong><span v-html="band.secondaryContactAffiliation"></span></p>
                        
                    </div>
                </div>
            </div>
            
        </div>
        </div>
        
        
    </div>
    
</div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "LostNSoundBands",
        data() {
            return {
                bands: [],
                selectedDates: [],
                selectedDonation: '',
                selectedPerformanceTypes: 'any',
                selectedAmplified: 'any',
                selectedIndoors: 'any',
                selectedMusicTypes: [],
                selectedPaType: 'any',
                filtersOpen: false,
                bioOpenStatus: {},
                specialOpenStatus: {},
                contactSectionStatus: {},
                paymentSectionStatus: {},
                filtersOpen: true,
                slickSettings: {
                    centerMode: false,
                    slidesToShow: 1
                }
            }
        },
        mounted() {
            this.getBands();
        },
        methods: {
            getBands: function() {
                axios
                    .get('/api/lns/musicians.json')
                    .then(
                        (response) => {
                            this.bands = response.data.data
                        }
                    )
                    .catch(error => console.log(error))
            },
            showAllDates: function() {
                this.selectedDates = [];
            },
            toggleFilters: function() {
                this.filtersOpen = !this.filtersOpen;
            },
            toggleBioSection: function(bandId, status) {
                this.$set(this.bioOpenStatus, bandId, status);
            },
            toggleSpecialSection: function(bandId, status) {
                this.$set(this.specialOpenStatus, bandId, status);
            },
            toggleContactSection: function(bandId, status) {
                this.$set(this.contactSectionStatus, bandId, status);
            },
            togglePaymentSection: function(bandId, status) {
                this.$set(this.paymentSectionStatus, bandId, status);
            },
            filterByDate: function(band) {
                return !this.selectedDates.length || band.performanceDates.some(dat => this.selectedDates.includes(dat));
            },
            filterByMusicType: function(band) {
                return !this.selectedMusicTypes.length || band.performanceType.some(t => this.selectedMusicTypes.includes(t));
            },
            filterByPaType: function(band) {
                return this.selectedPaType == 'any' || band.yourGearOrOurs.value == this.selectedPaType;
            },
            filterByIndoors: function(band) {
                return this.selectedIndoors == 'any' || band.performanceLocations.value == this.selectedIndoors;
            },
            filterByAcousticOrAmped: function(band) {
                return this.selectedAmplified == 'any' || band.acousticOrAmped == this.selectedAmplified;
            },
            filterByPerformanceType: function(band) {
                return this.selectedPerformanceTypes == 'any' || band.seekingBookingAs.value == this.selectedPerformanceTypes;
            },
            filterByDonation: function(band) {
                return !this.selectedDonation || band.willingToDonate == 'yes';
            },
            showPrev: function(band) {
                this.$refs[band.id][0].prev();
            },
            showNext: function(band) {
                this.$refs[band.id][0].next();
            },

        },
        computed: {
            bandsToDisplay: function() {
                return this.bands.filter(this.filterByDate)
                .filter(this.filterByPerformanceType)
                .filter(this.filterByDonation)
                .filter(this.filterByMusicType)
                .filter(this.filterByPaType)
                .filter(this.filterByAcousticOrAmped)
                .filter(this.filterByIndoors);
            }
        }
    }
</script>

<style>
    
</style>