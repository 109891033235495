<template>
    <div>
        <div class="entertainment-filters">
            <button class="btn" v-bind:class="{active: this.view == 'byStage'}" @click="setStageView()">View By Stage</button>
            <button class="btn" v-bind:class="{active: this.view == 'byTime'}" @click="setTimeView()">View By Time</button>
        </div>
        <div class="filters-wrapper filters-open" v-show="view == 'byStage'">
            <div class="holder-wrap">
                <div class="holder-wrap-content">
                    <div class="filters-row" data-filter-group="filters">
                        <div class="container-medium" style="margin-top:6px;">
                            <div class="custom-select members-select">
                                <div class="custom-select-arrow"></div>
                                <select name="category_sel" v-model="selectedStage" v-if="stages.length">
                                    <option value="">Select Stage</option>
                                    <optgroup label="Magnolia">
                                    <option v-for="stage in magnoliaStages" :value="stage.id">{{ stage.title }}</option>
                                    </optgroup>
                                    <optgroup label="South Main">
                                    <option v-for="stage in southMainStages" :value="stage.id">{{ stage.title }}</option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="view == 'byStage'"> <!--  class="ajax-holder info-list artist" -->
            <div class="ajax-holder info-list artist blocks">
                <div class="block-section" v-for="stage in stagesToDisplay">
                    <div class="stage-sponsor-section">
                        <a :href="stage.stageMapLink" class="stage-name">{{ stage.title }}</a>
                        <p v-if="stage.sponsorName">Presented by {{ stage.sponsorName }}</p>
                        <div class="sponsor-logo">
                            <img :src="stage.sponsorLogo" alt="" v-if="stage.sponsorLogo">
                        </div>
                        <div class="sponsor-info">
    <!--                        <p class="block-name">-->
    <!--                            <small>{{ stage.stageLocation.label }}</small>-->
    <!--                        </p>-->
                            <div class="sponsor-description" v-html="stage.sponsorDescription"></div>
                        </div>
                    </div>
                    <div>
                        <table class="ag-entertainers-list stage-hidden">
                            <thead>
                                <tr>
                                    <th class="band-name">Entertainer</th>
                                    <th>Time</th>
                                    <th>Fav?</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="band in stage.bands">
                                    <td class="band-name"><a :href="band.website" target="_blank">{{ band.bandName }}</a></td>
                                    <td class="performance-time">{{ band.performanceTime }}</td>
                                    <td>
                                        <i class="fas fa-star favorite-off"
                                           v-show="!isFavorite(band.id)"
                                           @click="setFavoriteArtist(band.id)"></i>
                                        <i class="fas fa-star favorite-on"
                                           v-show="isFavorite(band.id)"
                                           @click="removeFavoriteArtist(band.id)"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="view == 'byTime'">
            <table class="ag-entertainers-list stage-hidden">
                <thead>
                <tr>
                    <th>Entertainer</th>
                    <th>Stage</th>
                    <th>Time</th>
                    <th>Fav?</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="band in performers">
                    <td><a :href="band.website" target="_blank">{{ band.bandName }}</a></td>
                    <td>{{band.stageLocation}}<br>
                        <a :href="band.stageLink" target="_blank">{{band.stageAssignment }}</a></td>
                    <td class="performance-time">{{ band.performanceTime }}</td>
                    <td>
                        <i class="fas fa-star favorite-off"
                           v-show="!isFavorite(band.id)"
                           @click="setFavoriteArtist(band.id)"></i>
                        <i class="fas fa-star favorite-on"
                           v-show="isFavorite(band.id)"
                           @click="removeFavoriteArtist(band.id)"></i>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "ArtsgoggleStages",
        data() {
            return {
                selectedStage: "",
                stages: {},
                performers: {},
                view: 'byStage',
                favoriteArtists: []
            }
        },
        mounted() {
            this.getAllStages();
            this.getAllPerformers();
            this.getUserFavorites();
        },
        methods: {
            getAllStages: function() {
                axios
                    .get('/api/artsgoggle/stages.json')
                    .then(
                        (response) => {
                            this.stages = response.data.data
                        }
                    )
                    .catch(error => console.log(error))
            },

            getAllPerformers: function() {
                axios
                    .get('/api/artsgoggle/performers.json')
                    .then(
                        (response) => {
                            this.performers = response.data.data
                        }
                    )
                    .catch(error => console.log(error))
            },

            setStageView: function(){
                this.view = 'byStage';
            },

            setTimeView: function() {
                this.view = 'byTime';
            },

            getUserFavorites: function () {
                this.favoriteArtists = this.$storage.get('favoriteArtists', []);
            },
            setFavoriteArtist: function(artistId) {
                this.favoriteArtists.push(artistId);
                this.$storage.set('favoriteArtists', this.favoriteArtists);
            },
            removeFavoriteArtist: function(artistId) {
                this.favoriteArtists = this.favoriteArtists.filter(artist => artist !== artistId);
                this.$storage.set('favoriteArtists', this.favoriteArtists);
            },
            isFavorite: function(artistId) {
                return (this.favoriteArtists.length && this.favoriteArtists.includes(artistId) );
            }

        },
        computed: {
            stagesToDisplay: function() {
                if (this.selectedStage) {
                    return this.stages.filter(stage => stage.id === this.selectedStage)
                }

                return this.stages
            },
            southMainStages: function() {
                return this.stages.filter(stage => stage.stageLocation.value === 'southMain');
            },
            magnoliaStages: function() {
                return this.stages.filter(stage => stage.stageLocation.value === 'magnolia');
            }

        }
    }
</script>

<style>
    .sponsor-logo img {
        max-height: 200px;
    }
    i.favorite-on {
        color: #9b538d;
    }
    i.favorite-off {
        color: #ccc;
    }
</style>