var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.favoriteShops.length,
            expression: "!favoriteShops.length",
          },
        ],
        staticClass: "container no-favorites",
      },
      [_c("h2", [_vm._v("You have no favorite shops. Go find some!!")])]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "container-medium ajax-holder" }, [
      _c(
        "ul",
        { staticClass: "info-list shop-list content-style-decore guide" },
        _vm._l(this.shopData, function (shop) {
          return _c("li", { staticClass: "shop" }, [
            _c(
              "a",
              { staticClass: "info-box", attrs: { href: shop.shopUrl } },
              [
                _c("div", { staticClass: "shop-image-wrap" }, [
                  _c("div", {
                    staticClass: "shop-image",
                    style: {
                      backgroundImage: "url(" + shop.primaryProfileImage + ")",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "overlay" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-wrap" }, [
                    _c("div", { staticClass: "block-marker-container" }, [
                      _c(
                        "div",
                        { staticClass: "filters-marker-container" },
                        _vm._l(shop.submission.village, function (village) {
                          return _c(
                            "span",
                            {
                              key: village,
                              staticClass: "filters-marker bg-turquoise",
                            },
                            [
                              _c("span", {
                                domProps: { textContent: _vm._s(village) },
                              }),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "heading-info" }, [
                      _c("h2", [
                        _c("a", { attrs: { href: "#" } }, [
                          _vm._v(_vm._s(shop.submission.businessName)),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "details-button" }, [
                    _c("a", { attrs: { href: shop.shopUrl } }, [
                      _vm._v("Details ⟶"),
                    ]),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "toggle-favorite",
                class: { "favorite-active": _vm.isFavorite(shop.id) },
                on: {
                  click: function ($event) {
                    _vm.isFavorite(shop.id)
                      ? _vm.removeFavoriteShop(shop.id)
                      : _vm.setFavoriteShop(shop.id)
                  },
                },
              },
              [
                _c("i", { staticClass: "fas fa-star" }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isFavorite(shop.id),
                        expression: "!isFavorite(shop.id)",
                      },
                    ],
                    staticClass: "favorite-off",
                  },
                  [_vm._v("Add to Favorites")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isFavorite(shop.id),
                        expression: "isFavorite(shop.id)",
                      },
                    ],
                    staticClass: "favorite-on",
                  },
                  [_vm._v("Remove Favorite")]
                ),
              ]
            ),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }