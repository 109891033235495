var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content-section artists-list bg-white",
      attrs: { id: "artists-list" },
    },
    [
      _c(
        "div",
        { staticClass: "artists" },
        _vm._l(_vm.vendors, function (vendor) {
          return _c("div", { key: vendor.id, staticClass: "artist" }, [
            _c(
              "div",
              {
                staticClass: "image",
                on: {
                  click: function ($event) {
                    return _vm.showVendor(vendor)
                  },
                },
              },
              [
                vendor.logo
                  ? _c("img", { attrs: { src: vendor.logo, alt: "" } })
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "artist-content" }, [
              _c("div", { staticClass: "content" }, [
                _c("div", [
                  vendor.vendorType
                    ? _c("h4", { staticClass: "text-paper artist-medium" }, [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(vendor.vendorType) },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h3", {
                    staticClass: "text-white",
                    domProps: { innerHTML: _vm._s(vendor.businessName) },
                    on: {
                      click: function ($event) {
                        return _vm.showVendor(vendor)
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "action" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn-arrow",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.showVendor(vendor)
                      },
                    },
                  },
                  [_vm._v("Learn More")]
                ),
              ]),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "filter-artists container",
          on: { click: _vm.toggleFilters },
        },
        [
          _c(
            "a",
            {
              staticClass: "btn-arrow text-black",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [_vm._v("Filter Artists")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "artists" },
        _vm._l(_vm.artistsToDisplay, function (artist) {
          return _c("div", { key: artist.id, staticClass: "artist" }, [
            _c(
              "div",
              {
                staticClass: "image",
                on: {
                  click: function ($event) {
                    return _vm.showArtist(artist)
                  },
                },
              },
              [
                artist.profileImage
                  ? _c("img", { attrs: { src: artist.profileImage, alt: "" } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "toggle-favorite",
                    class: { "favorite-active": _vm.isFavorite(artist.id) },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.isFavorite(artist.id)
                          ? _vm.removeFavoriteArtist(artist.id)
                          : _vm.setFavoriteArtist(artist.id)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fas fa-star" }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isFavorite(artist.id),
                            expression: "!isFavorite(artist.id)",
                          },
                        ],
                        staticClass: "favorite-off",
                      },
                      [_vm._v("Add to Favorites")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isFavorite(artist.id),
                            expression: "isFavorite(artist.id)",
                          },
                        ],
                        staticClass: "favorite-on",
                      },
                      [_vm._v("Remove Favorite")]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "artist-content" }, [
              _c("div", { staticClass: "content" }, [
                _c("div", [
                  artist.mediaTypes.length
                    ? _c("h4", { staticClass: "text-paper artist-medium" }, [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(artist.mediaTypes) },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h3", {
                    staticClass: "text-white",
                    domProps: {
                      innerHTML: _vm._s(_vm.getArtistCollaboratorNames(artist)),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.showArtist(artist)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("h4", { staticClass: "text-paper artist-studio" }, [
                    _vm._v(_vm._s(artist.submission.businessName)),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "artist-description text-white",
                    domProps: {
                      innerHTML: _vm._s(_vm.getShortArtistStatement(artist)),
                    },
                  }),
                  _vm._v(" "),
                  artist.submission.historicallyMarginalized
                    ? _c(
                        "div",
                        { staticClass: "identity-statement text-white" },
                        [
                          _c("p", [
                            _c("strong", [_vm._v("IDENTITY STATEMENT ")]),
                            _vm._v(
                              _vm._s(
                                artist.submission
                                  .historicallyMarginalizedDescription
                              )
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "action" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn-arrow",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.showArtist(artist)
                      },
                    },
                  },
                  [_vm._v("Learn More")]
                ),
              ]),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "filters white",
          class: { open: _vm.filtersOpen },
          attrs: { id: "filter-sidebar" },
        },
        [
          _c(
            "div",
            { staticClass: "close-filters", on: { click: _vm.toggleFilters } },
            [_c("i", { staticClass: "far fa-times text-black" })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "filters-inner" }, [
            _c("div", { staticClass: "filter-section" }, [
              _c("h3", [_vm._v("Favorites:")]),
              _vm._v(" "),
              _c("div", { staticClass: "filter-list" }, [
                _c("label", { staticClass: "checkbox" }, [
                  _c("span", { staticClass: "checkbox__input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.showFavorites,
                          expression: "showFavorites",
                        },
                      ],
                      attrs: { type: "checkbox", name: "location" },
                      domProps: {
                        checked: Array.isArray(_vm.showFavorites)
                          ? _vm._i(_vm.showFavorites, null) > -1
                          : _vm.showFavorites,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.showFavorites,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.showFavorites = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.showFavorites = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.showFavorites = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "checkbox__control" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 24 24",
                            "aria-hidden": "true",
                            focusable: "false",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              fill: "none",
                              stroke: "currentColor",
                              "stroke-width": "3",
                              d: "M1.73 12.91l6.37 6.37L22.79 4.59",
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "radio__label" }, [
                    _vm._v("Only Show Favorites"),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "filter-section" }, [
              _c("h3", [_vm._v("Location:")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "filter-list" },
                _vm._l(_vm.locations, function (location) {
                  return _c(
                    "label",
                    { key: location.value, staticClass: "checkbox" },
                    [
                      _c("span", { staticClass: "checkbox__input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedLocations,
                              expression: "selectedLocations",
                            },
                          ],
                          attrs: { type: "checkbox", name: "location" },
                          domProps: {
                            value: location.value,
                            checked: Array.isArray(_vm.selectedLocations)
                              ? _vm._i(_vm.selectedLocations, location.value) >
                                -1
                              : _vm.selectedLocations,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.selectedLocations,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = location.value,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectedLocations = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectedLocations = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectedLocations = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkbox__control" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 24 24",
                                "aria-hidden": "true",
                                focusable: "false",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  fill: "none",
                                  stroke: "currentColor",
                                  "stroke-width": "3",
                                  d: "M1.73 12.91l6.37 6.37L22.79 4.59",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "radio__label" }, [
                        _vm._v(_vm._s(location.label)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "filter-section" }, [
              _c("h3", [_vm._v("Identity:")]),
              _vm._v(" "),
              _c("div", { staticClass: "filter-list" }, [
                _c("label", { staticClass: "checkbox" }, [
                  _c("span", { staticClass: "checkbox__input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.showOnlyMarginalized,
                          expression: "showOnlyMarginalized",
                        },
                      ],
                      attrs: { type: "checkbox", name: "marginalized" },
                      domProps: {
                        checked: Array.isArray(_vm.showOnlyMarginalized)
                          ? _vm._i(_vm.showOnlyMarginalized, null) > -1
                          : _vm.showOnlyMarginalized,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.showOnlyMarginalized,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.showOnlyMarginalized = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.showOnlyMarginalized = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.showOnlyMarginalized = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "checkbox__control" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 24 24",
                            "aria-hidden": "true",
                            focusable: "false",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              fill: "none",
                              stroke: "currentColor",
                              "stroke-width": "3",
                              d: "M1.73 12.91l6.37 6.37L22.79 4.59",
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "radio__label" }, [
                    _vm._v("Artists of Historically Marginalized Communities"),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "filter-section" }, [
              _c("h3", [_vm._v("Artist Type:")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "filter-list" },
                _vm._l(_vm.artistTypes, function (type) {
                  return _c(
                    "label",
                    { key: type.value, staticClass: "checkbox" },
                    [
                      _c("span", { staticClass: "checkbox__input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedTypes,
                              expression: "selectedTypes",
                            },
                          ],
                          attrs: { type: "checkbox", name: "artistType" },
                          domProps: {
                            value: type.value,
                            checked: Array.isArray(_vm.selectedTypes)
                              ? _vm._i(_vm.selectedTypes, type.value) > -1
                              : _vm.selectedTypes,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.selectedTypes,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = type.value,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectedTypes = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectedTypes = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectedTypes = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkbox__control" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 24 24",
                                "aria-hidden": "true",
                                focusable: "false",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  fill: "none",
                                  stroke: "currentColor",
                                  "stroke-width": "3",
                                  d: "M1.73 12.91l6.37 6.37L22.79 4.59",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "radio__label" }, [
                        _vm._v(_vm._s(type.label)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.selectedArtist
        ? _c("modal", { attrs: { name: "artist-modal", height: "90%" } }, [
            _c(
              "div",
              {
                staticClass: "return-button",
                on: {
                  click: function ($event) {
                    return _vm.closeVendorModal()
                  },
                },
              },
              [_c("i", { staticClass: "far fa-times" })]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "artist-header" }, [
              _c("div", { staticClass: "artist-nav" }, [
                _c("img", {
                  attrs: {
                    src: "/artsgoggle-guide/images/AG24guideheader_800.jpg",
                    alt: "ArtsGoggle 2024",
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "image-container artist-images" }, [
              _c(
                "div",
                { staticClass: "main-carousel" },
                [
                  _c(
                    "vue-carousel",
                    {
                      ref: "carousel",
                      attrs: {
                        centerMode: false,
                        slidesToShow: 1,
                        arrows: false,
                      },
                    },
                    _vm._l(_vm.selectedArtist.otherImages, function (image) {
                      return _c("img", {
                        key: image,
                        attrs: { src: image, alt: "" },
                      })
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "far fa-chevron-left arrow prev text-black",
                    on: { click: _vm.prevSlide },
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "far fa-chevron-right arrow next text-black",
                    on: { click: _vm.nextSlide },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "artist-content" }, [
              _c("div", { staticClass: "artist-medium" }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.selectedArtist.mediaTypes),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "artist-details" }, [
                _c("div", { staticClass: "artist-id" }, [
                  _vm.selectedArtist.headshot
                    ? _c("div", { staticClass: "artist-photo" }, [
                        _c("img", {
                          attrs: { src: _vm.selectedArtist.headshot, alt: "" },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "artist-name" }, [
                    _c("h2", {
                      staticStyle: { "margin-bottom": "0" },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.getArtistName(_vm.selectedArtist)
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("h5", [
                      _vm._v(
                        _vm._s(_vm.selectedArtist.submission.blockAssignment)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", {
                      staticClass: "font-heading",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.getArtistPronouns(_vm.selectedArtist)
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _vm.selectedArtist.submission.collaboratingArtisticPartner
                      ? _c("div", { staticClass: "artist-collab" }, [
                          _c("p", [_vm._v("Collaborator")]),
                          _vm._v(" "),
                          _c("h2", [
                            _vm._v(
                              _vm._s(
                                _vm.selectedArtist.submission
                                  .collaboratingArtisticPartner
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", {
                            staticClass: "font-heading",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.getCollabPronouns(_vm.selectedArtist)
                              ),
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedArtist.submission.businessName &&
                    _vm.selectedArtist.submission.businessName !=
                      _vm.getArtistName(_vm.selectedArtist)
                      ? _c(
                          "h2",
                          { staticClass: "text-crazy-pink artist-business" },
                          [
                            _vm._v(
                              _vm._s(_vm.selectedArtist.submission.businessName)
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "artist-description font-heading",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.selectedArtist.submission.artistStatement
                    ),
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "artist-assoc" }, [
                  _vm.selectedArtist.submission
                    .historicallyMarginalizedDescription
                    ? _c("p", [
                        _vm._v(
                          "IDENTITY STATEMENT: " +
                            _vm._s(
                              _vm.selectedArtist.submission
                                .historicallyMarginalizedDescription
                            )
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedArtist.submission.collectiveOrGalleryAssociation
                    ? _c("p", [
                        _vm._v("COLLECTIVE: "),
                        _c("strong", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.selectedArtist.submission
                                  .collectiveHyperlink,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.selectedArtist.submission
                                    .collectiveOrGalleryAssociation
                                )
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedArtist.submission.galleryRepresentation
                    ? _c("p", [
                        _vm._v("GALLERY: "),
                        _c("strong", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.selectedArtist.submission
                                  .galleryHyperlink,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.selectedArtist.submission
                                    .galleryRepresentation
                                )
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sidebar" }, [
                _c(
                  "div",
                  {
                    staticClass: "toggle-favorite font-heading",
                    class: {
                      "favorite-active": _vm.isFavorite(_vm.selectedArtist.id),
                    },
                    on: {
                      click: function ($event) {
                        _vm.isFavorite(_vm.selectedArtist.id)
                          ? _vm.removeFavoriteArtist(_vm.selectedArtist.id)
                          : _vm.setFavoriteArtist(_vm.selectedArtist.id)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fas fa-star" }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isFavorite(_vm.selectedArtist.id),
                            expression: "!isFavorite(selectedArtist.id)",
                          },
                        ],
                        staticClass: "favorite-off",
                      },
                      [_vm._v("Add to Favorites")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isFavorite(_vm.selectedArtist.id),
                            expression: "isFavorite(selectedArtist.id)",
                          },
                        ],
                        staticClass: "favorite-on",
                      },
                      [_vm._v("Remove Favorite")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "artist-local" }, [
                  _c("h3", [_vm._v("Local To")]),
                  _vm._v(" "),
                  _vm.selectedArtist.locations
                    ? _c("p", [
                        _vm.selectedArtist.locations.includes("NTX")
                          ? _c("span", { staticClass: "location-icon" }, [
                              _vm._v("NTX"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedArtist.locations.includes("FTW")
                          ? _c("span", { staticClass: "location-icon" }, [
                              _vm._v("FW"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedArtist.locations.includes("NS")
                          ? _c("span", { staticClass: "location-icon" }, [
                              _c("img", {
                                attrs: { src: "/img/logo-seal-dark-sm.png" },
                              }),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.selectedArtist.ctaUrl
                  ? _c(
                      "a",
                      {
                        staticClass: "btn",
                        attrs: { href: _vm.selectedArtist.ctaUrl },
                      },
                      [_vm._v(_vm._s(_vm.selectedArtist.ctaText))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "contact" }, [
                  _vm.selectedArtist.submission.viewMore
                    ? _c("p", { staticClass: "website" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn text-black",
                            attrs: {
                              href: _vm.formatUrl(
                                _vm.selectedArtist.submission.viewMore
                              ),
                            },
                          },
                          [_vm._v("Buy & Browse Art")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedArtist.submission.musicUrl
                    ? _c("p", { staticClass: "website" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn paper text-black",
                            attrs: {
                              href: _vm.formatUrl(
                                _vm.selectedArtist.submission.musicUrl
                              ),
                            },
                          },
                          [
                            _vm._v("Listen "),
                            _c("i", { staticClass: "fas fa-volume-up" }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedArtist.submission.WATCHURL
                    ? _c("p", { staticClass: "website" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn paper text-black",
                            attrs: {
                              href: _vm.formatUrl(
                                _vm.selectedArtist.submission.WATCHURL
                              ),
                            },
                          },
                          [
                            _vm._v("Watch "),
                            _c("i", { staticClass: "far fa-play-circle" }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h3", [_vm._v("Find Me in the Near Southside")]),
                  _vm._v(" "),
                  _vm.selectedArtist.submission.retailBusinessName
                    ? _c("h4", { staticClass: "location-name" }, [
                        _vm.selectedArtist.submission.businessOrVenueURL
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.formatUrl(
                                    _vm.selectedArtist.submission
                                      .businessOrVenueURL
                                  ),
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.selectedArtist.submission
                                      .retailBusinessName
                                  )
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.selectedArtist.submission
                                    .retailBusinessName
                                )
                              ),
                            ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedArtist.submission.retailBusinessAddress
                    ? _c("p", { staticClass: "font-heading" }, [
                        _vm._v(
                          _vm._s(
                            _vm.selectedArtist.submission.retailBusinessAddress
                          )
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.selectedArtist.submission.retailBusinessCity
                            ) +
                            ", TX " +
                            _vm._s(
                              _vm.selectedArtist.submission.retailBusinessZip
                            )
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h3", [_vm._v("Contact")]),
                  _vm._v(" "),
                  _c("p", { staticClass: "email font-heading" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "mailto:" + _vm.selectedArtist.submission.email,
                        },
                      },
                      [_vm._v("EMAIL")]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.selectedArtist.submission.website
                    ? _c("p", { staticClass: "website font-heading" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.formatUrl(
                                _vm.selectedArtist.submission.website
                              ),
                              target: "_blank",
                            },
                          },
                          [_vm._v("WEBSITE")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h3", [_vm._v("FOLLOW")]),
                  _vm._v(" "),
                  _c("p", { staticClass: "social" }, [
                    _vm.selectedArtist.submission.facebookURL
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.formatFB(
                                _vm.selectedArtist.submission.facebookURL
                              ),
                              target: "_blank",
                            },
                          },
                          [_c("i", { staticClass: "fab fa-facebook" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedArtist.submission.twitterURL
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.formatTW(
                                _vm.selectedArtist.submission.twitterURL
                              ),
                              target: "_blank",
                            },
                          },
                          [_c("i", { staticClass: "fab fa-twitter" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedArtist.submission.instagramURL
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.formatIG(
                                _vm.selectedArtist.submission.instagramURL
                              ),
                              target: "_blank",
                            },
                          },
                          [_c("i", { staticClass: "fab fa-instagram" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedArtist.submission.etsyURL
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.formatUrl(
                                _vm.selectedArtist.submission.etsyURL
                              ),
                              target: "_blank",
                            },
                          },
                          [_c("i", { staticClass: "fab fa-etsy" })]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.selectedArtist.submission.venmoHyperlink ||
                  _vm.selectedArtist.submission.payPalHyperlink
                    ? _c("h3", [_vm._v("COMMERCE & TIP JAR")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "pay-artist" }, [
                    _vm.selectedArtist.submission.venmoHyperlink
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.formatUrl(
                                _vm.selectedArtist.submission.venmoHyperlink
                              ),
                              target: "_blank",
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "venmo-icon",
                                attrs: { viewBox: "0 0 516 516" },
                              },
                              [
                                _c("rect", {
                                  staticClass: "a",
                                  attrs: {
                                    width: "516",
                                    height: "516",
                                    rx: "61",
                                    ry: "61",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  staticClass: "b",
                                  attrs: {
                                    d: "M385.16,105c11.1,18.3,16.08,37.17,16.08,61,0,76-64.87,174.7-117.52,244H163.49L115.28,121.65l105.31-10L246.2,316.82C270,278,299.43,217,299.43,175.44c0-22.77-3.9-38.25-10-51Z",
                                  },
                                }),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedArtist.submission.payPalHyperlink
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.formatUrl(
                                _vm.selectedArtist.submission.payPalHyperlink
                              ),
                              target: "_blank",
                            },
                          },
                          [_c("i", { staticClass: "fab fa-paypal" })]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedVendor
        ? _c("modal", { attrs: { name: "vendor-modal", height: "90%" } }, [
            _c("div", { staticClass: "return-button" }, [
              _c("i", {
                staticClass: "far fa-times",
                on: { click: _vm.closeVendorModal },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "artist-header" }, [
              _c("div", { staticClass: "artist-nav" }, [
                _c("img", {
                  attrs: {
                    src: "/artsgoggle-guide/images/AG-site-HEAD.jpg",
                    alt: "",
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "image-container artist-images" }, [
              _vm.selectedVendor.otherImages
                ? _c(
                    "div",
                    { staticClass: "main-carousel" },
                    [
                      _c(
                        "vue-carousel",
                        {
                          ref: "carousel",
                          attrs: {
                            centerMode: false,
                            slidesToShow: 1,
                            arrows: false,
                            loop: false,
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.selectedVendor.sampleImage,
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _vm._l(
                            _vm.selectedVendor.otherImages,
                            function (image) {
                              return _c("img", {
                                key: image,
                                attrs: { src: image, alt: "" },
                              })
                            }
                          ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("i", {
                        staticClass:
                          "far fa-chevron-left arrow prev text-black",
                        on: { click: _vm.prevSlide },
                      }),
                      _vm._v(" "),
                      _c("i", {
                        staticClass:
                          "far fa-chevron-right arrow next text-black",
                        on: { click: _vm.nextSlide },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "artist-content" }, [
              _c("div", { staticClass: "artist-details" }, [
                _c("div", { staticClass: "artist-id" }, [
                  _c("div", { staticClass: "artist-name" }, [
                    _c(
                      "h2",
                      { staticClass: "text-crazy-pink artist-business" },
                      [_vm._v(_vm._s(_vm.selectedVendor.businessName))]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "artist-description font-heading",
                  domProps: {
                    innerHTML: _vm._s(_vm.selectedVendor.description),
                  },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "artist-description font-heading",
                  domProps: {
                    innerHTML: _vm._s(_vm.selectedVendor.popularItems),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sidebar" }, [
                _c(
                  "div",
                  {
                    staticClass: "toggle-favorite font-heading",
                    class: {
                      "favorite-active": _vm.isFavorite(_vm.selectedVendor.id),
                    },
                    on: {
                      click: function ($event) {
                        _vm.isFavorite(_vm.selectedVendor.id)
                          ? _vm.removeFavoriteArtist(_vm.selectedVendor.id)
                          : _vm.setFavoriteArtist(_vm.selectedVendor.id)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fas fa-star" }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isFavorite(_vm.selectedVendor.id),
                            expression: "!isFavorite(selectedVendor.id)",
                          },
                        ],
                        staticClass: "favorite-off",
                      },
                      [_vm._v("Add to Favorites")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isFavorite(_vm.selectedVendor.id),
                            expression: "isFavorite(selectedVendor.id)",
                          },
                        ],
                        staticClass: "favorite-on",
                      },
                      [_vm._v("Remove Favorite")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "contact" }, [
                  _vm.selectedVendor.website
                    ? _c("p", { staticClass: "website" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn text-black",
                            attrs: {
                              href: _vm.formatUrl(_vm.selectedVendor.website),
                            },
                          },
                          [_vm._v("View Our Website")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedVendor.localBusiness == "Yes"
                    ? _c("h3", [_vm._v("Find Me in the Near Southside")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedVendor.localBusiness == "Yes"
                    ? _c("p", { staticClass: "font-heading" }, [
                        _vm._v(_vm._s(_vm.selectedVendor.address)),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.selectedVendor.city) +
                            ", TX"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h3", [_vm._v("Contact")]),
                  _vm._v(" "),
                  _c("p", { staticClass: "email font-heading" }, [
                    _c(
                      "a",
                      { attrs: { href: "mailto:" + _vm.selectedVendor.email } },
                      [_vm._v("EMAIL")]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.selectedVendor.website
                    ? _c("p", { staticClass: "website font-heading" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.formatUrl(_vm.selectedVendor.website),
                              target: "_blank",
                            },
                          },
                          [_vm._v("WEBSITE")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedVendor.facebookUrl ||
                  _vm.selectedVendor.instagramUrl
                    ? _c("div", [
                        _c("h3", [_vm._v("FOLLOW")]),
                        _vm._v(" "),
                        _c("p", { staticClass: "social" }, [
                          _vm.selectedVendor.facebookUrl
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.formatFB(
                                      _vm.selectedVendor.facebookUrl
                                    ),
                                    target: "_blank",
                                  },
                                },
                                [_c("i", { staticClass: "fab fa-facebook" })]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selectedVendor.instagramUrl
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.formatIG(
                                      _vm.selectedVendor.instagramUrl
                                    ),
                                    target: "_blank",
                                  },
                                },
                                [_c("i", { staticClass: "fab fa-instagram" })]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }