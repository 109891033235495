<template>
    <div>
        <div class="container no-favorites" v-show="!favoriteShops.length">
            <h2>You have no favorite shops. Go find some!!</h2>
        </div>
        <div class="container-medium ajax-holder">
            <ul class="info-list shop-list content-style-decore guide">
                <li class="shop" v-for="shop in this.shopData">
                    <a :href="shop.shopUrl" class="info-box">
                        <div class="shop-image-wrap">
                            <div class="shop-image" :style="{ backgroundImage: 'url('+shop.primaryProfileImage+')' }">
                            </div>
                            <div class="overlay"></div>
                            <div class="text-wrap">
                                <div class="block-marker-container">
                                    <div class="filters-marker-container">
                                        <span class="filters-marker bg-turquoise" v-for="village in shop.submission.village" v-bind:key="village">
                                            <span v-text="village"></span>
                                        </span>    
                                    </div>
                                </div>
                                <div class="heading-info">
                                    <h2><a href="#">{{ shop.submission.businessName }}</a></h2>
                                </div>
                            </div>
                            <div class="details-button">
                                <a :href="shop.shopUrl">Details &xrarr;</a>
                            </div>
                        </div>
                    </a>
                    <div class="toggle-favorite" v-bind:class="{'favorite-active' : isFavorite(shop.id)}" @click="isFavorite(shop.id) ? removeFavoriteShop(shop.id) : setFavoriteShop(shop.id)">
                        <i class="fas fa-star"></i>
                        <span class="favorite-off"
                            v-show="!isFavorite(shop.id)"
                        >Add to Favorites</span>
                        <span class="favorite-on"
                            v-show="isFavorite(shop.id)"
                        >Remove Favorite</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import SouthsideGuideFavoritesButton from './SouthsideGuideFavoritesButton';
    export default {
        name: "SouthsideGuideFavorites",
        components: {
           SouthsideGuideFavoritesButton
        },
        data() {
            return {
                favoriteShops: [],
                selectedCategories: [],
                shopData: []
            }
        },
        created() {
            this.getUserFavorites()
        },
        mounted() {
            this.getFavoriteShopData();
        },
        methods: {
            getUserFavorites: function () {
                this.favoriteShops = this.$storage.get('favoriteShops', []);
            },
            getFavoriteShopData: function() {
                this.shopData = [];
                if (this.favoriteShops.length) {
                    this.favoriteShops.map(shop => {
                        axios
                            .get('/api/submission/' + shop + '.json')
                            .then(
                                (response) => {
                                    this.shopData.push(response.data.data[0])
                                }
                            )
                            .catch(error => console.log(error))
                    });
                }
            },
            removeFavoriteShop: function(shopId) {
                this.favoriteShops = this.favoriteShops.filter(shop => shop !== shopId);
                this.$storage.set('favoriteShops', this.favoriteShops);
                this.getFavoriteShopData();
            },
            isFavorite: function(shopId) {
                return (this.favoriteShops.length && this.favoriteShops.includes(shopId) );
            },
        },
        computed: {
            shopsToDisplay: function() {
                if (this.selectedCategories.length) {
                    let selected = this.selectedCategories;
                    return this.favoriteShops.filter(function(shop){
                        return shop.submission.whatTypeOfBusinessAreYou.some(cat => selected.includes(cat));
                    });
             
                }
                return this.favoriteShops;
                
            }
        }
    }
</script>

<style>
 .no-favorites {
     min-height: 40vh;
     display:flex;
     justify-content: center;
     align-items: center;
     padding:12px;
     background: #fff;
 }
.no-favorites h2 {
    font-size: 1.5rem;
    font-weight: 600;
}
</style>