<template>
<div class="content-section artists-list bg-white" id="artists-list">
    <div class="artists">
        <div class="artist" v-for="vendor in vendors" :key="vendor.id">
            <div class="image" @click="showVendor(vendor)">
                <img :src="vendor.logo" alt="" v-if="vendor.logo">
            </div>
            <div class="artist-content">
                <div class="content">
                    <div>
                        <h4 class="text-paper artist-medium" v-if="vendor.vendorType"><span v-html="vendor.vendorType"></span></h4>
                        <h3 class="text-white" @click="showVendor(vendor)" v-html="vendor.businessName">
                        </h3>
                    </div>
                </div>
                <div class="action">
                    <a href="#" class="btn-arrow" @click.prevent="showVendor(vendor)">Learn More</a>
                </div>
            </div>
        </div>
    </div>
    <div class="filter-artists container" @click="toggleFilters">
        <a class="btn-arrow text-black" href="#" @click.prevent="">Filter Artists</a>
    </div>
    
    <div class="artists">
        <div class="artist" v-for="artist in artistsToDisplay" :key="artist.id">
            <div class="image" @click="showArtist(artist)">
                <img :src="artist.profileImage" alt="" v-if="artist.profileImage">
                <div class="toggle-favorite" v-bind:class="{'favorite-active' : isFavorite(artist.id)}" @click.stop="isFavorite(artist.id) ? removeFavoriteArtist(artist.id) : setFavoriteArtist(artist.id)">
                    <i class="fas fa-star"></i>
                    <span class="favorite-off"
                        v-show="!isFavorite(artist.id)"
                    >Add to Favorites</span>
                    <span class="favorite-on"
                        v-show="isFavorite(artist.id)"
                    >Remove Favorite</span>
                </div>
            </div>
            <div class="artist-content">
                <div class="content">
                    <div>
                        <h4 class="text-paper artist-medium" v-if="artist.mediaTypes.length"><span v-html="artist.mediaTypes"></span></h4>
                        <h3 class="text-white" @click="showArtist(artist)" v-html="getArtistCollaboratorNames(artist)">
                        </h3>
                        <h4 class="text-paper artist-studio">{{ artist.submission.businessName }}</h4>
                        <div v-html="getShortArtistStatement(artist)" class="artist-description text-white"></div>
                        <div class="identity-statement text-white" v-if="artist.submission.historicallyMarginalized">
                            <p><strong>IDENTITY STATEMENT </strong>{{ artist.submission.historicallyMarginalizedDescription }}</p>
                        </div>
                    </div>
                </div>
                <div class="action">
                    <a href="#" class="btn-arrow" @click.prevent="showArtist(artist)">Learn More</a>
                </div>
            </div>
        </div>
    </div>
    <div class="filters white" :class="{open: filtersOpen}" id="filter-sidebar">
        <div class="close-filters" @click="toggleFilters">
            <i class="far fa-times text-black"></i>
        </div>
        <div class="filters-inner">
            <div class="filter-section">
                <h3>Favorites:</h3>
                <div class="filter-list">
                    <label class="checkbox" >
                        <span class="checkbox__input">
                            <input type="checkbox" name="location" v-model="showFavorites">
                            <span class="checkbox__control">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                                <path fill='none' stroke='currentColor' stroke-width='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
                            </span>
                        </span>
                        <span class="radio__label">Only Show Favorites</span>
                    </label>
                </div>
            </div>
            <div class="filter-section">
                <h3>Location:</h3>
                <div class="filter-list">
                    <label class="checkbox" v-for="location in locations" :key="location.value">
                        <span class="checkbox__input">
                            <input type="checkbox" name="location" :value="location.value" v-model="selectedLocations">
                            <span class="checkbox__control">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                                <path fill='none' stroke='currentColor' stroke-width='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
                            </span>
                        </span>
                        <span class="radio__label">{{ location.label }}</span>
                    </label>
                </div>
            </div>
            <div class="filter-section">
                <h3>Identity:</h3>
                <div class="filter-list">
                    <label class="checkbox">
                        <span class="checkbox__input">
                            <input type="checkbox" name="marginalized" v-model="showOnlyMarginalized">
                            <span class="checkbox__control">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                                <path fill='none' stroke='currentColor' stroke-width='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
                            </span>
                        </span>
                        <span class="radio__label">Artists of Historically Marginalized Communities</span>
                    </label>
                </div>
            </div>
            <div class="filter-section">
                <h3>Artist Type:</h3>
                <div class="filter-list">
                    <label class="checkbox" v-for="type in artistTypes" :key="type.value">
                        <span class="checkbox__input">
                            <input type="checkbox" name="artistType" :value="type.value" v-model="selectedTypes">
                            <span class="checkbox__control">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                                <path fill='none' stroke='currentColor' stroke-width='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
                            </span>
                        </span>
                        <span class="radio__label">{{ type.label }}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>


    <modal name="artist-modal" height="90%" v-if="selectedArtist">
        <div class="return-button" @click="closeVendorModal()">
            <i class="far fa-times"></i>
        </div>
        <div class="artist-header">
            <div class="artist-nav">
                
                <img src="/artsgoggle-guide/images/AG24guideheader_800.jpg" alt="ArtsGoggle 2024" style="">
            </div>
            
        </div>
        <div class="image-container artist-images">
            <div class="main-carousel">
                <vue-carousel ref="carousel" :centerMode="false" :slidesToShow="1" :arrows="false">
                    <img :src="image" alt="" v-for="image in selectedArtist.otherImages" :key="image">
                </vue-carousel>
                <i class="far fa-chevron-left arrow prev text-black" @click="prevSlide"></i>
                <i class="far fa-chevron-right arrow next text-black" @click="nextSlide"></i>
            </div>
            
        </div>
        <div class="artist-content">
            <div class="artist-medium">
                <span v-html="selectedArtist.mediaTypes"></span>
            </div>
            <div class="artist-details">
                
                <div class="artist-id">
                    <div class="artist-photo" v-if="selectedArtist.headshot">
                        <img :src="selectedArtist.headshot" alt="">
                    </div>
                    <div class="artist-name">
                        <h2 v-html="getArtistName(selectedArtist)" style="margin-bottom: 0;"></h2>
                        <h5>{{ selectedArtist.submission.blockAssignment }}</h5>
                        <p v-html="getArtistPronouns(selectedArtist)" class="font-heading"></p>
                        
                        <div v-if="selectedArtist.submission.collaboratingArtisticPartner" class="artist-collab">
                            <p>Collaborator</p>
                            <h2>{{ selectedArtist.submission.collaboratingArtisticPartner }}</h2>
                            <p v-html="getCollabPronouns(selectedArtist)" class="font-heading"></p>
                        </div>
                        <h2 v-if="selectedArtist.submission.businessName && selectedArtist.submission.businessName != getArtistName(selectedArtist)" class="text-crazy-pink artist-business">{{ selectedArtist.submission.businessName }}</h2>
                    </div>
                    
                </div>
                
                <div class="artist-description font-heading" v-html="selectedArtist.submission.artistStatement">    
                </div>
                
                <div class="artist-assoc">
                    <p v-if="selectedArtist.submission.historicallyMarginalizedDescription">IDENTITY STATEMENT: {{ selectedArtist.submission.historicallyMarginalizedDescription }}</p>
                    <p v-if="selectedArtist.submission.collectiveOrGalleryAssociation">COLLECTIVE: <strong><a :href="selectedArtist.submission.collectiveHyperlink" target="_blank">{{ selectedArtist.submission.collectiveOrGalleryAssociation }}</a></strong></p>
                    <p v-if="selectedArtist.submission.galleryRepresentation">GALLERY: <strong><a :href="selectedArtist.submission.galleryHyperlink" target="_blank">{{ selectedArtist.submission.galleryRepresentation }}</a></strong></p>
                </div>
            </div>
            <div class="sidebar">
                <div class="toggle-favorite font-heading" v-bind:class="{'favorite-active' : isFavorite(selectedArtist.id)}" @click="isFavorite(selectedArtist.id) ? removeFavoriteArtist(selectedArtist.id) : setFavoriteArtist(selectedArtist.id)">
                    <i class="fas fa-star"></i>
                    <span class="favorite-off"
                        v-show="!isFavorite(selectedArtist.id)"
                    >Add to Favorites</span>
                    <span class="favorite-on"
                        v-show="isFavorite(selectedArtist.id)"
                    >Remove Favorite</span>
                </div>
                <div class="artist-local">
                    <h3>Local To</h3>
                    <p v-if="selectedArtist.locations">
                        <span v-if="selectedArtist.locations.includes('NTX')" class="location-icon">NTX</span>
                        <span v-if="selectedArtist.locations.includes('FTW')" class="location-icon">FW</span>
                        <span v-if="selectedArtist.locations.includes('NS')" class="location-icon"><img src="/img/logo-seal-dark-sm.png"></span>
                    </p>
                </div>
                <a :href="selectedArtist.ctaUrl" class="btn" v-if="selectedArtist.ctaUrl">{{ selectedArtist.ctaText }}</a>
            
                <div class="contact">
                    
                    <p class="website" v-if="selectedArtist.submission.viewMore">
                        <a :href="formatUrl(selectedArtist.submission.viewMore)" class="btn text-black">Buy & Browse Art</a>
                    </p>
                    <p class="website" v-if="selectedArtist.submission.musicUrl">
                        <a :href="formatUrl(selectedArtist.submission.musicUrl)" class="btn paper text-black">Listen <i class="fas fa-volume-up"></i></a>
                    </p>
                    <p class="website" v-if="selectedArtist.submission.WATCHURL">
                        <a :href="formatUrl(selectedArtist.submission.WATCHURL)" class="btn paper text-black">Watch <i class="far fa-play-circle"></i></a>
                    </p>
                    
                    <h3>Find Me in the Near Southside</h3>
                    <h4 v-if="selectedArtist.submission.retailBusinessName" class="location-name">
                        <a :href="formatUrl(selectedArtist.submission.businessOrVenueURL)" target="_blank" v-if="selectedArtist.submission.businessOrVenueURL">{{ selectedArtist.submission.retailBusinessName }}</a>
                        <span v-else>{{ selectedArtist.submission.retailBusinessName }}</span>
                    </h4>
                    <p v-if="selectedArtist.submission.retailBusinessAddress" class="font-heading">{{ selectedArtist.submission.retailBusinessAddress}}<br>
                    {{ selectedArtist.submission.retailBusinessCity }}, TX {{ selectedArtist.submission.retailBusinessZip }}</p>
                    
                    <h3>Contact</h3>
                    <p class="email font-heading"><a :href="'mailto:' + selectedArtist.submission.email">EMAIL</a></p>
                    <p class="website font-heading" v-if="selectedArtist.submission.website"><a :href="formatUrl(selectedArtist.submission.website)" target="_blank">WEBSITE</a></p>

                    <h3>FOLLOW</h3>
                    <p class="social">
                        <a :href="formatFB(selectedArtist.submission.facebookURL)" v-if="selectedArtist.submission.facebookURL" target="_blank"><i class="fab fa-facebook"></i></a>
                        <a :href="formatTW(selectedArtist.submission.twitterURL)" v-if="selectedArtist.submission.twitterURL" target="_blank"><i class="fab fa-twitter"></i></a>
                        <a :href="formatIG(selectedArtist.submission.instagramURL)" v-if="selectedArtist.submission.instagramURL" target="_blank"><i class="fab fa-instagram"></i></a>
                        <a :href="formatUrl(selectedArtist.submission.etsyURL)" v-if="selectedArtist.submission.etsyURL" target="_blank"><i class="fab fa-etsy"></i></a>
                    </p>

                    <h3 v-if="selectedArtist.submission.venmoHyperlink || selectedArtist.submission.payPalHyperlink">COMMERCE & TIP JAR</h3>
                    <p class="pay-artist">
                        <a :href="formatUrl(selectedArtist.submission.venmoHyperlink)" v-if="selectedArtist.submission.venmoHyperlink" target="_blank">
                            <svg viewBox="0 0 516 516" class="venmo-icon">
                                <rect class="a" width="516" height="516" rx="61" ry="61"/>
                                <path class="b" d="M385.16,105c11.1,18.3,16.08,37.17,16.08,61,0,76-64.87,174.7-117.52,244H163.49L115.28,121.65l105.31-10L246.2,316.82C270,278,299.43,217,299.43,175.44c0-22.77-3.9-38.25-10-51Z"/>
                            </svg>
                        </a>
                        <a :href="formatUrl(selectedArtist.submission.payPalHyperlink)" v-if="selectedArtist.submission.payPalHyperlink" target="_blank"><i class="fab fa-paypal"></i></a>
                    </p>
                </div>

            </div>
        </div>
    </modal>

    <modal name="vendor-modal" height="90%" v-if="selectedVendor">
        <div class="return-button">
            <i class="far fa-times" @click="closeVendorModal"></i>
        </div>
        <div class="artist-header">
            <div class="artist-nav">
                
                <img src="/artsgoggle-guide/images/AG-site-HEAD.jpg" alt="">
            </div>
            
        </div>
        <div class="image-container artist-images">
            <div class="main-carousel" v-if="selectedVendor.otherImages">
                <vue-carousel ref="carousel" :centerMode="false" :slidesToShow="1" :arrows="false" :loop="false">
                    <img :src="selectedVendor.sampleImage" alt="">
                    <img :src="image" alt="" v-for="image in selectedVendor.otherImages" :key="image">
                </vue-carousel>
                <i class="far fa-chevron-left arrow prev text-black" @click="prevSlide"></i>
                <i class="far fa-chevron-right arrow next text-black" @click="nextSlide"></i>
            </div>
        </div>
        <div class="artist-content">
           
            <div class="artist-details">
                
                <div class="artist-id">
                    
                    <div class="artist-name">
                        <h2 class="text-crazy-pink artist-business">{{ selectedVendor.businessName }}</h2>
                    </div>
                    
                </div>
                
                <div class="artist-description font-heading" v-html="selectedVendor.description">
                </div>
                <div class="artist-description font-heading" v-html="selectedVendor.popularItems">
                </div>
                
            </div>
            <div class="sidebar">
                <div class="toggle-favorite font-heading" v-bind:class="{'favorite-active' : isFavorite(selectedVendor.id)}" @click="isFavorite(selectedVendor.id) ? removeFavoriteArtist(selectedVendor.id) : setFavoriteArtist(selectedVendor.id)">
                    <i class="fas fa-star"></i>
                    <span class="favorite-off"
                        v-show="!isFavorite(selectedVendor.id)"
                    >Add to Favorites</span>
                    <span class="favorite-on"
                        v-show="isFavorite(selectedVendor.id)"
                    >Remove Favorite</span>
                </div>
                
                <div class="contact">
                    
                    <p class="website" v-if="selectedVendor.website">
                        <a :href="formatUrl(selectedVendor.website)" class="btn text-black">View Our Website</a>
                    </p>
                    
                    <h3 v-if="selectedVendor.localBusiness == 'Yes'">Find Me in the Near Southside</h3>
                    
                    <p v-if="selectedVendor.localBusiness == 'Yes'" class="font-heading">{{ selectedVendor.address }}<br>
                    {{ selectedVendor.city }}, TX</p>
                    
                    <h3>Contact</h3>
                    <p class="email font-heading"><a :href="'mailto:' + selectedVendor.email">EMAIL</a></p>
                    <p class="website font-heading" v-if="selectedVendor.website"><a :href="formatUrl(selectedVendor.website)" target="_blank">WEBSITE</a></p>

                    <div v-if="selectedVendor.facebookUrl || selectedVendor.instagramUrl">
                    <h3>FOLLOW</h3>
                    <p class="social">
                        <a :href="formatFB(selectedVendor.facebookUrl)" v-if="selectedVendor.facebookUrl" target="_blank"><i class="fab fa-facebook"></i></a>
                        <a :href="formatIG(selectedVendor.instagramUrl)" v-if="selectedVendor.instagramUrl" target="_blank"><i class="fab fa-instagram"></i></a>
                    </p>
                    </div>
                </div>

            </div>
        </div>
    </modal>
</div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "ArtsgoggleBlockArtists",
        props: ['block-id'],
        data() {
            return {
                block: {},
                artists: [],
                vendors: [],
                selectedTypes: [],
                showFavorites: false,
                showOnlyMarginalized: false,
                locations: [
                    {label:'Near Southside', value:'NS'},
                    {label:'Fort Worth', value:'FTW'},
                    {label:'North Texas', value:'NTX'}
                ],
                selectedLocations: [],
                filtersOpen: false,
                artistTypes: [
                    {label: 'Ceramics', value:'Ceramics'},
                    {label: 'Crafting', value:'Crafting'},
                    {label: 'Culinary', value:'Culinary Arts'},
                    {label: 'Drawing/Pastels', value:'Drawing / Pastels'},
                    {label: 'Fiber/Textiles', value:'Fiber / Textiles'},
                    {label: 'Folk/Traditional Arts', value:'Folk / Traditional Arts'},
                    {label: 'Glass', value:'Glass'},
                    {label: 'Jewelry', value:'Jewelry'},
                    {label: 'Leather', value:'Leather'},
                    {label: 'Literary Arts', value:'Literary Arts'},
                    {label: 'Metalwork', value:'Metalwork'},
                    {label: 'Mixed Media', value:'Mixed Media'},
                    {label: 'Murals', value:'Murals'},
                    {label: 'Painting', value:'Painting'},
                    {label: 'Papermaking/Book Arts', value:'Papermaking / Book Arts'},
                    {label: 'Photography', value:'Photography'},
                    {label: 'Printmaking', value:'Printmaking'},
                    {label: 'Sculpture', value:'Sculpture'},
                    {label: 'Woodworking', value:'Woodworking'},
                    {label: 'Other', value:'Other'},
                ],
                selectedArtist: null,
                selectedVendor: null,
                mainCarousel: null,
                favoriteArtists: []
            }
        },
        mounted() {
            this.getBlock();
            this.getArtists();
            this.getVendors();
            this.getUserFavorites();
        },
        methods: {
            getArtists: function() {
                axios
                    .get('/api/artsgoggle/block-artists/'+this.blockId+'.json')
                    .then(
                        (response) => {
                            this.artists = response.data.data
                        }
                    )
                    .catch(error => console.log(error))
            },
            getVendors: function() {
                axios
                    .get('/api/artsgoggle/block-vendors/'+this.blockId+'.json')
                    .then(
                        (response) => {
                            this.vendors = response.data.data
                        }
                    )
                    .catch(error => console.log(error))
            },
            getBlock: function() {
                axios
                    .get('/api/artsgoggle/block/'+this.blockId+'.json')
                    .then(
                        (response) => {
                            this.block = response.data.data[0]
                        }
                    )
                    .catch(error => console.log(error))
            },
            showArtist: function(artist) {
                this.selectedArtist = artist;
                this.$nextTick(() => {
                    this.showArtistModal();
                });
            },
            showVendor: function(vendor) {
                this.selectedVendor = vendor;
                this.$nextTick(() => {
                    this.showVendorModal();
                });
            },
            getArtistName: function(artist) {
                let name = '';
                if( artist.submission.artistPseudonym ) {
                    name = artist.submission.artistPseudonym;
                } else {
                    name = artist.submission.firstName + ' ' + artist.submission.lastName;
                }

                return name;
            },
            getArtistPronouns: function(artist) {
                let pn = '';
                if( artist.submission.preferredPronoun == "Other" ) {
                    pn = artist.submission.artistOtherPronoun;
                } else {
                    pn = artist.submission.preferredPronoun;
                }

                return pn;
            },
            getCollabPronouns: function(artist) {
                let pn = '';
                if( artist.submission.collaboratingArtistPreferredPronoun == "Other" ) {
                    pn = artist.submission.collaboratingArtistOtherPronoun;
                } else {
                    pn = artist.submission.collaboratingArtistreferredPronoun;
                }

                return pn;
            },
            getArtistCollaboratorNames: function(artist) {
                let name = '';
                let fullNames = '';
                if( artist.submission.artistPseudonym ) {
                    name = artist.submission.artistPseudonym;
                } else {
                    name = artist.submission.firstName + ' ' + artist.submission.lastName;
                }
                let collab = artist.submission.collaboratingArtisticPartner;
                if (collab) {
                    fullNames = name + ' & <br>' + collab;
                } else {
                    fullNames = name;
                }
                return fullNames;
            },
            // getMediumLabel: function(value) {
            //     console.log(value);
            //     let medium = this.artistTypes.find(type => type.value == value);
            //     return medium.label;
            // },
            getShortArtistStatement: function(artist) {
                let statement = artist.submission.artistStatement.replace(/(<([^>]+)>)/gi, "");
                return _.truncate(statement, {'length': 200, 'separator': ' '});
            },
            toggleFilters: function() {
                this.filtersOpen = !this.filtersOpen;
            },
            showArtistModal: function() {
                this.$modal.show('artist-modal');
            },
            showVendorModal: function() {
                this.$modal.show('vendor-modal');
            },
            closeArtistModal: function() {
                this.$modal.hide('artist-modal');
                this.selectedArtist = null;
            },
            closeVendorModal: function() {
                this.$modal.hide('vendor-modal');
                this.selectedVendor = null;
            },
            showAllCategories: function() {
                this.selectedCategory = null;
                // console.log(this.selectedCategory);
            },
            filterByType: function(artist) {
                return !this.selectedTypes.length || this.selectedTypes.includes(artist.mediaTypes);
            },
            filterByLocation: function(artist) {
                return !this.selectedLocations.length || artist.locations.some(loc => this.selectedLocations.includes(loc));
            },
            filterFavorites: function(artist) {
                return !this.showFavorites || this.favoriteArtists.includes(artist.id.toString());
            },
            filterMarginalized: function(artist) {
                return !this.showOnlyMarginalized || artist.historicallyMarginalized == true;
            },
            initStickySidebar: function() {

                var sidebar = new StickySidebar('#filter-sidebar', {
                    containerSelector: '#artists-list',
                    innerWrapperSelector: '.filters-inner',
                    minWidth: 960,
                    topSpacing: 0,
                    bottomSpacing: 0
                });
            },
            prevSlide: function() {
                this.$refs.carousel.prev();
            },
            nextSlide: function() {
                this.$refs.carousel.next();
            },
            prevNav: function() {
                this.$refs.carouselNav.prev();
            },
            nextNav: function() {
                this.$refs.carouselNav.next();
            },
            advanceSlide: function(index) {
                this.$refs.carousel.goTo(index);
            },
            getUserFavorites: function () {
                this.favoriteArtists = this.$storage.get('favoriteArtists', []);
            },
            setFavoriteArtist: function(artistId) {
                this.favoriteArtists.push(artistId.toString());
                this.$storage.set('favoriteArtists', this.favoriteArtists);
            },
            removeFavoriteArtist: function(artistId) {
                // console.log('removed');
                this.favoriteArtists = this.favoriteArtists.filter(artist => artist !== artistId.toString());
                this.$storage.set('favoriteArtists', this.favoriteArtists);
            },
            isFavorite: function(artistId) {
                let artist = artistId.toString();
                return (this.favoriteArtists.length && this.favoriteArtists.includes(artist) );
            },
            formatUrl: function(url) {
                var formatted = url;
                if( !url.startsWith('http')) {
                    formatted = 'http://' + url;
                }
                return formatted;
            },
            formatFB: function(str) {
                if( str.startsWith('http')) { //url formatted correctly
                    return str;
                }
                
                if( str.startsWith('facebook')) { //url needs https
                    return 'https://' + str;
                }

                if(str.startsWith('@')) {//user entered @ for some reason
                    return 'https://facebook.com/' + str.substring(1);
                }
                
                return 'https://facebook.com/' + str; //user only entered IG handle
            },
            formatIG: function(str) {

                if( str.startsWith('http')) { //url formatted correctly
                    return str;
                }
                
                if( str.startsWith('instagram')) { //url needs https
                    return 'https://' + str;
                }

                 if(str.startsWith('@')) {//user entered @ for some reason
                    return 'https://instagram.com/' + str.substring(1);
                }
                
                return 'https://instagram.com/' + str; //user only entered IG handle
            },
            formatTw: function(str) {

                if( str.startsWith('http')) { //url formatted correctly
                    return str;
                }
                
                if( str.startsWith('twitter')) { //url needs https
                    return 'https://' + str;
                }

                 if(str.startsWith('@')) {//user entered @ for some reason
                    return 'https://twitter.com/' + str.substring(1);
                }
                
                return 'https://twitter.com/' + str; //user only entered IG handle
            }

        },
        computed: {
            artistsToDisplay: function() {
                return this.artists.filter(this.filterByType).filter(this.filterByLocation).filter(this.filterFavorites).filter(this.filterMarginalized);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .artist {
        .image {
            background: #fff;
            padding: 1rem;
            border: 4px solid #000;
            img {
                object-fit: contain;
            }
        }
    }
</style>