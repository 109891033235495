var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-section news-list", attrs: { id: "news-list" } },
    [
      _c(
        "div",
        {
          staticClass: "filter-artists container text-denim",
          on: { click: _vm.toggleFilters },
        },
        [
          _c(
            "a",
            {
              staticClass: "btn-arrow text-denim",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [_vm._v("Filter News")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "news" },
        _vm._l(_vm.postsToDisplay, function (item) {
          return _c("div", { key: item.id, staticClass: "news-item" }, [
            _c("a", { staticClass: "image", attrs: { href: item.postLink } }, [
              item.image
                ? _c("img", { attrs: { src: item.image, alt: "" } })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "news-content" }, [
              _c("div", { staticClass: "content" }, [
                _c("div", [
                  _c("h4", {
                    staticClass: "text-powder",
                    domProps: {
                      innerHTML: _vm._s(_vm.getDate(item.publishDate)),
                    },
                  }),
                  _vm._v(" "),
                  _c("h5", { staticClass: "text-powder" }, [
                    _vm._v(_vm._s(item.source)),
                  ]),
                  _vm._v(" "),
                  _c("h3", { staticClass: "text-white" }, [
                    _c("a", { attrs: { href: item.postLink } }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "news-description text-white",
                    domProps: { innerHTML: _vm._s(item.summary) },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "action" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn-arrow",
                    attrs: { href: item.postLink, target: "_blank" },
                  },
                  [_vm._v("Read More")]
                ),
              ]),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "filters white",
          class: { open: _vm.filtersOpen },
          attrs: { id: "filter-sidebar" },
        },
        [
          _c(
            "div",
            { staticClass: "close-filters", on: { click: _vm.toggleFilters } },
            [_c("i", { staticClass: "far fa-times text-denim" })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "filters-inner" }, [
            _c("div", { staticClass: "filter-section" }, [
              _c("h3", [_vm._v("Category:")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "filter-list" },
                _vm._l(_vm.newsCategories, function (cat) {
                  return _c("label", { key: cat.id, staticClass: "checkbox" }, [
                    _c("span", { staticClass: "checkbox__input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedCategories,
                            expression: "selectedCategories",
                          },
                        ],
                        attrs: { type: "checkbox", name: "location" },
                        domProps: {
                          value: cat.id,
                          checked: Array.isArray(_vm.selectedCategories)
                            ? _vm._i(_vm.selectedCategories, cat.id) > -1
                            : _vm.selectedCategories,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.selectedCategories,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = cat.id,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.selectedCategories = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.selectedCategories = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.selectedCategories = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "checkbox__control" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 24 24",
                              "aria-hidden": "true",
                              focusable: "false",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "none",
                                stroke: "currentColor",
                                "stroke-width": "3",
                                d: "M1.73 12.91l6.37 6.37L22.79 4.59",
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio__label" }, [
                      _vm._v(_vm._s(cat.title)),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }