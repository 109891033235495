var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content-section events-list",
      attrs: { id: "events-list" },
    },
    [
      _c(
        "div",
        {
          staticClass: "filter-events container text-denim",
          on: { click: _vm.toggleFilters },
        },
        [
          _c(
            "a",
            {
              staticClass: "btn-arrow text-denim",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [_vm._v("Filter Events")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "events" },
        _vm._l(_vm.eventsToDisplay, function (event) {
          return _c("div", { key: event.id, staticClass: "event" }, [
            _c(
              "div",
              {
                staticClass: "image",
                on: {
                  click: function ($event) {
                    return _vm.showEventModal(event)
                  },
                },
              },
              [
                event.image
                  ? _c("img", { attrs: { src: event.image, alt: "" } })
                  : _vm._e(),
                _vm._v(" "),
                event.startDate == event.endDate
                  ? _c("div", { staticClass: "event-date bg-sunflower" }, [
                      _c("div", {
                        staticClass: "month",
                        domProps: {
                          textContent: _vm._s(_vm.getMonth(event.startDate)),
                        },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "date",
                        domProps: { textContent: _vm._s(_vm.getDate(event)) },
                      }),
                    ])
                  : _c("div", { staticClass: "event-date bg-sunflower" }, [
                      _c("div", { staticClass: "month" }, [_vm._v("DATES")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "date" }, [_vm._v("VARY")]),
                    ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "event-content" }, [
              _c("div", { staticClass: "content" }, [
                _c("div", [
                  _c(
                    "h3",
                    {
                      staticClass: "text-sunflower",
                      on: {
                        click: function ($event) {
                          return _vm.showEventModal(event)
                        },
                      },
                    },
                    [_vm._v(_vm._s(event.title))]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "event-description",
                    domProps: { innerHTML: _vm._s(event.description) },
                  }),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("div", { staticClass: "contact" }, [
                    _c("span", [_vm._v(_vm._s(event.location))]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(event.address))]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "action" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn-arrow",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.showEventModal(event)
                      },
                    },
                  },
                  [_vm._v("Learn More")]
                ),
              ]),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "filters white",
          class: { open: _vm.filtersOpen },
          attrs: { id: "filter-sidebar" },
        },
        [
          _c(
            "div",
            { staticClass: "close-filters", on: { click: _vm.toggleFilters } },
            [_c("i", { staticClass: "far fa-times" })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "filters-inner" }, [
            _c("div", { staticClass: "filter-section" }, [
              _c("h3", [_vm._v("Location:")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "filter-list" },
                _vm._l(_vm.villages, function (village) {
                  return _c(
                    "label",
                    { key: village.id, staticClass: "checkbox" },
                    [
                      _c("span", { staticClass: "checkbox__input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedVillages,
                              expression: "selectedVillages",
                            },
                          ],
                          attrs: { type: "checkbox", name: "location" },
                          domProps: {
                            value: village.id,
                            checked: Array.isArray(_vm.selectedVillages)
                              ? _vm._i(_vm.selectedVillages, village.id) > -1
                              : _vm.selectedVillages,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.selectedVillages,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = village.id,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectedVillages = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectedVillages = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectedVillages = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkbox__control" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 24 24",
                                "aria-hidden": "true",
                                focusable: "false",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  fill: "none",
                                  stroke: "currentColor",
                                  "stroke-width": "3",
                                  d: "M1.73 12.91l6.37 6.37L22.79 4.59",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "radio__label" }, [
                        _vm._v(_vm._s(village.title)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "filter-section" }, [
              _c("h3", [_vm._v("Event Type:")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "filter-list" },
                _vm._l(_vm.eventTypes, function (type) {
                  return _c(
                    "label",
                    { key: type.id, staticClass: "checkbox" },
                    [
                      _c("span", { staticClass: "checkbox__input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedTypes,
                              expression: "selectedTypes",
                            },
                          ],
                          attrs: { type: "checkbox", name: "eventType" },
                          domProps: {
                            value: type.id,
                            checked: Array.isArray(_vm.selectedTypes)
                              ? _vm._i(_vm.selectedTypes, type.id) > -1
                              : _vm.selectedTypes,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.selectedTypes,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = type.id,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectedTypes = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectedTypes = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectedTypes = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkbox__control" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 24 24",
                                "aria-hidden": "true",
                                focusable: "false",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  fill: "none",
                                  stroke: "currentColor",
                                  "stroke-width": "3",
                                  d: "M1.73 12.91l6.37 6.37L22.79 4.59",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "radio__label" }, [
                        _vm._v(_vm._s(type.title)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.selectedEvent
        ? _c("modal", { attrs: { name: "event-modal", height: "90%" } }, [
            _c("div", { staticClass: "return-button" }, [
              _c("i", {
                staticClass: "far fa-times",
                on: { click: _vm.closeEventModal },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "image-container" },
              [
                _c(
                  "vue-carousel",
                  {
                    ref: "carousel",
                    attrs: {
                      centerMode: false,
                      slidesToShow: 1,
                      arrows: false,
                    },
                  },
                  _vm._l(_vm.selectedEvent.eventImages, function (image) {
                    return _c("img", {
                      key: image,
                      attrs: { src: image, alt: "" },
                    })
                  }),
                  0
                ),
                _vm._v(" "),
                _c("i", {
                  staticClass: "far fa-chevron-left arrow prev",
                  on: { click: _vm.prevSlide },
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "far fa-chevron-right arrow next",
                  on: { click: _vm.nextSlide },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "event-content" }, [
              _c("div", { staticClass: "event-details" }, [
                _c("h2", [_vm._v(_vm._s(_vm.selectedEvent.title))]),
                _vm._v(" "),
                _vm.selectedEvent.subheadline
                  ? _c("h3", [_vm._v(_vm._s(_vm.selectedEvent.subheadline))])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", {
                  staticClass: "event-description",
                  domProps: {
                    innerHTML: _vm._s(_vm.selectedEvent.fullDescription),
                  },
                }),
                _vm._v(
                  "\n\n                " +
                    _vm._s(_vm.selectedEvent.eventDetails) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sidebar bg-black" }, [
                _c("h3", {
                  domProps: {
                    innerHTML: _vm._s(_vm.getFullDate(_vm.selectedEvent)),
                  },
                }),
                _vm._v(" "),
                !_vm.selectedEvent.allDay
                  ? _c("p", {
                      staticClass: "event-time",
                      domProps: {
                        innerHTML: _vm._s(_vm.getTimes(_vm.selectedEvent)),
                      },
                    })
                  : _c("p", { staticClass: "event-time" }, [_vm._v("All Day")]),
                _vm._v(" "),
                _vm.selectedEvent.price
                  ? _c("h4", { staticClass: "event-price" }, [
                      _vm._v("Price: " + _vm._s(_vm.selectedEvent.price)),
                    ])
                  : _c("h4", { staticClass: "event-price" }, [
                      _vm._v("Free Event"),
                    ]),
                _vm._v(" "),
                _vm.selectedEvent.ctaUrl
                  ? _c(
                      "a",
                      {
                        staticClass: "btn bg-sunflower",
                        attrs: { href: _vm.selectedEvent.ctaUrl },
                      },
                      [_vm._v(_vm._s(_vm.selectedEvent.ctaText))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "contact" }, [
                  _vm.selectedEvent.location
                    ? _c("h3", { staticClass: "location-name" }, [
                        _vm._v(_vm._s(_vm.selectedEvent.location)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedEvent.address
                    ? _c("p", [
                        _vm._v(_vm._s(_vm.selectedEvent.address)),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.selectedEvent.city) +
                            ", TX " +
                            _vm._s(_vm.selectedEvent.zip)
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedEvent.phone
                    ? _c("p", { staticClass: "phone" }, [
                        _vm._v(_vm._s(_vm.selectedEvent.phone)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "email" }, [
                    _c(
                      "a",
                      { attrs: { href: "mailto:" + _vm.selectedEvent.email } },
                      [_vm._v("EMAIL")]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.selectedEvent.website
                    ? _c("p", { staticClass: "website" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.formatUrl(_vm.selectedEvent.website),
                            },
                          },
                          [_vm._v("View Website")]
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "social-networks" }, [
                  _vm.selectedEvent.twitterUrl
                    ? _c("span", [
                        _c(
                          "a",
                          {
                            staticClass: "social-icon",
                            attrs: {
                              href: _vm.selectedEvent.twitterUrl,
                              rel: "nofollow",
                              target: "_blank",
                            },
                          },
                          [_c("i", { staticClass: "fab fa-twitter" })]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedEvent.facebookUrl
                    ? _c("span", [
                        _c(
                          "a",
                          {
                            staticClass: "social-icon",
                            attrs: {
                              href: _vm.selectedEvent.facebookUrl,
                              rel: "nofollow",
                              target: "_blank",
                            },
                          },
                          [_c("i", { staticClass: "fab fa-facebook" })]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedEvent.instagramUrl
                    ? _c("span", [
                        _c(
                          "a",
                          {
                            staticClass: "social-icon",
                            attrs: {
                              href: _vm.selectedEvent.instagramUrl,
                              rel: "nofollow",
                              target: "_blank",
                            },
                          },
                          [_c("i", { staticClass: "fab fa-instagram" })]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedEvent.youtubeUrl
                    ? _c("span", [
                        _c(
                          "a",
                          {
                            staticClass: "social-icon",
                            attrs: {
                              href: _vm.selectedEvent.youtubeUrl,
                              rel: "nofollow",
                              target: "_blank",
                            },
                          },
                          [_c("i", { staticClass: "fab fa-youtube" })]
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "links" }, [
                  _vm.selectedEvent.sponsorUrl
                    ? _c(
                        "a",
                        {
                          staticClass: "btn powder",
                          attrs: { href: _vm.selectedEvent.sponsorUrl },
                        },
                        [_vm._v("Sponsor")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedEvent.volunteerUrl
                    ? _c(
                        "a",
                        {
                          staticClass: "btn powder",
                          attrs: { href: _vm.selectedEvent.volunteerUrl },
                        },
                        [_vm._v("Volunteer")]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }