var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content-section property-list",
      attrs: { id: "properties" },
    },
    [
      _c(
        "div",
        {
          staticClass: "filter-properties container",
          on: { click: _vm.toggleFilters },
        },
        [
          _c(
            "a",
            {
              staticClass: "btn-arrow bg-denim text-sand",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [_vm._v("Filter Properties")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "properties" },
        [
          _vm._l(_vm.postsToDisplay, function (item) {
            return _c("div", { key: item.id, staticClass: "property" }, [
              _c("a", { staticClass: "image", attrs: { href: item.url } }, [
                item.image
                  ? _c("img", { attrs: { src: item.image, alt: "" } })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "property-content" }, [
                _c("div", { staticClass: "info-row" }, [
                  _c("span", [_vm._v(_vm._s(item.price))]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(item.sqft))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text-area" }, [
                  _c("address", { staticClass: "adr" }, [
                    _c("h2", [
                      _c("a", { attrs: { href: item.url } }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.address != item.title,
                              expression: "item.address != item.title",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(item.address))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("strong", { staticClass: "subtitle" }, [
                      _vm._v(
                        _vm._s(item.city) +
                          ", " +
                          _vm._s(item.state) +
                          " " +
                          _vm._s(item.zip)
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  item.villageId
                    ? _c(
                        "span",
                        {
                          staticClass: "filters-marker",
                          class: "filters-marker_" + item.villageColor,
                        },
                        [_vm._v(_vm._s(item.villageName))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "bottom-text" }, [
                    _c("p", {
                      domProps: { innerHTML: _vm._s(item.description) },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "contact-area" }, [
                  _c("h4", { staticClass: "mobile-hidden" }, [
                    _vm._v("Contact:"),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(item.agentName) + ", " + _vm._s(item.agentCompany)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c(
                      "a",
                      {
                        staticClass: "tel",
                        attrs: { href: "tel:" + item.agentPhone },
                      },
                      [_vm._v(_vm._s(item.agentPhone))]
                    ),
                    _vm._v(" "),
                    item.agentEmail
                      ? _c("span", [
                          _vm._v("or\n                            "),
                          _c(
                            "a",
                            {
                              staticClass: "mail",
                              attrs: { href: "mailto:" + item.agentEmail },
                            },
                            [_vm._v(_vm._s(item.agentEmail))]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "action" }, [
                  _c(
                    "a",
                    { staticClass: "btn-arrow", attrs: { href: item.url } },
                    [_vm._v("Learn More")]
                  ),
                ]),
              ]),
            ])
          }),
          _vm._v(" "),
          _vm._m(0),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "filters bg-sand",
          class: { open: _vm.filtersOpen },
          attrs: { id: "filter-sidebar" },
        },
        [
          _c(
            "div",
            { staticClass: "close-filters", on: { click: _vm.toggleFilters } },
            [_c("i", { staticClass: "far fa-times text-denim" })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "filters-inner" }, [
            _c("div", { staticClass: "filter-section" }, [
              _c("h3", [_vm._v("Category:")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "filter-list" },
                _vm._l(_vm.propertyCategories, function (cat) {
                  return _c("label", { key: cat.id, staticClass: "checkbox" }, [
                    _c("span", { staticClass: "checkbox__input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedCategories,
                            expression: "selectedCategories",
                          },
                        ],
                        attrs: { type: "checkbox", name: "categories" },
                        domProps: {
                          value: cat.id,
                          checked: Array.isArray(_vm.selectedCategories)
                            ? _vm._i(_vm.selectedCategories, cat.id) > -1
                            : _vm.selectedCategories,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.selectedCategories,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = cat.id,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.selectedCategories = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.selectedCategories = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.selectedCategories = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "checkbox__control" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 24 24",
                              "aria-hidden": "true",
                              focusable: "false",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "none",
                                stroke: "currentColor",
                                "stroke-width": "3",
                                d: "M1.73 12.91l6.37 6.37L22.79 4.59",
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio__label" }, [
                      _vm._v(_vm._s(cat.title)),
                    ]),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "filter-section" }, [
              _c("h3", [_vm._v("Village:")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "filter-list" },
                _vm._l(_vm.villages, function (village) {
                  return _c(
                    "label",
                    { key: village.id, staticClass: "checkbox" },
                    [
                      _c("span", { staticClass: "checkbox__input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedVillages,
                              expression: "selectedVillages",
                            },
                          ],
                          attrs: { type: "checkbox", name: "village" },
                          domProps: {
                            value: village.id,
                            checked: Array.isArray(_vm.selectedVillages)
                              ? _vm._i(_vm.selectedVillages, village.id) > -1
                              : _vm.selectedVillages,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.selectedVillages,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = village.id,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectedVillages = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectedVillages = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectedVillages = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkbox__control" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 24 24",
                                "aria-hidden": "true",
                                focusable: "false",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  fill: "none",
                                  stroke: "currentColor",
                                  "stroke-width": "3",
                                  d: "M1.73 12.91l6.37 6.37L22.79 4.59",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "radio__label" }, [
                        _vm._v(_vm._s(village.title)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "filter-section" }, [
              _c("h3", [_vm._v("Type:")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "filter-list" },
                _vm._l(_vm.propertyFilters, function (type) {
                  return _c(
                    "label",
                    { key: type.id, staticClass: "checkbox" },
                    [
                      _c("span", { staticClass: "checkbox__input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedTypes,
                              expression: "selectedTypes",
                            },
                          ],
                          attrs: { type: "checkbox", name: "type" },
                          domProps: {
                            value: type.id,
                            checked: Array.isArray(_vm.selectedTypes)
                              ? _vm._i(_vm.selectedTypes, type.id) > -1
                              : _vm.selectedTypes,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.selectedTypes,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = type.id,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectedTypes = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectedTypes = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectedTypes = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkbox__control" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 24 24",
                                "aria-hidden": "true",
                                focusable: "false",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  fill: "none",
                                  stroke: "currentColor",
                                  "stroke-width": "3",
                                  d: "M1.73 12.91l6.37 6.37L22.79 4.59",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "radio__label" }, [
                        _vm._v(_vm._s(type.title)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pagination" }, [_c("p")])
  },
]
render._withStripped = true

export { render, staticRenderFns }