<template>
<div class="content-section artists-list bg-white full" id="artists-list">
    <h3 class="text-black" style="margin: 1rem auto; font-size: 2rem; text-transform: uppercase;">Search Artists</h3>
            <form action="/guide/search" style="text-align: center; width: 80%; margin: 0 auto;">
                <input type="search" name="search" value="" style="width: 80%; background: #fff; border: 1px solid #000; margin-bottom: .5rem;">
                <input type="submit" class="btn bg-pencil text-black" value="Search" style="width: 80%;">
            </form>
    <div class="artists">
        <div class="artist" v-for="artist in artistsToDisplay" :key="artist.id">
            <div class="image" @click="showArtistModal(artist)">
                <img :src="artist.profileImage" alt="" v-if="artist.profileImage">
                <div class="toggle-favorite" v-bind:class="{'favorite-active' : isFavorite(artist.id)}" @click.stop="isFavorite(artist.id) ? removeFavoriteArtist(artist.id) : setFavoriteArtist(artist.id)">
                    <i class="fas fa-star"></i>
                    <span class="favorite-off"
                        v-show="!isFavorite(artist.id)"
                    >Add to Favorites</span>
                    <span class="favorite-on"
                        v-show="isFavorite(artist.id)"
                    >Remove Favorite</span>
                </div>
            </div>
            <div class="artist-content">
                <div class="content">
                    <div>
                        <h4 class="text-pencil artist-medium" v-if="artist.mediaTypes.length"><span v-html="artist.mediaTypes"></span></h4>
                        <h3 class="text-white" @click="showArtistModal(artist)" v-html="getArtistCollaboratorNames(artist)">
                        </h3>
                        <h4 class="text-pencil artist-studio">{{ artist.submission.businessName }}</h4>
                        <h5>{{ artist.submission.blockAssignment }}</h5>
                        <div v-html="getShortArtistStatement(artist)" class="artist-description text-white"></div>
                        <div class="identity-statement text-white" v-if="artist.submission.historicallyMarginalized">
                            <p><strong>IDENTITY STATEMENT </strong>{{ artist.submission.historicallyMarginalizedDescription }}</p>
                        </div>
                    </div>
                </div>
                <div class="action">
                    <a href="#" class="btn-arrow" @click.prevent="showArtistModal(artist)">Learn More</a>
                </div>
            </div>
        </div>

        <div v-if="artistsToDisplay.length == 0">
            <h4 class="text-black" style="text-transform:uppercase; margin: 1rem 0; text-align:center;">No Matching Artists</h4>
        </div>
       
    </div>

    <modal name="artist-modal" height="90%" v-if="selectedArtist">
        <div class="return-button">
            <i class="far fa-times" @click="closeArtistModal"></i>
        </div>
        <div class="artist-header">
            <div class="artist-nav">
                <a href="#" class="btn pencil prev" :class="{'disabled': !prevArtist}" @click="showArtist(prevArtist)">< Previous</a>
                <a href="#" class="btn pencil next" :class="{'disabled': !nextArtist}" @click="showArtist(nextArtist)">Next ></a>
                <img src="/artsgoggle-guide/images/AG24guideheader_800.jpg" alt="ArtsGoggle 2024" style="">
            </div>
            
        </div>
        <div class="image-container artist-images">
            <div class="main-carousel">
                <vue-carousel ref="carousel" :centerMode="false" :slidesToShow="1" :arrows="false">
                    <img :src="image" alt="" v-for="image in selectedArtist.otherImages" :key="image">
                </vue-carousel>
                <i class="far fa-chevron-left arrow prev text-black" @click="prevSlide"></i>
                <i class="far fa-chevron-right arrow next text-black" @click="nextSlide"></i>
            </div>
            
        </div>
        <div class="artist-content">
            <div class="artist-medium">
                <span v-html="selectedArtist.mediaTypes"></span>
            </div>
            <div class="artist-details">
                
                <div class="artist-id">
                    <div class="artist-photo" v-if="selectedArtist.headshot">
                        <img :src="selectedArtist.headshot" alt="">
                    </div>
                    <div class="artist-name">
                        <h2 v-html="getArtistName(selectedArtist)" style="margin-bottom: 0;"></h2>
                        <p v-html="getArtistPronouns(selectedArtist)" class="font-heading"></p>
                        
                        <div v-if="selectedArtist.submission.collaboratingArtisticPartner" class="artist-collab">
                            <p>Collaborator</p>
                            <h2>{{ selectedArtist.submission.collaboratingArtisticPartner }}</h2>
                            <p v-html="getCollabPronouns(selectedArtist)" class="font-heading"></p>
                        </div>
                        <h2 v-if="selectedArtist.submission.businessName && selectedArtist.submission.businessName != getArtistName(selectedArtist)" class="text-crazy-pink artist-business">{{ selectedArtist.submission.businessName }}</h2>
                    </div>
                    
                </div>
                
                <div class="artist-description font-heading" v-html="selectedArtist.submission.artistStatement">    
                </div>
                
                <div class="artist-assoc">
                    <p v-if="selectedArtist.submission.historicallyMarginalizedDescription">IDENTITY STATEMENT: {{ selectedArtist.submission.historicallyMarginalizedDescription }}</p>
                    <p v-if="selectedArtist.submission.collectiveOrGalleryAssociation">COLLECTIVE: <strong><a :href="selectedArtist.submission.collectiveHyperlink" target="_blank">{{ selectedArtist.submission.collectiveOrGalleryAssociation }}</a></strong></p>
                    <p v-if="selectedArtist.submission.galleryRepresentation">GALLERY: <strong><a :href="selectedArtist.submission.galleryHyperlink" target="_blank">{{ selectedArtist.submission.galleryRepresentation }}</a></strong></p>
                </div>
            </div>
            <div class="sidebar">
                <div class="toggle-favorite font-heading" v-bind:class="{'favorite-active' : isFavorite(selectedArtist.id)}" @click="isFavorite(selectedArtist.id) ? removeFavoriteArtist(selectedArtist.id) : setFavoriteArtist(selectedArtist.id)">
                    <i class="fas fa-star"></i>
                    <span class="favorite-off"
                        v-show="!isFavorite(selectedArtist.id)"
                    >Add to Favorites</span>
                    <span class="favorite-on"
                        v-show="isFavorite(selectedArtist.id)"
                    >Remove Favorite</span>
                </div>
                <div class="artist-local">
                    <h3>Local To</h3>
                    <p v-if="selectedArtist.locations">
                        <span v-if="selectedArtist.locations.includes('NTX')" class="location-icon">NTX</span>
                        <span v-if="selectedArtist.locations.includes('FTW')" class="location-icon">FW</span>
                        <span v-if="selectedArtist.locations.includes('NS')" class="location-icon"><img src="/img/logo-seal-dark-sm.png"></span>
                    </p>
                </div>
                <a :href="selectedArtist.ctaUrl" class="btn" v-if="selectedArtist.ctaUrl">{{ selectedArtist.ctaText }}</a>
            
                <div class="contact">
                    
                    <p class="website" v-if="selectedArtist.submission.viewMore">
                        <a :href="formatUrl(selectedArtist.submission.viewMore)" class="btn text-black">Buy & Browse Art</a>
                    </p>
                    <p class="website" v-if="selectedArtist.submission.musicUrl">
                        <a :href="formatUrl(selectedArtist.submission.musicUrl)" class="btn paper text-black">Listen <i class="fas fa-volume-up"></i></a>
                    </p>
                    <p class="website" v-if="selectedArtist.submission.WATCHURL">
                        <a :href="formatUrl(selectedArtist.submission.WATCHURL)" class="btn paper text-black">Watch <i class="far fa-play-circle"></i></a>
                    </p>
                    
                    <h3>Find Me in the Near Southside</h3>
                    <h4 v-if="selectedArtist.submission.retailBusinessName" class="location-name">
                        <a :href="formatUrl(selectedArtist.submission.businessOrVenueURL)" target="_blank" v-if="selectedArtist.submission.businessOrVenueURL">{{ selectedArtist.submission.retailBusinessName }}</a>
                        <span v-else>{{ selectedArtist.submission.retailBusinessName }}</span>
                    </h4>
                    <p v-if="selectedArtist.submission.retailBusinessAddress" class="font-heading">{{ selectedArtist.submission.retailBusinessAddress}}<br>
                    {{ selectedArtist.submission.retailBusinessCity }}, TX {{ selectedArtist.submission.retailBusinessZip }}</p>
                    
                    <h3>Contact</h3>
                    <p class="email font-heading"><a :href="'mailto:' + selectedArtist.submission.email">EMAIL</a></p>
                    <p class="website font-heading" v-if="selectedArtist.submission.website"><a :href="formatUrl(selectedArtist.submission.website)" target="_blank">WEBSITE</a></p>

                    <h3>FOLLOW</h3>
                    <p class="social">
                        <a :href="formatFB(selectedArtist.submission.facebookURL)" v-if="selectedArtist.submission.facebookURL" target="_blank"><i class="fab fa-facebook"></i></a>
                        <a :href="formatTW(selectedArtist.submission.twitterURL)" v-if="selectedArtist.submission.twitterURL" target="_blank"><i class="fab fa-twitter"></i></a>
                        <a :href="formatIG(selectedArtist.submission.instagramURL)" v-if="selectedArtist.submission.instagramURL" target="_blank"><i class="fab fa-instagram"></i></a>
                        <a :href="formatUrl(selectedArtist.submission.etsyURL)" v-if="selectedArtist.submission.etsyURL" target="_blank"><i class="fab fa-etsy"></i></a>
                    </p>
                </div>

            </div>
        </div>
    </modal>
</div>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment';
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    export default {
        name: "Artists",
        data() {
            return {
                artists: [],
                nextPage: null,
                loading: false,
                locations: [
                    {label:'Near Southside', value:'NS'},
                    {label:'Fort Worth', value:'FTW'},
                    {label:'North Texas', value:'NTX'}
                ],
                selectedArtist: null,
                mainCarousel: null,
                favoriteArtists: []
            }
        },
        created() {
            // this.getLocations();
        },
        mounted() {
            this.getArtists();
            this.mainCarousel = this.$refs.carousel;
            this.getUserFavorites();
        },
        methods: {
            formatUrl: function(url) {
                let fullUrl = '';
                if(url.startsWith('http')) {
                    fullUrl = url;
                } else {
                    fullUrl = 'http://' + url;
                }
                // console.log(fullUrl);
                return fullUrl;
            },
            getArtists: function() {
                let query = window.location.search;
                axios
                    .get('/api/artist-guide/search.json' + query)
                    .then(
                        (response) => {
                            this.artists = response.data.data;
                            this.selectedArtist = this.artists[0];
                        }
                    )
                    .catch(error => console.log(error))
            },
            showArtist: function(artist) {
                if(artist) {
                    this.selectedArtist = artist;
                }
            },
            getArtistName: function(artist) {
                let name = '';
                if( artist.submission.artistPseudonym ) {
                    name = artist.submission.artistPseudonym;
                } else {
                    name = artist.submission.firstName + ' ' + artist.submission.lastName;
                }

                return name;
            },
            getArtistPronouns: function(artist) {
                let pn = '';
                if( artist.submission.preferredPronoun == "Other" ) {
                    pn = artist.submission.artistOtherPronoun;
                } else {
                    pn = artist.submission.preferredPronoun;
                }

                return pn;
            },
            getCollabPronouns: function(artist) {
                let pn = '';
                if( artist.submission.collaboratingArtistPreferredPronoun == "Other" ) {
                    pn = artist.submission.collaboratingArtistOtherPronoun;
                } else {
                    pn = artist.submission.collaboratingArtistreferredPronoun;
                }

                return pn;
            },
            getArtistCollaboratorNames: function(artist) {
                let name = '';
                let fullNames = '';
                if( artist.submission.artistPseudonym ) {
                    name = artist.submission.artistPseudonym;
                } else {
                    name = artist.submission.firstName + ' ' + artist.submission.lastName;
                }
                let collab = artist.submission.collaboratingArtisticPartner;
                if (collab) {
                    fullNames = name + ' & <br>' + collab;
                } else {
                    fullNames = name;
                }
                return fullNames;
            },
            // getMediumLabel: function(value) {
            //     console.log(value);
            //     let medium = this.artistTypes.find(type => type.value == value);
            //     return medium.label;
            // },
            getShortArtistStatement: function(artist) {
                let statement = artist.submission.artistStatement.replace(/(<([^>]+)>)/gi, "");
                return _.truncate(statement, {'length': 200, 'separator': ' '});
            },
            showArtistModal: function(artist) {
                this.selectedArtist = artist;
                this.$modal.show('artist-modal');
            },
            closeArtistModal: function() {
                this.$modal.hide('artist-modal');
                // this.selectedArtist = null;
            },
            prevSlide: function() {
                this.$refs.carousel.prev();
            },
            nextSlide: function() {
                this.$refs.carousel.next();
            },
            prevNav: function() {
                this.$refs.carouselNav.prev();
            },
            nextNav: function() {
                this.$refs.carouselNav.next();
            },
            advanceSlide: function(index) {
                this.$refs.carousel.goTo(index);
            },
            getUserFavorites: function () {
                this.favoriteArtists = this.$storage.get('favoriteArtists', []);
            },
            setFavoriteArtist: function(artistId) {
                this.favoriteArtists.push(artistId.toString());
                this.$storage.set('favoriteArtists', this.favoriteArtists);
            },
            removeFavoriteArtist: function(artistId) {
                // console.log('removed');
                this.favoriteArtists = this.favoriteArtists.filter(artist => artist !== artistId.toString());
                this.$storage.set('favoriteArtists', this.favoriteArtists);
            },
            isFavorite: function(artistId) {
                let artist = artistId.toString();
                return (this.favoriteArtists.length && this.favoriteArtists.includes(artist) );
            },
            formatFB: function(str) {
                if( str.startsWith('http')) { //url formatted correctly
                    return str;
                }
                
                if( str.startsWith('facebook')) { //url needs https
                    return 'https://' + str;
                }

                if(str.startsWith('@')) {//user entered @ for some reason
                    return 'https://facebook.com/' + str.substring(1);
                }
                
                return 'https://facebook.com/' + str; //user only entered IG handle
            },
            formatIG: function(str) {
                console.log(str);
                if( str.startsWith('http')) { //url formatted correctly
                    return str;
                }
                
                if( str.startsWith('instagram')) { //url needs https
                    return 'https://' + str;
                }

                 if(str.startsWith('@')) {//user entered @ for some reason
                    return 'https://instagram.com/' + str.substring(1);
                }
                
                return 'https://instagram.com/' + str; //user only entered IG handle
            },
            formatTw: function(str) {

                if( str.startsWith('http')) { //url formatted correctly
                    return str;
                }
                
                if( str.startsWith('twitter')) { //url needs https
                    return 'https://' + str;
                }

                 if(str.startsWith('@')) {//user entered @ for some reason
                    return 'https://twitter.com/' + str.substring(1);
                }
                
                return 'https://twitter.com/' + str; //user only entered IG handle
            }
        },
        computed: {
            artistsToDisplay: function() {
                return this.artists;
            },
            nextArtist: function() {
                let next = null;
                
                let index = this.artistsToDisplay.findIndex(artist => artist.id == this.selectedArtist.id);
                if(index < this.artistsToDisplay.length - 1) { //not last artist
                    next = this.artistsToDisplay[index + 1];
                }
               
                return next
            },
            prevArtist: function() {
                let prev = null;
              
                let index = this.artistsToDisplay.findIndex(artist => artist.id == this.selectedArtist.id);
                if(index > 0) { //not first artist
                    prev = this.artistsToDisplay[index - 1];
                }
              
                return prev;
            }
        }
    }

</script>

<style scoped>
    .toggle-favorite {
        background: rgba(0,0,0,.5);
    }
</style>