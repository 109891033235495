<template>
<div class="content-section news-list" id="news-list">
    <div class="filter-artists container text-denim" @click="toggleFilters">
        <a class="btn-arrow text-denim" href="#" @click.prevent="">Filter News</a>
    </div>
    <div class="news">
            <div class="news-item" v-for="item in postsToDisplay" :key="item.id">
                <a class="image" :href="item.postLink">
                    <img :src="item.image" alt="" v-if="item.image">
                </a>
                <div class="news-content">
                    <div class="content">
                        <div>
                            <h4 class="text-powder" v-html="getDate(item.publishDate)"></h4>
                            <h5 class="text-powder">{{ item.source }}</h5>
                            <h3 class="text-white">
                                <a :href="item.postLink">{{item.title}}</a>
                            </h3>
                            <div v-html="item.summary" class="news-description text-white"></div>
                        </div>
                    </div>
                    <div class="action">
                        <a :href="item.postLink" class="btn-arrow" target="_blank">Read More</a>
                    </div>
                </div>
            </div>
    </div>
    <div class="filters white" :class="{open: filtersOpen}" id="filter-sidebar">
        <div class="close-filters" @click="toggleFilters">
            <i class="far fa-times text-denim"></i>
        </div>
        <div class="filters-inner">
            <div class="filter-section">
                <h3>Category:</h3>
                <div class="filter-list">
                    <label class="checkbox" v-for="cat in newsCategories" :key="cat.id">
                        <span class="checkbox__input">
                            <input type="checkbox" name="location" :value="cat.id" v-model="selectedCategories">
                            <span class="checkbox__control">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                                <path fill='none' stroke='currentColor' stroke-width='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
                            </span>
                        </span>
                        <span class="radio__label">{{ cat.title }}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment';
    export default {
        name: "News",
        props: ['limit'],
        data() {
            return {
                posts: [],
                selectedCategories: [],
                filtersOpen: false,
                newsCategories: [],
            }
        },
        created() {
            this.getNewsCategories();
        },
        mounted() {
            this.getPosts();
            this.initStickySidebar();
        },
        methods: {
            getPosts: function() {
                axios
                    .get('/api/news.json?limit=' + this.limit)
                    .then(
                        (response) => {
                            this.posts = response.data.data
                        }
                    )
                    .catch(error => console.log(error))
            },
            getPage: function(i) {
                axios
                    .get('/api/news.json?limit=' + this.limit + '&page=' + i)
                    .then(
                        (response) => {
                            this.posts = response.data.data
                        }
                    )
                    .catch(error => console.log(error))
            },
            getNewsCategories: function() {
                axios
                    .get('/api/newsCategories.json')
                    .then(
                        (response) => {
                            this.newsCategories = response.data.data
                        }
                    )
                    .catch(error => console.log(error))
            },
            getDate : function (date) {
                return moment(date).format('MMM DD, YYYY');
            },
            selectCategory: function(id) {
                this.selectedCategory = id;
                console.log(id);
            },
            showAllCategories: function() {
                this.selectedCategory = null;
                // console.log(this.selectedCategory);
            },
            filterByCategory: function(post) {
                return !this.selectedCategories.length || post.categories.some(cat => this.selectedCategories.includes(cat));
            },
            toggleFilters: function() {
                this.filtersOpen = !this.filtersOpen;
            },
            initStickySidebar: function() {
                var sidebar = new StickySidebar('#filter-sidebar', {
                    containerSelector: '#news-list',
                    innerWrapperSelector: '.filters-inner',
                    minWidth: 960,
                    topSpacing: 32,
                    bottomSpacing: 32
                });
            },
        },
        computed: {
            postsToDisplay: function() {
                return this.posts.filter(this.filterByCategory);
            }
        }
    }
</script>

<style scoped>
    .news-content h5 {
        font-weight: 200;
    }
</style>