var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: "highlights bg-" + _vm.bgColor },
    [
      _vm.entries.length
        ? _c(
            "vue-carousel",
            {
              ref: "carousel",
              attrs: { centerMode: false, slidesToShow: 1, arrows: false },
            },
            _vm._l(_vm.entries, function (entry, index) {
              return _c("div", { key: entry.id, staticClass: "highlight" }, [
                entry.image
                  ? _c("img", {
                      staticClass: "highlight-image",
                      attrs: { src: entry.image, alt: "" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "highlight-content" }, [
                  _c("h4", [_vm._v(_vm._s(entry.title))]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "highlight-text",
                    domProps: { innerHTML: _vm._s(entry.text) },
                  }),
                  _vm._v(" "),
                  entry.cta
                    ? _c("div", {
                        staticClass: "cta-wrap",
                        domProps: { innerHTML: _vm._s(entry.cta) },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "highlight-nav" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "46.803",
                          height: "12.796",
                          viewBox: "0 0 46.803 12.796",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.showPrev()
                          },
                        },
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              transform: "translate(46.803 12.4) rotate(180)",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M0,0H45.732",
                                transform: "translate(0 6)",
                                fill: "none",
                                stroke: "#224461",
                                "stroke-width": "1",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M0,0,7.557,5.828,0,12.013",
                                transform: "translate(38.443 0)",
                                fill: "none",
                                stroke: "#224461",
                                "stroke-width": "1",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "nav-info" }, [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(index + 1) },
                      }),
                      _c("span", [_vm._v("OF")]),
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.entries.length) },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "46.803",
                          height: "12.796",
                          viewBox: "0 0 46.803 12.796",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.showNext()
                          },
                        },
                      },
                      [
                        _c(
                          "g",
                          { attrs: { transform: "translate(0 0.396)" } },
                          [
                            _c("path", {
                              attrs: {
                                d: "M-239.5,562.156h45.732",
                                transform: "translate(239.496 -556.157)",
                                fill: "none",
                                stroke: "#224461",
                                "stroke-width": "1",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M-198.586,553.211l7.557,5.828-7.557,6.185",
                                transform: "translate(237.029 -553.211)",
                                fill: "none",
                                stroke: "#224461",
                                "stroke-width": "1",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ])
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }