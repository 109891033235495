var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content-section artists-list bg-white full",
      attrs: { id: "artists-list" },
    },
    [
      _c(
        "h3",
        {
          staticClass: "text-black",
          staticStyle: {
            margin: "1rem auto",
            "font-size": "2rem",
            "text-transform": "uppercase",
          },
        },
        [_vm._v("Search Artists")]
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "artists" },
        [
          _vm._l(_vm.artistsToDisplay, function (artist) {
            return _c("div", { key: artist.id, staticClass: "artist" }, [
              _c(
                "div",
                {
                  staticClass: "image",
                  on: {
                    click: function ($event) {
                      return _vm.showArtistModal(artist)
                    },
                  },
                },
                [
                  artist.profileImage
                    ? _c("img", {
                        attrs: { src: artist.profileImage, alt: "" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "toggle-favorite",
                      class: { "favorite-active": _vm.isFavorite(artist.id) },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.isFavorite(artist.id)
                            ? _vm.removeFavoriteArtist(artist.id)
                            : _vm.setFavoriteArtist(artist.id)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fas fa-star" }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isFavorite(artist.id),
                              expression: "!isFavorite(artist.id)",
                            },
                          ],
                          staticClass: "favorite-off",
                        },
                        [_vm._v("Add to Favorites")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isFavorite(artist.id),
                              expression: "isFavorite(artist.id)",
                            },
                          ],
                          staticClass: "favorite-on",
                        },
                        [_vm._v("Remove Favorite")]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "artist-content" }, [
                _c("div", { staticClass: "content" }, [
                  _c("div", [
                    artist.mediaTypes.length
                      ? _c("h4", { staticClass: "text-pencil artist-medium" }, [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(artist.mediaTypes) },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h3", {
                      staticClass: "text-white",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.getArtistCollaboratorNames(artist)
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showArtistModal(artist)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("h4", { staticClass: "text-pencil artist-studio" }, [
                      _vm._v(_vm._s(artist.submission.businessName)),
                    ]),
                    _vm._v(" "),
                    _c("h5", [
                      _vm._v(_vm._s(artist.submission.blockAssignment)),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "artist-description text-white",
                      domProps: {
                        innerHTML: _vm._s(_vm.getShortArtistStatement(artist)),
                      },
                    }),
                    _vm._v(" "),
                    artist.submission.historicallyMarginalized
                      ? _c(
                          "div",
                          { staticClass: "identity-statement text-white" },
                          [
                            _c("p", [
                              _c("strong", [_vm._v("IDENTITY STATEMENT ")]),
                              _vm._v(
                                _vm._s(
                                  artist.submission
                                    .historicallyMarginalizedDescription
                                )
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "action" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn-arrow",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.showArtistModal(artist)
                        },
                      },
                    },
                    [_vm._v("Learn More")]
                  ),
                ]),
              ]),
            ])
          }),
          _vm._v(" "),
          _vm.artistsToDisplay.length == 0
            ? _c("div", [
                _c(
                  "h4",
                  {
                    staticClass: "text-black",
                    staticStyle: {
                      "text-transform": "uppercase",
                      margin: "1rem 0",
                      "text-align": "center",
                    },
                  },
                  [_vm._v("No Matching Artists")]
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.selectedArtist
        ? _c("modal", { attrs: { name: "artist-modal", height: "90%" } }, [
            _c("div", { staticClass: "return-button" }, [
              _c("i", {
                staticClass: "far fa-times",
                on: { click: _vm.closeArtistModal },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "artist-header" }, [
              _c("div", { staticClass: "artist-nav" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn pencil prev",
                    class: { disabled: !_vm.prevArtist },
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.showArtist(_vm.prevArtist)
                      },
                    },
                  },
                  [_vm._v("< Previous")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn pencil next",
                    class: { disabled: !_vm.nextArtist },
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.showArtist(_vm.nextArtist)
                      },
                    },
                  },
                  [_vm._v("Next >")]
                ),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: "/artsgoggle-guide/images/AG24guideheader_800.jpg",
                    alt: "ArtsGoggle 2024",
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "image-container artist-images" }, [
              _c(
                "div",
                { staticClass: "main-carousel" },
                [
                  _c(
                    "vue-carousel",
                    {
                      ref: "carousel",
                      attrs: {
                        centerMode: false,
                        slidesToShow: 1,
                        arrows: false,
                      },
                    },
                    _vm._l(_vm.selectedArtist.otherImages, function (image) {
                      return _c("img", {
                        key: image,
                        attrs: { src: image, alt: "" },
                      })
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "far fa-chevron-left arrow prev text-black",
                    on: { click: _vm.prevSlide },
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "far fa-chevron-right arrow next text-black",
                    on: { click: _vm.nextSlide },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "artist-content" }, [
              _c("div", { staticClass: "artist-medium" }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.selectedArtist.mediaTypes),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "artist-details" }, [
                _c("div", { staticClass: "artist-id" }, [
                  _vm.selectedArtist.headshot
                    ? _c("div", { staticClass: "artist-photo" }, [
                        _c("img", {
                          attrs: { src: _vm.selectedArtist.headshot, alt: "" },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "artist-name" }, [
                    _c("h2", {
                      staticStyle: { "margin-bottom": "0" },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.getArtistName(_vm.selectedArtist)
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass: "font-heading",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.getArtistPronouns(_vm.selectedArtist)
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _vm.selectedArtist.submission.collaboratingArtisticPartner
                      ? _c("div", { staticClass: "artist-collab" }, [
                          _c("p", [_vm._v("Collaborator")]),
                          _vm._v(" "),
                          _c("h2", [
                            _vm._v(
                              _vm._s(
                                _vm.selectedArtist.submission
                                  .collaboratingArtisticPartner
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", {
                            staticClass: "font-heading",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.getCollabPronouns(_vm.selectedArtist)
                              ),
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedArtist.submission.businessName &&
                    _vm.selectedArtist.submission.businessName !=
                      _vm.getArtistName(_vm.selectedArtist)
                      ? _c(
                          "h2",
                          { staticClass: "text-crazy-pink artist-business" },
                          [
                            _vm._v(
                              _vm._s(_vm.selectedArtist.submission.businessName)
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "artist-description font-heading",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.selectedArtist.submission.artistStatement
                    ),
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "artist-assoc" }, [
                  _vm.selectedArtist.submission
                    .historicallyMarginalizedDescription
                    ? _c("p", [
                        _vm._v(
                          "IDENTITY STATEMENT: " +
                            _vm._s(
                              _vm.selectedArtist.submission
                                .historicallyMarginalizedDescription
                            )
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedArtist.submission.collectiveOrGalleryAssociation
                    ? _c("p", [
                        _vm._v("COLLECTIVE: "),
                        _c("strong", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.selectedArtist.submission
                                  .collectiveHyperlink,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.selectedArtist.submission
                                    .collectiveOrGalleryAssociation
                                )
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedArtist.submission.galleryRepresentation
                    ? _c("p", [
                        _vm._v("GALLERY: "),
                        _c("strong", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.selectedArtist.submission
                                  .galleryHyperlink,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.selectedArtist.submission
                                    .galleryRepresentation
                                )
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sidebar" }, [
                _c(
                  "div",
                  {
                    staticClass: "toggle-favorite font-heading",
                    class: {
                      "favorite-active": _vm.isFavorite(_vm.selectedArtist.id),
                    },
                    on: {
                      click: function ($event) {
                        _vm.isFavorite(_vm.selectedArtist.id)
                          ? _vm.removeFavoriteArtist(_vm.selectedArtist.id)
                          : _vm.setFavoriteArtist(_vm.selectedArtist.id)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fas fa-star" }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isFavorite(_vm.selectedArtist.id),
                            expression: "!isFavorite(selectedArtist.id)",
                          },
                        ],
                        staticClass: "favorite-off",
                      },
                      [_vm._v("Add to Favorites")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isFavorite(_vm.selectedArtist.id),
                            expression: "isFavorite(selectedArtist.id)",
                          },
                        ],
                        staticClass: "favorite-on",
                      },
                      [_vm._v("Remove Favorite")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "artist-local" }, [
                  _c("h3", [_vm._v("Local To")]),
                  _vm._v(" "),
                  _vm.selectedArtist.locations
                    ? _c("p", [
                        _vm.selectedArtist.locations.includes("NTX")
                          ? _c("span", { staticClass: "location-icon" }, [
                              _vm._v("NTX"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedArtist.locations.includes("FTW")
                          ? _c("span", { staticClass: "location-icon" }, [
                              _vm._v("FW"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedArtist.locations.includes("NS")
                          ? _c("span", { staticClass: "location-icon" }, [
                              _c("img", {
                                attrs: { src: "/img/logo-seal-dark-sm.png" },
                              }),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.selectedArtist.ctaUrl
                  ? _c(
                      "a",
                      {
                        staticClass: "btn",
                        attrs: { href: _vm.selectedArtist.ctaUrl },
                      },
                      [_vm._v(_vm._s(_vm.selectedArtist.ctaText))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "contact" }, [
                  _vm.selectedArtist.submission.viewMore
                    ? _c("p", { staticClass: "website" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn text-black",
                            attrs: {
                              href: _vm.formatUrl(
                                _vm.selectedArtist.submission.viewMore
                              ),
                            },
                          },
                          [_vm._v("Buy & Browse Art")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedArtist.submission.musicUrl
                    ? _c("p", { staticClass: "website" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn paper text-black",
                            attrs: {
                              href: _vm.formatUrl(
                                _vm.selectedArtist.submission.musicUrl
                              ),
                            },
                          },
                          [
                            _vm._v("Listen "),
                            _c("i", { staticClass: "fas fa-volume-up" }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedArtist.submission.WATCHURL
                    ? _c("p", { staticClass: "website" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn paper text-black",
                            attrs: {
                              href: _vm.formatUrl(
                                _vm.selectedArtist.submission.WATCHURL
                              ),
                            },
                          },
                          [
                            _vm._v("Watch "),
                            _c("i", { staticClass: "far fa-play-circle" }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h3", [_vm._v("Find Me in the Near Southside")]),
                  _vm._v(" "),
                  _vm.selectedArtist.submission.retailBusinessName
                    ? _c("h4", { staticClass: "location-name" }, [
                        _vm.selectedArtist.submission.businessOrVenueURL
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.formatUrl(
                                    _vm.selectedArtist.submission
                                      .businessOrVenueURL
                                  ),
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.selectedArtist.submission
                                      .retailBusinessName
                                  )
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.selectedArtist.submission
                                    .retailBusinessName
                                )
                              ),
                            ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedArtist.submission.retailBusinessAddress
                    ? _c("p", { staticClass: "font-heading" }, [
                        _vm._v(
                          _vm._s(
                            _vm.selectedArtist.submission.retailBusinessAddress
                          )
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.selectedArtist.submission.retailBusinessCity
                            ) +
                            ", TX " +
                            _vm._s(
                              _vm.selectedArtist.submission.retailBusinessZip
                            )
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h3", [_vm._v("Contact")]),
                  _vm._v(" "),
                  _c("p", { staticClass: "email font-heading" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "mailto:" + _vm.selectedArtist.submission.email,
                        },
                      },
                      [_vm._v("EMAIL")]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.selectedArtist.submission.website
                    ? _c("p", { staticClass: "website font-heading" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.formatUrl(
                                _vm.selectedArtist.submission.website
                              ),
                              target: "_blank",
                            },
                          },
                          [_vm._v("WEBSITE")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h3", [_vm._v("FOLLOW")]),
                  _vm._v(" "),
                  _c("p", { staticClass: "social" }, [
                    _vm.selectedArtist.submission.facebookURL
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.formatFB(
                                _vm.selectedArtist.submission.facebookURL
                              ),
                              target: "_blank",
                            },
                          },
                          [_c("i", { staticClass: "fab fa-facebook" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedArtist.submission.twitterURL
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.formatTW(
                                _vm.selectedArtist.submission.twitterURL
                              ),
                              target: "_blank",
                            },
                          },
                          [_c("i", { staticClass: "fab fa-twitter" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedArtist.submission.instagramURL
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.formatIG(
                                _vm.selectedArtist.submission.instagramURL
                              ),
                              target: "_blank",
                            },
                          },
                          [_c("i", { staticClass: "fab fa-instagram" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedArtist.submission.etsyURL
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.formatUrl(
                                _vm.selectedArtist.submission.etsyURL
                              ),
                              target: "_blank",
                            },
                          },
                          [_c("i", { staticClass: "fab fa-etsy" })]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "form",
      {
        staticStyle: { "text-align": "center", width: "80%", margin: "0 auto" },
        attrs: { action: "/guide/search" },
      },
      [
        _c("input", {
          staticStyle: {
            width: "80%",
            background: "#fff",
            border: "1px solid #000",
            "margin-bottom": ".5rem",
          },
          attrs: { type: "search", name: "search", value: "" },
        }),
        _vm._v(" "),
        _c("input", {
          staticClass: "btn bg-pencil text-black",
          staticStyle: { width: "80%" },
          attrs: { type: "submit", value: "Search" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }