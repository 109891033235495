<template>
<div :class="'highlights bg-' + bgColor">
    <vue-carousel ref="carousel" :centerMode="false" :slidesToShow="1" :arrows="false" v-if="entries.length">
    <div class="highlight" v-for="(entry, index) in entries" :key="entry.id">
        <img :src="entry.image" alt="" class="highlight-image" v-if="entry.image">
        <div class="highlight-content">
            <h4>{{ entry.title }}</h4>
            <div v-html="entry.text" class="highlight-text"></div>
            <div class="cta-wrap" v-if="entry.cta" v-html="entry.cta"></div>
            <div class="highlight-nav">
                <svg width="46.803" height="12.796" viewBox="0 0 46.803 12.796" @click="showPrev()">
                <g transform="translate(46.803 12.4) rotate(180)">
                    <path d="M0,0H45.732" transform="translate(0 6)" fill="none" stroke="#224461" stroke-width="1"/>
                    <path d="M0,0,7.557,5.828,0,12.013" transform="translate(38.443 0)" fill="none" stroke="#224461" stroke-width="1"/>
                </g>
                </svg>
                <div class="nav-info"><span v-html="index + 1"></span><span>OF</span><span v-html="entries.length"></span></div>
                <svg width="46.803" height="12.796" viewBox="0 0 46.803 12.796" @click="showNext()">
                    <g transform="translate(0 0.396)">
                        <path d="M-239.5,562.156h45.732" transform="translate(239.496 -556.157)" fill="none" stroke="#224461" stroke-width="1"/>
                        <path d="M-198.586,553.211l7.557,5.828-7.557,6.185" transform="translate(237.029 -553.211)" fill="none" stroke="#224461" stroke-width="1"/>
                    </g>
                </svg>

            </div>
        </div>
    </div>
    </vue-carousel>
</div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "FeaturedEntriesCarousel",
        props: ['blockId', 'bgColor'],
        data() {
            return {
                entries: []
            }
        },
        created() {
        },
        mounted() {
            this.getEntries();
        },
        methods: {
            getEntries: function() {
                axios
                    .get('/api/featured-entries/' + this.blockId + '.json')
                    .then(
                        (response) => {
                            this.entries = response.data.data[0].entriesToDisplay;
                        }
                    )
                    .catch(error => console.log(error))
            },
            showNext: function() {
                this.$refs.carousel.next();
            },
            showPrev: function() {
                this.$refs.carousel.prev();
            }
        }
    }
</script>

<style>
    
</style>